import Button from "~/src/components/shared/Button";
import Icon from "~/src/components/shared/Icon";
import styles from "./styles.module.scss";
import { useContext, useEffect, useState } from "react";
import RemoveModal from "~/src/components/shared/RemoveModal";
import { Col, Modal, Row, notification, Form } from "antd";
import ShoppingListCard from "./ShoppingListCard";
import ItemViewSection from "./ItemViewSection";
import axios from "axios";
import { getCookie } from "~/src/server/utils/commonUtils";
import DataLoader from "~/src/components/shared/DataLoader";
import CustomInput from "~/src/components/shared/Fields";
import CustomButton from "~/src/components/shared/Button";
import classNames from "classnames";
import { getText } from "~/src/libs/resources";
import { AppContext } from "~/src/libs/context";
import SEO from "@components/shared/SEO";
import useWishlist from "~/src/utilities/hooks/useWishlist";
import { useRouter } from "next/router";

const ShoppingLists = () => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [currentItem, setCurrentItem] = useState<any>();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [isErrorValue, setIsErrorValue] = useState<boolean>(false);
  const [isItemView, setItemView] = useState(false);
  const [ItemViewData, setItemViewData] = useState();
  const { shoppingList, shoppingListLoading, getShoppingList,customerStatus } =
    useContext<any>(AppContext);
    const { addNewWishList } = useWishlist();

  useEffect(()=>{
   getShoppingList()
  },[])
  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (showSuccessModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showSuccessModal]);

  const removeItem = async () => {
    try {
      setIsLoading(true);
      if (currentItem) {
        await axios
          .delete(`/api/ERP/customer/wishlist/${currentItem.uid}`, {
            headers: { sessionId: getCookie("sessionid") }
          })
          .then(() => {
            getShoppingList();
            setModalOpen(false);
            setIsLoading(false);
            notification.success({
              message: `${currentItem.name} removed successfully`,
              duration: 2
            });
          })
          .catch((err) => {});
      }
    } catch (error) {}
  };

  const openModal = (item: any) => {
    setModalOpen(true);
    setCurrentItem(item);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const openCreateNewShoppingListDialog = () => {
    setShowSuccessModal(true);
    setIsErrorValue(false);
  };
 const router = useRouter()
  useEffect(() => {
    if (!customerStatus?.is_vendor && !customerStatus?.profile_completed && customerStatus?.is_authenticated) {
      router.push("/");
    }
  }, [router?.query, customerStatus]);

  return (
    <>
      <SEO
        title={getText().Account?.SEO?.shoppingList?.title}
        description={getText().Account?.SEO?.shoppingList?.description}
      />
      {isItemView ? (
        <ItemViewSection item={ItemViewData} setItemView={setItemView} />
      ) : (
        <div className={styles.shoppingList}>
          <Row className={styles.titleContainer}>
            <h2 className="font-bold-h2 text-oxfordBlue">
              {getText()?.Account?.shoppingList}
            </h2>
            <Button
              theme="link"
              className={styles.creatBtn}
              onClick={() => openCreateNewShoppingListDialog()}
            >
              <div className={`${styles.addBtn} me-2`}>
                <Icon name="add" width={24} height={24} color="#ffffff" />
              </div>
              {getText()?.Account?.createNewShoppingList}
            </Button>
          </Row>
          <>
            {isLoading || shoppingListLoading ? (
              <div className={styles.loaderContainer}>
                <DataLoader />
              </div>
            ) : (
              <>
                {shoppingList?.length > 0 ? (
                  <>
                    <Row
                      className={`${styles.shoppingListItemWrapper} mb-40 pt-20`}
                      gutter={[16, 16]}
                    >
                      {shoppingList?.map((item: any, index: number) => (
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}
                          xl={{ span: 8 }}
                          key={item.uid}
                        >
                          <ShoppingListCard
                            item={item}
                            index={index}
                            openModal={openModal}
                            setItemViewData={setItemViewData}
                            setItemView={setItemView}
                            shoppingList={shoppingList}
                            getShoppingList={getShoppingList}
                          />
                        </Col>
                      ))}
                    </Row>
                    {isModalOpen && (
                      <RemoveModal
                        closeModal={closeModal}
                        confirmRemove={removeItem}
                        confirmationText={
                          "Are you sure you want to delete this shopping list?"
                        }
                      />
                    )}
                  </>
                ) : (
                  <Row
                    className={`${styles.noShoppingListAvailable} mt-40`}
                    gutter={[16, 16]}
                    justify="center"
                  >
                    <h5 className="font-medium-h5 mt-4">
                      {getText()?.Account?.shoppingListNotAvailableMessage}
                    </h5>
                  </Row>
                )}
              </>
            )}
          </>
        </div>
      )}
      <Modal
        open={showSuccessModal}
        title="Create New Shopping List"
        onCancel={() => setShowSuccessModal(false)}
        footer={false}
        destroyOnClose={true}
        closable={false}
        keyboard={false}
      >
        <Form
          name="Create New Shopping List"
          initialValues={{ recipients: [""] }}
          preserve={false}
          autoComplete="off"
          onFinish={(values:any)=>addNewWishList(values, setIsSubmitLoading, getShoppingList, setShowSuccessModal)}
          className={classNames("mt-12")}
        >
          <Form.Item
            name="shoppingListName"
            className={styles.formItem}
            rules={[
              {
                required: true,
                message: "Shopping list name is required"
              },
              {
                validator: (rule, value, callback) => {
                  if (!value) {
                    callback(rule.message as any);
                    return;
                  }

                  const nameExists = shoppingList.some(
                    (item: any) => item?.name === value.trim()
                  );
                  if (!nameExists) {
                    callback();
                  } else {
                    callback("Shopping list name should be unique.");
                  }
                }
              },
              { whitespace: true },
              {
                max: 30,
                message: "Maximum number of characters allowed is 30"
              }
            ]}
            hasFeedback
          >
            <CustomInput
              type="text"
              className={`bg-white ${
                isErrorValue ? styles.shoppingNameInputError : ""
              } my-3 py-4`}
              placeholder="Enter shopping list name *"
              onChange={() => setIsErrorValue(false)}
            />
          </Form.Item>
          <Form.Item
            className={classNames(styles.saveBtnContainer, "mb-0")}
            wrapperCol={{ offset: 0, span: 24 }}
          >
            <CustomButton
              theme="primary"
              htmlType="submit"
              loading={isSubmitLoading}
              disabled={isSubmitLoading}
              className={classNames(
                styles.saveWishlistBtn,
                "py-12 font-bold-base mt-4"
              )}
            >
              {getText()?.Account?.saveButton}
            </CustomButton>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ShoppingLists;
