import React, { useState } from "react";
import PropTypes from "prop-types";

const PdpContext = React.createContext({});
const PdpProvider = ({ children }) => {
  const [productInfo, setProductInfo] = useState({});
  const [attributeGroups, setAttributeGroups] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showLetUsKnow, setShowLetUsKnow] = useState(false);
  const [pricesData, setPricesData] = useState(null);
  const [accessRequested, setAccessRequested] = useState(false);
  const [certificatesData, setCertificatesData] = useState();

  return (
    <PdpContext.Provider
      value={{
        productInfo,
        setProductInfo,
        attributeGroups,
        setAttributeGroups,
        showModal,
        setShowModal,
        showLetUsKnow,
        setShowLetUsKnow,
        pricesData,
        setPricesData,
        accessRequested,
        setAccessRequested,
        certificatesData, setCertificatesData
      }}
    >
      {children}
    </PdpContext.Provider>
  );
};

PdpProvider.propTypes = {
  children: PropTypes.array
};

export { PdpContext, PdpProvider };
