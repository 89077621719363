import React, { memo, useContext, useMemo } from "react";
import { ProductProps } from "./types";
import styles from "./style.module.scss";
import Link from "next/link";
import Tags from "@components/shared/Tag";
import { Image } from "antd";
import classnames from "classnames";
import Tag from "@components/shared/Tag";
import AddToShoppingListDialog from "~/src/components/shared/AddToShoppingList";
import RequestForm from "../requestForm";
import ProductCard from "./components/ProductCard";
import { useCardLogic } from "./hooks";
import WishList from "@components/shared/WishList";
import { AppContext } from "~/src/libs/context";

const Product = ({
  isCarousel,
  isMixAndMatchCarousel = false,
  isVendor = false,
  productCardClass,
  product,
  disabled = false,
  id,
  titleOfList,
  selectProductHandler,
  placeholderImage,
  casesPerPalletComponent,
  vendorItemTi
}: ProductProps & {
  className?: string;
}) => {
  const { customerStatus, isGuestUser } = useContext<any>(AppContext);
  const {
    setAddToCartStatus,
    addItemToCart,
    addItemToPallet,
    showModal,
    buildMixedPallet,
    addToCartStatus,
    compareProductHandler,
    viewDetailsHandler,
    doWishlistAction,
    router,
    validGuestUser,
    validCustomerStatus,
    inCart,
    palletId,
    vendorId,
    favClick,
    isWishlist,
    showShoppingListDialog,
    setShowToShoppingListDialog,
    selectedItem,
    setFavClick,
    setIsWishlist,
    itemShoppingLists,
    isModalOpen,
    setIsModalOpen,
    addToCartHandler,
  } = useCardLogic({
    customerStatus,
    isGuestUser,
    id,
    titleOfList,
    product,
  });
  const productCardProps = useMemo(
    () => ({
      isVendor,
      setAddToCartStatus,
      selectProductHandler,
      addItemToCart,
      addItemToPallet,
      showModal,
      buildMixedPallet,
      addToCartStatus,
      compareProductHandler,
      viewDetailsHandler,
      router,
      disabled,
      product,
      featured: false,
      id,
      titleOfList,
      validGuestUser,
      validCustomerStatus,
      inCart,
      placeholderImage,
      casesPerPalletComponent,
      vendorId,
      vendorItemTi
    }),
    [
      isVendor,
      setAddToCartStatus,
      selectProductHandler,
      addItemToCart,
      addItemToPallet,
      showModal,
      buildMixedPallet,
      addToCartStatus,
      compareProductHandler,
      viewDetailsHandler,
      id,
      titleOfList,
      validGuestUser,
      validCustomerStatus,
      showShoppingListDialog,
      palletId,
      vendorId,
      vendorItemTi
    ]
  );
  return (
    <>
      <div
        className={`${
          !isCarousel
            ? classnames(styles.productCard, styles.productSectionClass)
            : isVendor
            ? classnames(styles.productCard, styles.productVendorClass)
            : styles.productCard
        } ${
          productCardClass === "recentlyViewedCard"
            ? styles.recentlyViewedCard
            : ""
        } ${isCarousel ? styles.carouselProductCard : ""} ${
          isMixAndMatchCarousel ? styles.mixAndMatchCarouselProductCard : ""
        } ${disabled ? styles.cardDisabled : ""}`}
      >
        <div className={`${styles.tagsSection} mb-6`}>
          <div className={classnames(styles.topButton)}>
            {product?.has_promo ? (
              <Tag
                theme="light"
                className={classnames(
                  `font-bold-xxs text-oxfordBlue`,
                  styles.promoTag
                )}
                text="PROMO"
                hasIcon={false}
                size={"md"}
              />
            ) : null}

            {!isVendor && product?.for_mixed_pallet && (
              <Tags
                theme="outline"
                text={"Mixed Pallet Available"}
                hasIcon={true}
                className={styles.mixedTagWrapper}
                iconName="my-order"
                iconHeight={13}
                iconWidth={13}
                size="md"
              />
            )}
          </div>

          <div className={styles.haggleAndWishlistWrapper}>
            {product?.has_haggle_rewards ? (
              <Link href="/haggle">
                <a aria-label="Haggle landing page link">
                  <Image
                    width={24}
                    preview={false}
                    src="https://cdn.bfldr.com/SK3DIHJL/at/x6r3whnzsvwcxf9xxkxv8q7k/haggleLogo.svg"
                  />
                </a>
              </Link>
            ) : null}
            {!validGuestUser &&
              !isVendor &&
              !(
                !customerStatus?.is_vendor && !customerStatus?.profile_completed
              ) && (
                <WishList
                  favClick={favClick}
                  onClick={() => doWishlistAction(product)}
                  isWishlist={isWishlist}
                />
              )}
          </div>
        </div>
        <ProductCard {...productCardProps} />
      </div>
      {showShoppingListDialog && (
        <AddToShoppingListDialog
          setShowToShoppingListDialog={setShowToShoppingListDialog}
          isModalOpen={showShoppingListDialog}
          selectedItem={selectedItem}
          favClick={favClick}
          setFavClick={setFavClick}
          setIsWishlist={setIsWishlist}
          slug={product?.slug}
          productId={product?.id}
          productName={product?.name}
          itemShoppingLists={itemShoppingLists}
          gaForWishList={() => addToCartHandler(product, "add_to_wishlist")}
        />
      )}
      <RequestForm setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />
    </>
  );
};

export default memo(Product);
