import { notification } from "antd";
import axios from "axios";
import router from "next/router";
import { useContext } from "react";
import { AppContext } from "~/src/libs/context";
import { getText } from "~/src/libs/resources";
import { getCookie } from "~/src/server/utils/commonUtils";
interface cardProps {
  closeMenu: () => void;
  item: any;
  cartEvent: string;
  price: number;
  discount: number;
  id: number;
  quantity: number;
  setQty: (args: number) => void;
  setCartEvent: (args: string) => void;
  closeModal: () => void;
  getMiniCartItems: () => void;
  setLoading: (args: boolean) => void;
}
const useCard = ({
  closeMenu,
  item,
  cartEvent,
  price,
  discount,
  id,
  quantity,
  setQty,
  setCartEvent,
  closeModal,
  getMiniCartItems,
  setLoading,
}: cardProps) => {
  const { defaultPostZip, couponsName, getCartItems } = useContext<any>(AppContext);
  const cartItemQty = async (qty: number) => {
    setLoading(true);
    try {
      await axios.post("/api/ERP/cart/setPalletQuantity", {
        headers: { sessionId: getCookie("sessionid") },
        data: { pallet: item?.id, quantity: qty },
      });
      await getMiniCartItems();
      if (router.pathname === "/cart") {
        getCartItems();
      }
    } catch (error) {
      closeMenu();
      notification.error({
        message: getText().General.Messages.SomethingWrongTryAgain,
        duration: 4,
      });
    } finally {
      setLoading(false);
    }
    closeModal();
  };
  const openMixedPallet = async (palletId: any) => {
    let _url = `/api/ERP/customer/mixedPallet/${palletId}`;
    if (defaultPostZip) {
      _url += `?post_zip=${defaultPostZip}`;
    }
    await axios
      .get(_url, {
        headers: { sessionId: getCookie("sessionid") },
      })
      .then((res) => {
        const vendorId = res?.data?.products?.[0]?.product?.vendor_id;
        closeMenu();
        router.push(
          `/mixed-pallet-vendor?pallet=${palletId}&vendor=${vendorId}&ordering=delivered_case_price`
        );
      })
      .catch((e) => {
        notification.error({
          message: `${getText().General.Messages.SomethingWrongTryAgain}`,
          duration: 3,
        });
      });
  };

  const changeQty = (eventValue?: string) => {
    window?.dataLayer?.push({ ecommerce: null });
    if (item?.pallet_type === "PALLET") {
      window?.dataLayer?.push({
        event:
          eventValue === "select_item"
            ? "select_item"
            : eventValue === "remove_Item"
            ? "remove_from_cart"
            : eventValue === "add_to_wishlist"
            ? "add_to_wishlist"
            : cartEvent === "move_to_cart"
            ? "add_to_cart"
            : cartEvent === "saved_for_later"
            ? "remove_from_cart"
            : cartEvent,
        ecommerce: {
          currency: "USD",
          value:
            eventValue === "remove_Item"
              ? item?.total_delivered_price
              : cartEvent === "move_to_cart" ||
                cartEvent === "saved_for_later" ||
                eventValue === "select_item"
              ? item?.total_delivered_price
              : Number(
                  Math.abs(item?.total_delivered_price - price).toFixed(2)
                ),
          items: [
            {
              item_id: item?.pallet_items?.[0]?.product?.id?.toString(),
              item_name: item?.pallet_items?.[0]?.product?.name,
              coupon: couponsName,
              currency: "USD",
              discount:
                eventValue === "remove_Item"
                  ? item?.total_delivered_discount
                  : cartEvent === "move_to_cart" ||
                    cartEvent === "saved_for_later"
                  ? item?.total_delivered_discount
                  : eventValue === "select_item"
                  ? Number(
                      Math.abs(
                        item?.total_delivered_discount / item?.quantity
                      ).toFixed(2)
                    )
                  : Number(
                      Math.abs(
                        item?.total_delivered_discount - discount
                      ).toFixed(2)
                    ),
              index: id,
              item_brand: item?.pallet_items?.[0]?.product?.brand?.name,
              item_category:
                item?.pallet_items?.[0]?.product?.main_category_name,
              item_category2:
                item?.pallet_items?.[0]?.product?.sub_category_name,
              item_list_id: "",
              item_list_name:
                item?.pallet_items?.[0]?.product?.sub_category_name,
              item_variant: item?.pallet_items?.[0]?.product?.pack_size,
              price: Number(
                (item?.total_delivered_price / item?.quantity).toFixed(2)
              ),

              quantity:
                eventValue === "remove_Item" ||
                cartEvent === "move_to_cart" ||
                cartEvent === "saved_for_later"
                  ? item?.quantity
                  : eventValue === "select_item"
                  ? 1
                  : Math.abs(item?.quantity - quantity),
            },
          ],
        },
      });
    } else {
      window?.dataLayer?.push({
        event:
          eventValue === "select_item"
            ? "select_item"
            : eventValue === "remove_Item"
            ? "remove_from_cart"
            : eventValue === "add_to_wishlist"
            ? "add_to_wishlist"
            : cartEvent === "move_to_cart"
            ? "add_to_cart"
            : cartEvent === "saved_for_later"
            ? "remove_from_cart"
            : cartEvent,
        ecommerce: {
          currency: "USD",
          value:
            eventValue === "remove_Item"
              ? item?.total_delivered_price
              : cartEvent === "move_to_cart" ||
                cartEvent === "saved_for_later" ||
                eventValue === "select_item"
              ? item?.total_delivered_price
              : Number(
                  Math.abs(item?.total_delivered_price - price).toFixed(2)
                ),
          items: [
            {
              item_id: item?.id?.toString(),
              item_name: "Mixed Pallet",
              coupon: couponsName,
              currency: "USD",
              discount:
                eventValue === "remove_Item"
                  ? item?.total_delivered_discount
                  : cartEvent === "move_to_cart" ||
                    cartEvent === "saved_for_later"
                  ? item?.total_delivered_discount
                  : eventValue === "select_item"
                  ? Number(
                      Math.abs(
                        item?.total_delivered_discount / item?.quantity
                      ).toFixed(2)
                    )
                  : Number(
                      Math.abs(
                        item?.total_delivered_discount - discount
                      ).toFixed(2)
                    ),
              index: 1,
              item_brand: item?.sold_by_vendor,
              item_category:
                item?.pallet_items?.[0]?.product?.main_category_name,
              item_category2:
                item?.pallet_items?.[0]?.product?.sub_category_name,
              item_category3: "",
              item_category4: "",
              item_list_id: "",
              item_list_name:
                item?.pallet_items?.[0]?.product?.sub_category_name,
              item_variant: `${item?.sold_by_vendor} - mixed pallet`,
              price: Number(
                (item?.total_delivered_price / item?.quantity).toFixed(2)
              ),
              quantity:
                eventValue === "remove_Item" ||
                cartEvent === "move_to_cart" ||
                cartEvent === "saved_for_later"
                  ? item?.quantity
                  : eventValue === "select_item"
                  ? 1
                  : Math.abs(item?.quantity - quantity),
              selected_product1: item?.pallet_items?.[0]?.product?.name || "",
              selected_product2: item?.pallet_items?.[1]?.product?.name || "",
              selected_product3: item?.pallet_items?.[2]?.product?.name || "",
              selected_product4: item?.pallet_items?.[3]?.product?.name || "",
              selected_product5: item?.pallet_items?.[4]?.product?.name || "",
              selected_product6: item?.pallet_items?.[5]?.product?.name || "",
              selected_product7: item?.pallet_items?.[6]?.product?.name || "",
              selected_product8: item?.pallet_items?.[7]?.product?.name || "",
              selected_product9: item?.pallet_items?.[8]?.product?.name || "",
              selected_product10: item?.pallet_items?.[9]?.product?.name || "",
              quantity1: item?.pallet_items?.[0]?.case_quantity || "",
              quantity2: item?.pallet_items?.[1]?.case_quantity || "",
              quantity3: item?.pallet_items?.[2]?.case_quantity || "",
              quantity4: item?.pallet_items?.[3]?.case_quantity || "",
              quantity5: item?.pallet_items?.[4]?.case_quantity || "",
              quantity6: item?.pallet_items?.[5]?.case_quantity || "",
              quantity7: item?.pallet_items?.[6]?.case_quantity || "",
              quantity8: item?.pallet_items?.[7]?.case_quantity || "",
              quantity9: item?.pallet_items?.[8]?.case_quantity || "",
              quantity10: item?.pallet_items?.[9]?.case_quantity || "",
            },
          ],
        },
      });
    }
  };
  const changeQtyHandler = async (value: number, min: number, max: number) => {
    if (isNaN(value)) {
      setQty(1);
      await cartItemQty(1);
      setCartEvent("remove_from_cart");
    } else {
      if (value && max && value > max) {
        if (item?.pallet_items?.[0]?.product?.sold_in_stack) {
          setQty(max % 2 === 0 ? max : max - 1);
          await cartItemQty(max % 2 === 0 ? max : max - 1);
        } else {
          setQty(max);
          await cartItemQty(max);
        }
        setCartEvent("add_to_cart");
      } else if (value && min && value < min) {
        setQty(min);
        await cartItemQty(min);
        setCartEvent("remove_from_cart");
      } else if (value && value >= min && value <= max) {
        const eventText =
          item?.quantity < value ? "add_to_cart" : "remove_from_cart";
        if (item?.pallet_items?.[0]?.product?.sold_in_stack) {
          setQty(value % 2 === 0 ? value : value - 1);
          await cartItemQty(value % 2 === 0 ? value : value - 1);
        } else {
          setQty(value);
          await cartItemQty(value);
        }
        setCartEvent(eventText);
      }
    }
  };

  return {
    openMixedPallet,
    changeQty,
    changeQtyHandler,
    cartItemQty,
  };
};

export default useCard;
