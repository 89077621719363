import { useContext, useEffect, useState } from "react";
import { Checkbox, Col, Form, Modal, notification, Row } from "antd";
import classNames from "classnames";
import CustomButton from "../Button";
import { DialogProps } from "./types";
import DataLoader from "~/src/components/shared/DataLoader";
import styles from "./styles.module.scss";
import useWishlist from "~/src/utilities/hooks/useWishlist";
import CustomInput from "~/src/components/shared/Fields";
import { AppContext } from "~/src/libs/context";
import Link from "next/link";
import Tag from "@components/shared/Tag";
import { getText } from "~/src/libs/resources";
import { useRouter } from "next/router";

export interface shoppingListType {
  id: number;
  is_default: boolean;
  name: string;
  products: any;
  uid: string;
}

const AddToShoppingList = ({
  setShowToShoppingListDialog,
  isModalOpen,
  favClick,
  setFavClick,
  setIsWishlist,
  slug,
  productId,
  productName,
  gaForWishList,
  cancelEvent,
  isAddToShoppingList,
  slugs,
  setCheckAll,
  setCheckedList,
}: DialogProps) => {
  const [wishListedItems, setWishListedItems] = useState<any>();
  const { wishlist, selectedWishlist, addNewWishList } = useWishlist();
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(true);
  const [wishListedItemsArray, setWishListedItemsArray] = useState<any>([]);
  const {
    shoppingListUpdated,
    setShoppingListUpdated,
    shoppingList,
    shoppingListLoading,
    setAddToWishListOpened,
  } = useContext<any>(AppContext);
  const [isErrorValue, setIsErrorValue] = useState<boolean>(false);
  const [form] = Form.useForm();
  const router = useRouter();

  const getShoppingList = () => {
    const newArray: any = [];
    const itemsArray: any = [];
    shoppingList?.map((item: any) => {
      item?.products_ids?.map((product: any) => {
        if (product == productId) {
          itemsArray.push(item?.uid);
          const obj: any = {};
          obj[item?.uid] = true;
          newArray.push(obj);
        }
      });
    });

    setWishListedItemsArray(itemsArray);
    const result = newArray.reduce((acc: any, cur: any) => {
      return { ...acc, ...cur };
    }, {});

    setWishListedItems(result);
  };

  useEffect(() => {
    getShoppingList();
  }, [shoppingList]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    setAddToWishListOpened(true)
  }, []);
  const saveToShoppingList = async (values: any) => {
    setShoppingListUpdated(false);
    const valuesArray = Object.entries(values);
    const countSelected = valuesArray.reduce(
      (count: any, item) => count + (item[1] == true),
      0
    );

    const wishlistArray: any = [];
    const unWishlistArray: any = [];

    valuesArray?.map((item) => {
      if (item[1] === true && !wishListedItemsArray?.includes(item[0])) {
        wishlistArray.push(item[0]);
      } else if (item[1] === false && wishListedItemsArray?.includes(item[0])) {
        unWishlistArray.push(item[0]);
      }
    });

    if (countSelected > 0) {
      if (wishlistArray.length > 0) {
        wishlist(slug, "wish", wishlistArray).then((res) => {
          if (res) {
            setFavClick(!favClick);
            setIsWishlist(true);
            gaForWishList();
            notification.success({
              message: `${productName} added to your shopping list(s) successfully.`,
              duration: 2,
            });
          } else {
            notification.error({
              message: `${productName} can't be added to your shopping list(s).`,
              duration: 2,
            });
          }
        });
      }

      if (unWishlistArray?.length > 0) {
        wishlist(slug, "unwish", unWishlistArray).then((res) => {
          if (res) {
            setIsWishlist(true);
            notification.success({
              message: `${productName} removed from your shopping list(s) successfully.`,
              duration: 2,
            });
          }
        });
      }
    } else {
      if (unWishlistArray?.length > 0) {
        wishlist(slug, "unwish", unWishlistArray).then((res) => {
          if (res) {
            setIsWishlist(false);
            notification.success({
              message: `${productName} removed from your shopping list(s) successfully.`,
              duration: 2,
            });
          }
        });
      } else {
        wishlist(slug, "unwish").then((res) => {
          if (res) {
            setIsWishlist(false);
          }
        });
      }

      setIsWishlist(false);
      if (cancelEvent) {
        cancelEvent(false);
      }
    }
    setShoppingListUpdated(true);
    setShowToShoppingListDialog(false);
    document.body.style.overflow = "auto";
  };

  const openCreateNewShoppingListDialog = () => {
    setShowSuccessModal(true);
    setIsErrorValue(false);
  };

  useEffect(() => {
      shoppingList?.forEach((item: any) => {
        form.setFieldValue(item?.uid, false);
      });
      for (const uid in wishListedItems) {
        if (Object.prototype.hasOwnProperty.call(wishListedItems, uid)) {
          form.setFieldValue(uid, true);
        }
      }
  }, [wishListedItems]);

  useEffect(() => {
    if (showSuccessModal) {
      setIsSubmitLoading(false);
    }
  }, [showSuccessModal]);
  const saveToSelectedShoppingList = async (values: any) => {
    const valuesArray = Object.entries(values);
    const wishlistArray: any = [];
    const unWishlistArray: any = [];
    valuesArray?.map((item) => {
      if (item[1] === true && !wishListedItemsArray?.includes(item[0])) {
        wishlistArray.push(item[0]);
      } else if (item[1] === false && wishListedItemsArray?.includes(item[0])) {
        unWishlistArray.push(item[0]);
      }
    });
    selectedWishlist(slugs, wishlistArray).then((res) => {
      if (res) {
        notification.success({
          message: `selected items added to your shopping list(s) successfully.`,
          duration: 2,
        });
      } else {
        notification.error({
          message: `selected items can't be added to your shopping list(s).`,
          duration: 2,
        });
      }
    });
    setShowToShoppingListDialog(false);
    document.body.style.overflow = "auto";
    setCheckedList([]);
    setCheckAll(false);
  };
  return (
    <>
      <Modal
        zIndex={1000}
        title="Add to shopping list"
        onCancel={() => {
          setShowToShoppingListDialog(false);
          document.body.style.overflow = "auto";
          if (cancelEvent) {
            cancelEvent(false);
          }
        }}
        footer={false}
        destroyOnClose={true}
        closable={true}
        keyboard={false}
        open={isModalOpen}
        className={styles.ShoppingListDialog}
      >
        {!shoppingListLoading ? (
          <>
            {typeof wishListedItems === "object" && shoppingList?.length > 0 ? (
              <Form
                initialValues={wishListedItems}
                name="basic"
                wrapperCol={{ span: 24 }}
                style={{ maxWidth: 800 }}
                className="mt-12"
                onFinish={
                  isAddToShoppingList
                    ? saveToSelectedShoppingList
                    : saveToShoppingList
                }
                autoComplete="off"
                form={form}
              >
                <Row justify="end" className="mb-15">
                  <CustomButton
                    theme="link"
                    className={`${styles.creatBtn} `}
                    onClick={() => openCreateNewShoppingListDialog()}
                  >
                    {
                      getText()?.Wishlist?.AddToShoppingList
                        ?.createNewShoppingList
                    }
                  </CustomButton>
                </Row>
                <Row>
                  <Col span={24} className={styles.dialogShoppingListContainer}>
                    {shoppingList?.map((item: any, index: number) => {
                      return (
                        <Row
                          className={`${styles.shoppingListNameWrapper}`}
                          key={index}
                        >
                          <Form.Item
                            key={item?.uid}
                            name={item?.uid}
                            valuePropName="checked"
                            className={`${styles.productName} mb-7`}
                          >
                            <Checkbox>{item?.name}</Checkbox>
                          </Form.Item>
                          <Form.Item className={`${styles.productsCount} mb-7`}>
                            <Link href={`/shared/wishlist/${item?.uid}`}>
                              <a
                                className={`font-bold-md ${styles.productCountText} `}
                              >
                                ({item?.products_ids?.length})
                              </a>
                            </Link>
                          </Form.Item>
                          <Form.Item
                            className={`${styles.defaultShoppingList} mb-7`}
                          >
                            {item?.is_default && (
                              <Tag
                                theme="light"
                                className={`font-bold-xs text-oxfordBlue mt-0 ms-4 me-4`}
                                text="Default"
                                hasIcon={false}
                                size={"sm"}
                              />
                            )}
                          </Form.Item>
                        </Row>
                      );
                    })}
                  </Col>
                </Row>

                <Row
                  justify="space-between"
                  className={`${styles.DialogActionsContainer} pt-4 mt-4`}
                >
                  <Form.Item
                    className={classNames(styles.saveBtnContainer, "mb-0")}
                  >
                    <CustomButton
                      theme="primary"
                      htmlType="submit"
                      className={classNames(
                        styles.saveWishlistBtn,
                        "py-12 font-bold-sm mt-4"
                      )}
                    >
                      {getText()?.Wishlist?.AddToShoppingList?.save}
                    </CustomButton>
                  </Form.Item>
                  <Form.Item
                    className={classNames(styles.saveBtnContainer, "mb-0")}
                  >
                    <CustomButton
                      onClick={() => {
                        setAddToWishListOpened(false);
                        setShowToShoppingListDialog(false);
                        document.body.style.overflow = "auto";
                        router.push("/account/shoppingList");
                      }}
                      theme="primary"
                      className={classNames(
                        styles.saveWishlistBtn,
                        styles.manageBtn,
                        "py-12 font-bold-sm mt-4"
                      )}
                    >
                      {
                        getText()?.Wishlist?.AddToShoppingList
                          ?.manageYourShoppingLists
                      }
                    </CustomButton>
                  </Form.Item>
                </Row>
              </Form>
            ) : (
              <>
                <div className={styles.loaderContainer}>
                  <h3>
                    {
                      getText()?.Wishlist?.AddToShoppingList
                        ?.noShoppingListsAvailable
                    }
                  </h3>
                  <CustomButton
                    theme="primary"
                    className={`${styles.creatBtn} p-10 mt-10`}
                    onClick={() => setShowSuccessModal(true)}
                  >
                    {
                      getText()?.Wishlist?.AddToShoppingList
                        ?.createNewShoppingList
                    }
                  </CustomButton>
                </div>
              </>
            )}
          </>
        ) : (
          <div className={styles.loaderContainer}>
            <DataLoader />
          </div>
        )}
      </Modal>
      <Modal
        open={showSuccessModal}
        title="Create New Shopping List"
        onCancel={() => setShowSuccessModal(false)}
        footer={false}
        destroyOnClose={true}
        closable={false}
        keyboard={false}
        centered
      >
        <Form
          name="Create New Shopping List"
          initialValues={{ recipients: [""] }}
          preserve={false}
          autoComplete="off"
          onFinish={(values: any) =>
            addNewWishList(
              values,
              setIsSubmitLoading,
              getShoppingList,
              setShowSuccessModal,
              setShoppingListUpdated,
              shoppingListUpdated
            )
          }
          className={classNames("mt-12")}
        >
          <Form.Item
            name="shoppingListName"
            className={styles.formItem}
            rules={[
              {
                required: true,
                message: "Shopping list name is required",
              },
              {
                validator: (rule, value, callback) => {
                  if (!value) {
                    callback(rule.message as any);
                    return;
                  }

                  const nameExists = shoppingList.some(
                    (item: any) => item?.name === value.trim()
                  );
                  if (!nameExists) {
                    callback();
                  } else {
                    callback("Shopping list name should be unique.");
                  }
                },
              },
              { whitespace: true },
              {
                max: 30,
                message: "Maximum number of characters allowed is 30",
              },
            ]}
            hasFeedback
          >
            <CustomInput
              type="text"
              className={`bg-white ${
                isErrorValue ? styles.shoppingNameInputError : ""
              } my-3 py-4`}
              placeholder="Enter shopping list name *"
              onChange={() => setIsErrorValue(false)}
            />
          </Form.Item>
          <Form.Item
            className={classNames(styles.saveBtnContainer, "mb-0")}
            wrapperCol={{ offset: 0, span: 24 }}
          >
            <CustomButton
              theme="primary"
              htmlType="submit"
              loading={isSubmitLoading}
              className={classNames(
                styles.saveWishlistBtn,
                "py-12 font-bold-base mt-4"
              )}
            >
              {getText()?.Wishlist?.AddToShoppingList?.save}
            </CustomButton>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddToShoppingList;
