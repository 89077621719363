import { Spin } from "antd";
import { DataLoaderProps } from "./types";

const DataLoader = ({ className, size = "large", onClick }: DataLoaderProps) => {
  return (
    <div className={className} onClick={onClick}>
      <Spin size={size} />
    </div>
  );
};

export default DataLoader;
