import { PLP_PRODUCTS } from "~/src/modules/Products/action";

export const setAllPlpProducts = (allPlpProducts: []) => ({
  type: PLP_PRODUCTS.SET_ALL_PLP_PRODUCTS,
  payload: allPlpProducts,
});

export const setProductSKUs = (productsSKUs: []) => ({
  type: PLP_PRODUCTS.SET_PRODUCTS_SKUs,
  payload: productsSKUs,
});

export const setPlpProducts = (plpProducts: any[]) => ({
  type: PLP_PRODUCTS.SET_PLP_PRODUCTS,
  payload: plpProducts,
});

export const setProductListInfo = (productListInfo?: any) => ({
  type: PLP_PRODUCTS.SET_PRODUCT_LIST_INFO,
  payload: productListInfo,
});

export const setPlpCachedData = (plpCachedData: any) => ({
  type: PLP_PRODUCTS.SET_PLP_CACHED_DATA,
  payload: plpCachedData,
});
