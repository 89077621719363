import React, { memo } from "react";
import styles from "../style.module.scss";
import { getText } from "~/src/libs/resources";
import { casesPerPalletProps } from "../types";
const CasesPerPallet = ({ isVendor, casesPerPallet }: casesPerPalletProps) => {
  return (
    <div className={styles.itemWrapper}>
      <span className={styles.casesPerPallet}>
        {isVendor
          ? getText()?.PDP?.productDetails?.casesPerLayer
          : getText()?.PDP?.productDetails?.casesPerPallet}
      </span>
      <span className={styles.value}>{casesPerPallet}</span>
    </div>
  );
};

export default memo(CasesPerPallet);
