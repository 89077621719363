import { BannerProps } from "./types";
import styles from "./styles.module.scss";
import classnames from "classnames";
import Link from "next/link";
import { useEffect, useRef } from "react";

const SlickBanner = ({
  showBanner,
  bannerTitle,
  bannerLinkLabel,
  bannerLinkUrl
}: BannerProps) => {
  const stringToHTML = function (str: string) {
    const dom = document.createElement("div");
    dom.innerHTML = str;
    return dom;
  };
  const slickBanner: any = useRef();
  useEffect(()=>{
      const observable = new IntersectionObserver(
        ([entry]) => {
          if (entry?.isIntersecting) {
            selectedPromotionBanner("view_promotion");
            observable?.unobserve(slickBanner.current);
          }
        },
        { threshold: 0.2 }
      );
      if (slickBanner.current) {
        observable?.observe(slickBanner.current);
      }
      return () => {
        if (slickBanner.current) {
          observable?.unobserve(slickBanner.current);
        }
      };
  },[slickBanner.current])
  const selectedPromotionBanner = (event: string) => {
    const bannerText = stringToHTML(bannerTitle || "");
    window?.dataLayer?.push({ ecommerce: null });
    window?.dataLayer?.push({
      event: event,
      ecommerce: {
        items: [
          {
            promotion_id: 0,
            promotion_name: bannerText?.innerText?.replace("\n", "") || "",
            creative_name: bannerText?.innerText?.replace("\n", "") || "",
            creative_slot: 1
          }
        ]
      }
    });
  };

  return showBanner ? (
    <div ref={slickBanner} className={classnames(styles.bannerContainer, "bg-oxfordBlue")}>
      <div className={styles.bannerItem} onClick={()=>selectedPromotionBanner("select_promotion")}>
        {bannerTitle && (
          <span
            className={classnames(
              styles.itemText,
              "text-white font-regular-sm py-5"
            )}
            dangerouslySetInnerHTML={{
              __html: bannerTitle
            }}
          />
        )}
        {bannerLinkLabel && (
          <span
            className={classnames(
              styles.shopText,
              "text-white font-bold-sm py-5"
            )}
          >
            {bannerLinkUrl && (
              <Link href={`${bannerLinkUrl}`}>
                <a className="ms-4 pb-2">{bannerLinkLabel}</a>
              </Link>
            )}
          </span>
        )}
      </div>
    </div>
  ) : null;
};

export default SlickBanner;
