import { Col, Row } from "antd";
import styles from "./styles.module.scss";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "~/src/libs/context";
import HaggleReward from "../Dashboard/HaggleReward";
import { getText } from "~/src/libs/resources";
import DataLoader from "~/src/components/shared/DataLoader";
import router from "next/router";
import Dashboard from "../Dashboard";

const HaggleRewards = () => {
  const { customerStatus, setContent } = useContext<any>(AppContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
    if (customerStatus?.is_vendor) {
      router.push("/account/dashboard");
      setContent(<Dashboard/>)
    }
  },[customerStatus])


  return (
    <div>
      <h2 className="font-bold-h2 pt-20 pb-12 mb-0">
        {getText()?.Haggle?.haggle}
      </h2>
      {isLoading ? (
        <Row justify="center" className="py-30">
          <DataLoader />
        </Row>
      ) : (
        <>
          {!customerStatus?.is_haggle_customer ? (
            <HaggleReward />
          ) : customerStatus?.is_haggle_customer && (
            <>
              <Row
                justify="space-around"
                align="middle"
                className={`pt-37 pb-35 ${styles.haggleWrapper}`}
              >
                <Col span={6} className={styles.haggleInfo}>
                  <p className="font-medium-xs text-darkGray">
                    {getText()?.Haggle?.joinDate}
                  </p>
                  <p className="font-bold-h2">
                    {customerStatus?.haggle_join_date || "-"}
                  </p>
                </Col>
                <Col span={6} className={styles.haggleInfo}>
                  <p className="font-medium-xs text-darkGray">
                    {getText()?.Haggle?.Anniversary}
                  </p>
                  <p className="font-bold-h2">
                    {customerStatus?.tier_start_date || "-"}
                  </p>
                </Col>
                <Col span={6} className={styles.haggleInfo}>
                  <p className="font-medium-xs text-darkGray">
                    {getText()?.Haggle?.Tier}
                  </p>
                  <p className="font-bold-h2">
                    {customerStatus?.current_tier || "-"}
                  </p>
                </Col>
                <Col span={6} className={styles.haggleInfo}>
                  <p className="font-medium-xs text-darkGray">
                    {getText()?.Haggle?.availableRewards}
                  </p>
                  <p className="font-bold-h2">
                    ${customerStatus?.available_haggle_rewards}
                  </p>
                </Col>
              </Row>
            </>
          ) }
        </>
      )}
    </div>
  );
};

export default HaggleRewards;
