import React from "react";
const _ = require("lodash");

import {
  Drawer,
  Space,
  Row,
  Form,
  Col,
  Checkbox,
  Radio,
  RadioChangeEvent
} from "antd";
import Button from "@components/shared/Button";
import Icon from "@components/shared/Icon";
import styles from "./styles.module.scss";
import classnames from "classnames";
import Field from "~/src/components/shared/Fields";
import { useState, useEffect } from "react";
import { getText } from "~/src/libs/resources";

const DrawerMenu = ({
  openMenu,
  setOpenMenu,
  fetchData,
  updateUser,
  deleteHandler,
  resendInviteHandler
}: any) => {
  const AdminOptions = [
    "MANAGE_USERS",
    "MANAGE_ORDERS",
    "MANAGE_SHIPPING_ADDRESSES",
    "VIEW_ORDERS",
    "VIEW_USERS",
    "VIEW_SHIPPING_ADDRESSES"
  ];
  const ManagerOptions = [
    "MANAGE_USERS",
    "MANAGE_ORDERS",
    "VIEW_ORDERS",
    "VIEW_USERS"
  ];
  const [radioGroupDisabled, setRadioGroupDisabled] = useState(false);

  const onClose = () => {
    setOpenMenu(false);
    form?.resetFields();
  };

  const premissionChange = (e: RadioChangeEvent) => {
    if (e?.target?.value == "Manager") {
      form?.setFieldsValue({
        checkboxGroup: fetchData?.is_customer_admin
          ? []
          : fetchData?.permissions
      });
      setRadioGroupDisabled(false);
    } else if (e?.target?.value == "Admin") {
      form?.setFieldsValue({
        checkboxGroup: AdminOptions
      });
      setRadioGroupDisabled(true);
    } else {
      setRadioGroupDisabled(false);
    }
  };

  const [form] = Form?.useForm();
  useEffect(() => {
    form?.resetFields();
  }, [fetchData]);
  useEffect(() => {
    if (openMenu) {
      setRadioGroupDisabled(fetchData?.is_customer_admin);
      let initialValues = {
        permissions: fetchData?.is_customer_admin ? "Admin" : "Manager",
        email: fetchData?.email,
        contact_name: fetchData?.contact_name,
        checkboxGroup: fetchData?.permissions
      };
      form?.setFieldsValue(initialValues);
    }
  }, [openMenu]);

  const onFinish = async (values: any) => {
    const bodyData = {
      contact_name: values?.contact_name,
      email: values?.email,
      is_customer_admin: radioGroupDisabled,
      permissions: values?.checkboxGroup
    };
    updateUser(bodyData);
    onClose();
    form.resetFields();
  };
  const onFinishFailed = (errorInfo: any) => {};
  return (
    <>
      <Drawer
        className={styles.drawer}
        title="Edit User"
        placement="right"
        closable={false}
        width="100%"
        onClose={onClose}
        open={openMenu}
        extra={
          <Space>
            <Button
              className={styles.closeButton}
              theme={"primary"}
              onClick={onClose}
              hasIcon={true}
              icon={
                <Icon
                  name="cross"
                  width={20}
                  height={20}
                  color="#ffffff"
                  dir="top"
                />
              }
            />
          </Space>
        }
      >
        <Form
          onFinish={onFinish}
          autoComplete="off"
          onFinishFailed={onFinishFailed}
          form={form}
          disabled={!fetchData?.permissions_can_be_changed}
        >
          <Row gutter={[16, 12]}>
            <Col span={24}>
              <Form.Item
                name="contact_name"
                rules={[
                  { required: true, message: "This field should not be empty" }
                ]}
              >
                <Field
                  placeholder="Name"
                  label="Name"
                  className="manageAccountField"
                  labelUp={true}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "This field should not be empty" }
                ]}
              >
                <Field
                  label="Email"
                  className="manageAccountField"
                  labelUp={true}
                  disabled={true}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[0, 16]} className="pt-12">
            <Col span={24} className="font-bold-base text-oxfordBlue">
              {getText()?.Account?.permissions}
            </Col>
            <Col span={24}>
              <Form.Item name="permissions">
                <Radio.Group onChange={premissionChange}>
                  <Radio value="Manager"> {getText()?.Account?.manager} </Radio>
                  <Radio value="Admin">{getText()?.Account?.admin}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row className={styles.checkboxGroup}>
            <Col span={24}>
              <Form.Item
                name="checkboxGroup"
                className="pt-12"
                rules={[
                  () => ({
                    validator(_, value) {
                      if (
                        value?.includes("MANAGE_ORDERS") &&
                        !value?.includes("VIEW_ORDERS")
                      )
                        return Promise.reject(
                          new Error("MANAGE ORDERS must include VIEW ORDERS")
                        );
                      else if (
                        value?.includes("MANAGE_SHIPPING_ADDRESSES") &&
                        !value?.includes("VIEW_SHIPPING_ADDRESSES")
                      )
                        return Promise.reject(
                          new Error(
                            "MANAGE SHIPPING ADDRESSES must include VIEW SHIPPING ADDRESSES"
                          )
                        );
                      else if (
                        value?.includes("MANAGE_USERS") &&
                        !value?.includes("VIEW_USERS")
                      )
                        return Promise.reject(
                          new Error("MANAGE USERS must include VIEW USERS")
                        );
                      else if (value?.length === 0) {
                        return Promise.reject(
                          new Error("Please select at least one permission")
                        );
                      } else return Promise.resolve();
                    }
                  })
                ]}
              >
                <Checkbox.Group
                  style={{ width: "100%" }}
                  disabled={radioGroupDisabled}
                >
                  <Checkbox value="MANAGE_ORDERS">
                    {getText()?.Account?.manageOrders}
                  </Checkbox>
                  <Checkbox value="VIEW_ORDERS">
                    {getText()?.Account?.viewOrders}
                  </Checkbox>
                  <Checkbox value="MANAGE_SHIPPING_ADDRESSES">
                    {getText()?.Account?.manageShippingAddress}
                  </Checkbox>
                  <Checkbox value="VIEW_SHIPPING_ADDRESSES">
                    {getText()?.Account?.viewShippingAddress}
                  </Checkbox>
                  <Checkbox value="MANAGE_USERS">
                    {getText()?.Account?.manageUsers}
                  </Checkbox>
                  <Checkbox value="VIEW_USERS">
                    {getText()?.Account?.viewUsers}
                  </Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>
          {fetchData?.is_main_customer_user ? null : (
            <Row>
              <Form.Item>
                <Button
                  theme="link"
                  onClick={() => {
                    deleteHandler(fetchData);
                    onClose();
                  }}
                  className={styles.deleteBtn}
                  hasIcon={true}
                  icon={<Icon name="delete" width={22} height={22} />}
                >
                  {getText()?.Account?.removeUserButton}
                </Button>
              </Form.Item>
            </Row>
          )}
          {fetchData?.email_verified ? null : (
            <Row>
              <Button
                theme="link"
                onClick={() => {
                  resendInviteHandler(fetchData);
                  onClose();
                }}
                className={styles.deleteBtn}
                hasIcon={true}
                icon={<Icon name="sent" width={22} height={22} />}
              >
                {getText()?.Account?.resendInviteButton}
              </Button>
            </Row>
          )}
          <Row className={styles.btnForm}>
            <Form.Item>
              <Button
                theme="primary"
                htmlType="submit"
                className={classnames(
                  styles.filterBttn,
                  "font-bold-base py-10 my-5"
                )}
              >
                {getText()?.Account?.saveButton}
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};
export default DrawerMenu;
