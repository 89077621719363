import { FooterProps } from "./types";
import { Col, Row } from "antd";
import Icon from "@components/shared/Icon";
import { FooterListItem } from "./FooterListItem";
import Link from "next/link";
import styles from "./styles.module.scss";
import UpperFooter from "./UpperFooter";
import classnames from "classnames";
import CompareProduct from "@components/shared/CompareProduct";
import { useRouter } from "next/router";
import { useContext } from "react";
import { AppContext } from "~/src/libs/context";
import Image from "next/image";
import HubSpotForm from "./HubSpotForm";

const Footer = ({
  logoImage,
  description,
  privacyLink,
  privacyText,
  copyrightText,
  NewsAndOffers,
  footerLists,
  footerHeading,
  socialIconsData,
}: FooterProps & {
  className?: string;
}) => {
  const router = useRouter();
  const { compareProductsSku, guestUser } = useContext<any>(AppContext);

  return (
    <footer>
      {router.pathname !== "/compare" && compareProductsSku?.length > 0 ? (
        <CompareProduct />
      ) : null}
      <UpperFooter footerHeading={footerHeading} />
      <div className={styles.footerContent}>
        <Row wrap={true} className={`container ${styles.footerRowWrapper}`}>
          <Col span={24} lg={{ span: 8 }} className={styles.leftSide}>
            <div className={styles.imageWrapper}>
              <Link href="/">
                <a aria-label="navigate to home page">
                  <Image
                    unoptimized
                    src={logoImage?.url}
                    alt={logoImage?.alt || "ePallet-logo"}
                    width={160}
                    height={53}
                  />
                </a>
              </Link>
            </div>
            {description && (
              <div
                className={classnames(
                  styles.description,
                  "font-regular-sm lh-xl mb-20 mt-10"
                )}
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            )}
            {privacyLink && (
              <Link href={privacyLink} target="_blank">
                <a
                  className={classnames(
                    styles.privacyLink,
                    "text-oxfordBlue font-bold-sm lh-sm"
                  )}
                >
                  {privacyText}
                </a>
              </Link>
            )}
          </Col>
          <Col span={24} lg={{ span: 16 }} className={styles.rightSide}>
            <Row wrap={true}>
              {footerLists?.map((item: any, index: number) => (
                <Col span={12} lg={{ span: 5 }} key={index} className={styles.linkCol}>
                  <h1 className="font-bold-h5 lh-xl mb-10">
                    {item?.listName}
                  </h1>
                  {item?.listItems && <FooterListItem list={item?.listItems} />}
                </Col>
              ))}

              <Col span={24} lg={{ span: 9 }}>
                <h1 className="font-bold-h5 lh-xl mb-10 ">
                  {NewsAndOffers}
                </h1>

                <HubSpotForm/>
                {socialIconsData && (
                  <div className={styles.socialIconsWrapper}>
                    {socialIconsData?.map((item, index) => (
                      <div className={styles.SocialIconCol} key={index}>
                        <Link href={item?.url} key={index}>
                          <a aria-label={`navigate to ${item?.icon}`}>
                            <Icon
                              name={item?.icon}
                              width={18}
                              height={18}
                              color="#02254A"
                            />
                          </a>
                        </Link>
                      </div>
                    ))}
                  </div>
                )}
                {copyrightText && (
                  <div
                    className={classnames(
                      styles.copyRightWrapper,
                      "font-regular-sm lh-md mt-2"
                    )}
                    dangerouslySetInnerHTML={{
                      __html: copyrightText,
                    }}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default Footer;
