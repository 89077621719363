import { signOut } from "next-auth/react";
import { getCookie, setCookie, deleteCookie } from "cookies-next";
import axios from "axios";
export const logoutHandler = async (path?: any) => {
  const domain = getCookie("login_domain") || "";
  
  try {
    await axios.post(
      "/api/epallet-logout",
      {},
      { headers: { sessionid: getCookie("sessionid") } }
    );
  } catch (error) {}
  const isSessionValid = process?.env?.NEXT_PUBLIC_APP_ENV === "development"? 'staging_valid_session' : 'valid_session'
  const ePalletDomain = process?.env?.NEXT_PUBLIC_PARENT_DOMAIN
  setCookie(isSessionValid, "", {
    path: "/",
    httpOnly: false,
    secure: true,
    domain: ePalletDomain
  });
  setCookie("userID","" , {
    path: "/",
    sameSite: "strict",
    secure: true
  });
 
  setCookie("beta_cookie", false, {
    path: "/",
    sameSite: "strict",
    secure: true,
    domain:ePalletDomain
  });

  deleteCookie("compareSkus");

  const redirectPath = path ? `${process.env.NEXT_PUBLIC_APP_URL}${path}` : process.env.NEXT_PUBLIC_APP_URL;
  signOut({ callbackUrl: redirectPath, redirect: true });
};
