import { Modal } from "antd";
import styles from "./styles.module.scss";
import { thankYouModalProps } from "./types";
import { getText } from "~/src/libs/resources";
import CustomButton from "../Button";
import { useRouter } from "next/router";

const ThankYouModal = ({
  setThankYouModalOpen,
  isThankYouModalOpen,
}: thankYouModalProps) => {
  const router = useRouter();
  return (
    <Modal
      className={styles.haggleModal}
      centered
      open={isThankYouModalOpen}
      footer={null}
      onCancel={() => setThankYouModalOpen(false)}
    >
      <h2 className="font-bolder-lg mb-2 mt-14">
        {getText()?.Haggle?.thankYou}
      </h2>
      <p className="font-medium-h6 mb-20">{getText()?.Haggle?.willBeOnTouch}</p>
      {router?.asPath !== "/move" && (
        <CustomButton
          text="Start Shopping"
          className="mb-30 font-semibold-xs"
          theme="primary"
          onClick={() => router.push("/")}
        />
      )}
    </Modal>
  );
};

export default ThankYouModal;
