import React, { useEffect, useState } from "react";
import styles from "../styles.module.scss";
import Image from "next/image";
import Link from "next/link";
import Icon from "~/src/components/shared/Icon";

function TabContent({ content, setMenuOpen }: any) {
  const closeMenu = () => {
    setMenuOpen(false);
  };
  const [visibleCategoryBanner, setVisibleCategoryBanner] = useState("true");
  const [categoryImg, setCategoryImage] = useState("");
  const [categoryDesc, setCategoryDesc] = useState("");
  const [categoryDateValue, setCategoryDate] = useState("");
  const [categoryLink, setCategoryLink] = useState("");
  const [btnText, setBtnText] = useState("");
  const getCategoryInfo = () => {
    content?.params?.forEach((element: any) => {
      element?.kind === "showCategoryBanner" &&
        setVisibleCategoryBanner(element?.value);
      element?.kind === "categoryImage" && setCategoryImage(element?.value);
      element?.kind === "categoryDate" && setCategoryDate(element?.value);
      element?.kind === "categoryReadMoreURL" &&
        setCategoryLink(element?.value);
      element?.kind === "categoryDescription" &&
        setCategoryDesc(element?.value);
      element?.kind === "categoryReadMoreText" && setBtnText(element?.value);
    });
  };
  useEffect(() => {
    getCategoryInfo();
  }, []);
  const navigationHandler = (item: any) => {
    const arrayOfCategory = item?.path?.split("/");
    const category =
      arrayOfCategory?.length > 3
        ? `${item?.path?.split("/")[1]}/${item?.path?.split("/")[2]}`
        : item?.path?.split("/")[1];
    window?.dataLayer?.push({
      event: "navigation_click",
      item_click: item?.name, //label of navigation button clicked
      nav_category:
        category === "Food" || category === "Non-Food"
          ? "Shop by category"
          : "Shop by business", //Shop by category, Shop by brand
      nav_category2: category //Food, Non-Food
    });
  };
  const menuItems = content?.children?.filter((item:any) => item.isActive);
  return (
    <div className={styles.tabContent}>
      <div className={styles.ulData}>
        {menuItems?.length > 10 ? (
          <>
            <ul>
              {menuItems?.slice(0, 10)?.map((item: any) => {
                return (
                  <li key={item?.id} onClick={closeMenu}>
                    <Link href={`/product-list${item?.path}`}>
                      <a onClick={() => navigationHandler(item)}>
                        {item?.name}
                      </a>
                    </Link>
                  </li>
                );
              })}
            </ul>
            <ul>
              {menuItems?.slice(10, 20)?.map((item: any) => {
                return (
                  <li key={item?.id} onClick={closeMenu}>
                    <Link href={`/product-list${item?.path}`}>
                      <a onClick={() => navigationHandler(item)}>
                        {item?.name}
                      </a>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </>
        ) : (
          <ul>
            {menuItems?.map((item: any) => {
              return (
                <li key={item?.id} onClick={closeMenu}>
                  <Link href={`/product-list${item?.path}`}>
                    <a onClick={() => navigationHandler(item)}>{item?.name}</a>
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </div>
      {visibleCategoryBanner === "true" && (
        <div className={styles.banner}>
          {categoryImg && (
            <Image
              className=""
              width={343}
              height={233}
              src={categoryImg}
              alt="category image"
            />
          )}

          <div className={styles.dateSection}>
            <Icon name="calender" width={16} height={16} />
            <span className={styles.date}>{categoryDateValue}</span>
          </div>
          <div className={styles.title}>{categoryDesc}</div>
          <Link href={categoryLink}>
            <a className={styles.readMore} onClick={closeMenu}>
              <span className="font-bold-base text-oxfordBlue py-5">
                {btnText}
              </span>
            </a>
          </Link>
        </div>
      )}
    </div>
  );
}

export default TabContent;
