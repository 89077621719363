import { RuleObject } from "antd/es/form";
const countHyphens = (phoneNumber: string) => {
  const hyphenCount = phoneNumber.split("-").length - 1;
  return hyphenCount;
};
export const phoneValidator = (
  _: RuleObject,
  value: string,
  fieldName: string
) => {
  if (fieldName === "phone_number" && !value) {
    return Promise.reject("This field should not be empty");
  } else if (fieldName === "phone_number" && value) {
    const normalizedValue = value.replace(/\D/g, "");
    if (normalizedValue) {
      if (normalizedValue.length !== 10 || countHyphens(value) !== 2) {
        return Promise.reject(
          "Please enter a valid phone number, e.g., 123-123-1234."
        );
      } else {
        return Promise.resolve();
      }
    }else{
      return Promise.reject("This field should not be empty");
    }
  }
  return Promise.resolve();
};
export const normalizePhoneNumber = (value: string) => {
  const normalizedValue = value.replace(/[^\d-()]/g, "");
  let formattedValue = normalizedValue.replace(
    /(\d{3})(\d{3})(\d{4})/,
    "$1-$2-$3"
  );
  if (formattedValue.length === 13 && formattedValue.charAt(0) === "(") {
    formattedValue = formattedValue.replace("(", "");
    formattedValue = formattedValue.replace(")", "");
    formattedValue = formattedValue.replace("-", "");
    formattedValue =
      formattedValue.slice(0, 3) +
      "-" +
      formattedValue.slice(3, 6) +
      "-" +
      formattedValue.slice(6);
  }
  return formattedValue;
};
