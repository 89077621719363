import Image from "next/image";
import { ArticleProps } from "./types";
import styles from "./style.module.scss";
import ArticleImage from "@assets/images/article.png";
import Bookmark from "@assets/icons/bookmark.svg";
import MenuDots from "@assets/icons/menuDots.svg";
import { Button } from "antd";
import CustomButton from "@components/shared/Button";

const Product = ({
  readMoreUrl = "",
  imgSrc = "",
  title,
  categories = []
}: ArticleProps & {
  className?: string;
}) => {
  return (
    <div className={styles.articleCard}>
      <Image src={ArticleImage} alt="article image" />
      <Button
        className={styles.bookmarkBtn}
        tabIndex={-1}
        icon={<Bookmark aria-label="bookmark icon" />}
      />
      <div className={styles.articleContent}>
        <span className={styles.titleText}>{title}</span>
        <div className={styles.categoriesContainer}>
          {categories?.slice(0, 3)?.map((category, index) => (
            <div className={styles.categoryItem} key={index}>
              {category}
            </div>
          ))}
          {categories?.length > 3 && (
            <div
              className={styles.categoryItem}
              style={{
                padding: "0px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <MenuDots />
            </div>
          )}
        </div>

        <CustomButton
          className={styles.readMoreText}
          theme={"link"}
          tabIndex={-1}
        >
          Read More
        </CustomButton>
      </div>
    </div>
  );
};

export default Product;
