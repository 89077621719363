export const MIXED_PALLET_ACTIONS = {
  SET_LOADING: "palletLoading",
  SET_PALLET_NAME: "setPalletName",
  SET_PALLET_ITEMS: "setPalletItems",
  SET_VENDOR_PRODUCTS: "setVendorProducts",
  SET_PALLET_LOADED_PROGRESS: "setPalletLoadedProgress",
  SET_PALLET_FILTERS: "setPalletFilters",
  SET_IS_LOADED: "setIsLoaded",
  SET_SKU: "setSKU",
  APPLIED_PALLET_FILTERS: "appliedPalletFilters",
  SET_VIEW_DETAILS: "setViewDetails",
  SET_VENDOR_ID: "setVendorId",
  SET_VENDOR_NAME: "setVendorName",
  SET_OFFSET: "setOffset",
  SET_PALLET_PRICE: "setPalletPrice",
  SET_VENDOR_PRODUCTS_COUNT: "setVendorProductsCount",
  SET_NEXT: "setNext",
  SET_PRODUCT_INFO: "setProductInfo",
  SET_ATTRIBUTE_GROUPS: "setAttributeGroups",
  SET_PRICES_DATA: "setPricesData",
  INITIATE_MIXED_PALLET: "initiateMixedPallet",
};
