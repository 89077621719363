import classnames from "classnames";
import { Input } from "antd";
import { FieldProps } from "./types";
import styles from "./style.module.scss";
import { useTime } from "~/src/utilities/hooks/useTime";
import DataLoader from "../DataLoader";

const Field = ({
  label,
  id,
  addonAfter,
  addonBefore,
  placeholder,
  onChange,
  hasIcon = false,
  Icon,
  className,
  type,
  Ref,
  status,
  disabled = false,
  isReadOnly = false,
  isValid = true,
  validationMsg,
  value,
  allowClear,
  onFocus,
  onBlur,
  autoFocus,
  onPressEnter,
  onIconClick,
  labelUp,
  loading = false,
  enterKeyHint,
  max,
  min,
  onKeyPress,
  prefix
}: FieldProps) => {
  const inputValue =
    id === "for_delivery_from" || id === "for_delivery_to"
      ? useTime(value as string)
      : id === "phone_number"
      ? value?.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
      : value;
  const fieldIcon = hasIcon ? (
    Icon ? (
      loading ? (
        <DataLoader size="small"/>
      ) : (
        <Icon onClick={() => onIconClick && onIconClick()} aria-label="icon" />
      )
    ) : null
  ) : null;
  const containerClasses = classnames(
    className,
    prefix?"input-prefix":"",
    hasIcon || isReadOnly || labelUp ? "input-wrapper" : "input-field",
    styles[`btn-${status}`],
    {
      "read-only": isReadOnly,
    },
    styles[`btn-${status}`],
    {
      "label-up": labelUp,
    }
  );
  return (
    <>
      <Input
        onKeyDown={onKeyPress}
        enterKeyHint={enterKeyHint}
        onBlur={onBlur}
        onFocus={onFocus}
        allowClear={allowClear}
        onChange={onChange}
        value={inputValue}
        className={containerClasses}
        placeholder={disabled? '-': placeholder}
        addonAfter={isReadOnly || labelUp ? label : addonAfter}
        disabled={disabled}
        type={type}
        suffix={prefix?undefined:fieldIcon}
        readOnly={isReadOnly}
        autoFocus={autoFocus}
        onPressEnter={onPressEnter}
        ref={Ref}
        id={id}
        min={min}
        max={max}
        addonBefore={addonBefore}
        prefix={prefix?fieldIcon:undefined}
      />
      {!isValid ? <div className="text-danger">{validationMsg}</div> : ""}
    </>
  );
};

export default Field;
