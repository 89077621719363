import { useEffect, useState } from "react";

export const useWindowResize = () => {
  const [size, setSize] = useState(0);

  useEffect(() => {
    function handleWindowResize() {
      setSize(window.innerWidth);
    }

    window.addEventListener("resize", handleWindowResize);
    handleWindowResize();

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return size;
};
