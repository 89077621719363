import React from "react";
import { Col, Row } from "antd";
import Icon from "@components/shared/Icon";
import styles from "./styles.module.scss";
import classnames from "classnames";

const UpperFooter = ({footerHeading}:any) => {
  return (
    <div className={`bg-oxfordBlue py-22 ${styles.footerContainer}`}>
      <Row
        justify="center"
        wrap={true}
        className={classnames(
          styles.upperFooterWrapper,
          "font-semibold-h5 text-white container"
        )}
      >
        {footerHeading?.map((item:any, index:number)=>(
        <Col span={12} lg={{ span: 5 }} md={{ span: 6 }} key={index} className={styles.itemWrapper}>
        <div className={styles.iconColContainer}>
          <Icon
            name={item?.icon}
            width={50}
            height={50}
            color="white"
            aria-label={item?.alt}
          />
          <div className={styles.items}
            dangerouslySetInnerHTML={{
              __html: item?.ItemText
            }}
          >
          </div>
        </div>
      </Col>
        ))}
      </Row>
    </div>
  );
};

export default UpperFooter;
