import Field from "@components/shared/Fields";
import { Col, Row, Form, Input, Divider, notification } from "antd";
import CustomCheckbox from "@components/shared/Checkbox";
import styles from "./styles.module.scss";
import CustomButton from "@components/shared/Button";
import epalletLogo from "@assets/images/epalletLogo.png";
import Image from "next/image";
import { useState, useContext, useEffect } from "react";
import axios from "axios";
import { setCookie } from "cookies-next";
import { AppContext } from "~/src/libs/context";
import { useRouter } from "next/router";
import Link from "next/link";
import Icon from "~/src/components/shared/Icon";
import { useSession, signIn } from "next-auth/react";
import { getText } from "@src/libs/resources";
import { useWindowResize } from "@src/utilities/hooks/useWindowResize.js";
import SEO from "~/src/components/shared/SEO";
import googleLogo from "@assets/images/google_normal.png";
import { normalizePhoneNumber } from "~/src/libs/utils/phoneValidator";
import Script from "next/script";

const SignUpPage = () => {
  const router = useRouter();
  const { guestUser, setGuestUser, completeProfileLink, isVendor } =
    useContext<any>(AppContext);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [signUpDone, setSignUpDone] = useState(false);
  const [userEmail, setUserEmail] = useState("your email");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [form] = Form.useForm();
  const { data: session } = useSession();
  const { status: sessionStatus } = useSession();
  const windowWidth = useWindowResize();
  const [receiveNewsLetter, setReceiveNewsLetter] = useState<boolean>(false);
  const vendorQuery = router?.query?.vendor;
  useEffect(() => {
    if (!guestUser && (isVendor || !vendorQuery)) window.location.href = "/";
  }, [isVendor]);
  useEffect(() => {
    if (vendorQuery) {
      const timer = setInterval(() => {
        if (
          !window?.hbspt ||
          !process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID ||
          !process.env.NEXT_PUBLIC_SIGN_UP_FORM_ID
        )
          return;
        window?.hbspt?.forms.create({
          region: "na1",
          portalId: process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID,
          formId: process.env.NEXT_PUBLIC_SIGN_UP_FORM_ID,
          target: "#signUpForm",
        });
        clearInterval(timer);
      }, 1000);
    }
  }, []);

  useEffect(() => {
    router?.beforePopState(({ as }) => {
      window.location.href = as;
      return true;
    });
  }, []);

  useEffect(() => {
    if (session) {
      onFinish({});
    }
  }, [session]);

  const onFinish = async (values: any) => {
    setButtonLoading(true);
    const credentials = session
      ? {
          access_token: (session as any)?.accessToken,
          provider: "google-oauth2",
        }
      : {
          email: values.email,
          password: values.password,
          phone: values.phone,
          showPromotions: receiveNewsLetter ? ["PROMO"] : [],
        };
    setUserEmail(session ? session?.user?.email : credentials?.email);
    try {
      let isNewUser = false;
      setErrorMessage("");
      const response = await axios.post(
        session
          ? `/api/social-auth`
          : `/api/epallet-signup/signup?type=CUSTOMER`,
        credentials
      );

      setGuestUser(false);
      const isSessionValid =
        process?.env?.NEXT_PUBLIC_APP_ENV === "development"
          ? "staging_valid_session"
          : "valid_session";
      const ePalletDomain = process?.env?.NEXT_PUBLIC_PARENT_DOMAIN;
      setCookie(isSessionValid, "valid", {
        httpOnly: false,
        secure: true,
        domain: ePalletDomain,
      });
      setCookie("beta_cookie", true, {
        path: "/",
        sameSite: "strict",
        secure: true,
        domain: ePalletDomain,
      });
      setButtonLoading(false);
      session
        ? (isNewUser = !response?.data?.response?.profile_completed)
        : (isNewUser = false);
      session && isNewUser
        ? (window.location.href = completeProfileLink)
        : null;
      session && !isNewUser
        ? (window.location.href = "/")
        : sessionStatus === "authenticated"
        ? null
        : setSignUpDone(true);
      window?.dataLayer?.push({
        event: "sign_up",
        method: session ? "Google" : "Email",
        status: "signup_attempt",
        message: "Attempt",
      });
      window?.dataLayer?.push({
        event: "sign_up",
        method: session ? "Google" : "Email",
        status: "signup_success",
        message: "success",
      });
    } catch (error: any) {
      window?.dataLayer?.push({
        event: "sign_up",
        method: session ? "Google" : "Email",
        status: "signup_attempt",
        message: "Attempt",
      });
      window?.dataLayer?.push({
        event: "sign_up",
        method: session ? "Google" : "Email",
        status: "signup_failure",
        message: "fail",
      });

      setErrorMessage(error?.response?.data);
      setButtonLoading(false);
    }
  };
  const onFinishFailed = (errorInfo: any) => {};
  const onValuesChange = (changedValues: any, allValues: any) => {
    setErrorMessage({});
  };
  const resendValidationEmail = async () => {
    const reqBody = {
      email: userEmail,
    };
    try {
      setButtonLoading(true);
      setErrorMessage("");
      const response = await axios.post(
        `/api/ERP/request-link?type=EMAIL_VERIFICATION`,
        reqBody
      );
      notification.success({
        message: `An e-mail has been sent in order to validate your address! `,
        duration: 4,
      });
    } catch (error: any) {
      notification.error({
        message:
          error?.response?.data?.detail ||
          `${getText().General.Messages.SomethingWrongTryAgain}`,
        duration: 4,
      });
    }
    setButtonLoading(false);
  };

  const epalletLogoImage = (
    <Image
      src={epalletLogo}
      width="126"
      height="46"
      alt="ePallet-logo"
      className={styles["cursor-pointer"]}
    />
  );
  return (
    <>
      <SEO
        title={getText()?.SignUp?.SEO?.title}
        description={getText()?.SignUp?.SEO?.description}
      ></SEO>
      <Row
        className={`bg-white text-oxfordBlue container-fluid p-0 ${styles["outer-container"]}`}
      >
        <Col
          lg={{ span: 0 }}
          md={{ span: 2 }}
          sm={{ span: 2 }}
          xs={{ span: 2 }}
        />
        <Col
          lg={{ span: 0 }}
          md={{ span: 22 }}
          sm={{ span: 22 }}
          xs={{ span: 22 }}
          className={`mt-8`}
        >
          {signUpDone ? (
            <a href="/">{epalletLogoImage} </a>
          ) : (
            <Link href="/">
              <a>{epalletLogoImage}</a>
            </Link>
          )}
        </Col>
        <Col
          lg={{ span: 10 }}
          md={{ span: 0 }}
          sm={{ span: 0 }}
          xs={{ span: 0 }}
          className={`${styles["left-container"]} px-0`}
        >
          <Row className={styles.imageContainer}>
            <Col span={10} className={`pt-26`}>
              {signUpDone ? (
                <a href="/">{epalletLogoImage} </a>
              ) : (
                <Link href="/">
                  <a>{epalletLogoImage}</a>
                </Link>
              )}
              <div className={`${styles.yourSupply} font-bolder-xxl lh-sm`}>
                {getText()?.General?.chain}
              </div>
            </Col>
          </Row>
        </Col>

        <Col
          lg={{ span: vendorQuery ? 12 : 10 }}
          md={{ span: 20 }}
          sm={{ span: 20 }}
          xs={{ span: 20 }}
          className={`mx-auto ${
            vendorQuery ? "pt-20 pb-15 my-20" : `${styles["form-container"]} py-24 my-24`}`}
        >
          {vendorQuery ? (
            <div>
              <div
                className="mb-20 font-regular-sm text-black text-right"
              >
                {getText()?.SignUp?.alreadyHaveAccount}
                <Link href={"/?epallet_login=true"}>
                  <a>
                    <CustomButton
                      theme="link"
                      className={`${styles["heigh-23"]} ms-2`}
                    >
                      <span className="font-bold-sm lh-lg">
                        {getText()?.General?.SignIn}
                      </span>
                    </CustomButton>
                  </a>
                </Link>
              </div>
              <div id="signUpForm">
                <Script
                  type="text/javascript"
                  src="//js.hsforms.net/forms/embed/v2.js"
                />
              </div>
            </div>
          ) : (
            <>
              {!signUpDone ? (
                <Form
                  onFinish={onFinish}
                  initialValues={{ remember: true }}
                  onFinishFailed={onFinishFailed}
                  form={form}
                  onValuesChange={onValuesChange}
                >
                  <Row gutter={16}>
                    <Col
                      className={windowWidth >= 992 ? "mb-16" : "mb-10"}
                      span={24}
                    >
                      <span
                        className={`${styles["signup-title"]} font-bolder-lg`}
                      >
                        {getText().SignUp.signUpAsCustomer}
                      </span>
                    </Col>
                    <Col
                      className={`gutter-row mb-12`}
                      lg={{ span: 18 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="font-base-sm lh-lg">
                        {getText().SignUp.registerNowToAccessALL}
                      </span>
                    </Col>
                    <Col
                      className={`gutter-row`}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <Form.Item
                        name="email"
                        {...(errorMessage?.email
                          ? {
                              validateStatus: "error",
                            }
                          : {})}
                        help={
                          errorMessage?.email ? errorMessage?.email[0] : null
                        }
                        rules={[
                          {
                            type: "email",
                            required: true,
                            message: "Please input a valid email.",
                          },
                        ]}
                      >
                        <Field
                          placeholder="Email"
                          className={`${styles["border-radius"]} p-5`}
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      className={`gutter-row`}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <Form.Item
                        name="phone"
                        normalize={normalizePhoneNumber}
                        {...(errorMessage?.phone
                          ? {
                              validateStatus: "error",
                            }
                          : {})}
                        help={
                          errorMessage?.phone ? errorMessage?.phone[0] : null
                        }
                        rules={[
                          {
                            message: "Please input your phone number!",
                          },
                          ({}) => ({
                            validator(_, value) {
                              setErrorMessage({});
                              if (
                                /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
                                  value
                                ) &&
                                value.length >= 8
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error("Please input a valid phone number")
                              );
                            },
                          }),
                        ]}
                      >
                        <Field
                          placeholder="Phone"
                          className={`${styles["border-radius"]} p-5`}
                        />
                      </Form.Item>
                    </Col>

                    <Col className={`gutter-row`} span={24}>
                      <Form.Item
                        name="password"
                        {...(errorMessage?.password
                          ? {
                              validateStatus: "error",
                            }
                          : {})}
                        help={
                          errorMessage?.password ? (
                            <ul className="mt-6 ps-0">
                              {errorMessage?.password?.map(
                                (item: string, index: number) => (
                                  <li
                                    className="mb-6 font-medium-xs"
                                    key={index}
                                  >
                                    {item}
                                  </li>
                                )
                              )}
                            </ul>
                          ) : null
                        }
                        rules={[
                          {
                            required: true,
                            message: "This field should not be empty",
                          },
                          ({}) => ({
                            validator(_, value) {
                              setErrorMessage({});
                              if (
                                /[A-Z]/.test(value) &&
                                /[0-9]/.test(value) &&
                                value.length >= 8
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  "Password should contain at least 8 characters and a capital letter and number"
                                )
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          className={`${styles["border-radius"]} p-5`}
                          placeholder="Password"
                          iconRender={(visible) =>
                            visible ? (
                              <Icon
                                name="eye"
                                color="#BCBCBC"
                                width={24}
                                height={24}
                              />
                            ) : (
                              <Icon
                                name="eye"
                                color="#02254a"
                                width={24}
                                height={24}
                              />
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col className={`gutter-row`} span={24}>
                      <Form.Item
                        name="VerifyPassword"
                        rules={[
                          {
                            required: true,
                            message: "This field should not be empty",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  "The two passwords that you entered do not match!"
                                )
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          className={`${styles["border-radius"]} p-5`}
                          placeholder="Verify Password"
                          iconRender={(visible) =>
                            visible ? (
                              <Icon
                                name="eye"
                                color="#BCBCBC"
                                width={24}
                                height={24}
                              />
                            ) : (
                              <Icon
                                name="eye"
                                color="#02254a"
                                width={24}
                                height={24}
                              />
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col className={`gutter-row`} span={24}>
                      <Form.Item name="promo" valuePropName="checked">
                        <div className={`${styles["newsLetters-container"]}`}>
                          <span className=" me-4 ">
                            <CustomCheckbox
                              disabled={false}
                              checked={receiveNewsLetter}
                              onChange={() =>
                                setReceiveNewsLetter(!receiveNewsLetter)
                              }
                            />
                          </span>
                          <span
                            className={`${styles["news-letters-text"]} font-medium-base lh-lg `}
                          >
                            {getText().SignUp.ClickThisBoxToReceiveEPallet}
                          </span>
                        </div>
                      </Form.Item>
                    </Col>
                    <Col className={`mb-12`} span={24}>
                      <CustomButton
                        className={`${styles["height-50"]} w-100`}
                        htmlType="submit"
                        theme="primary"
                        disabled={false}
                        loading={buttonLoading}
                      >
                        <span className="font-bold-base lh-sm ">
                          {getText()?.SignUp.signUp}
                        </span>
                      </CustomButton>
                    </Col>
                    <Col className={""} span={24}>
                      <Divider type="horizontal" className="m-0">
                        OR
                      </Divider>
                    </Col>
                    <Col
                      className={windowWidth >= 992 ? "mb-34" : "mb-22"}
                      span={24}
                    >
                      <div
                        className={`${styles["google-icon-container"]}`}
                        onClick={() => signIn("google")}
                      >
                        <Image src={googleLogo} objectFit="contain" />
                      </div>
                    </Col>
                    <Col
                      className={`${styles["text-align"]} font-regular-sm lh-lg`}
                      span={24}
                    >
                      {getText()?.SignUp?.alreadyHaveAccount}
                      <Link href={"/?epallet_login=true"}>
                        <a>
                          <CustomButton
                            theme="link"
                            className={`${styles["heigh-23"]} ms-2`}
                          >
                            <span className="font-bold-base lh-md">
                              {windowWidth >= 992
                                ? getText()?.General?.SignIn
                                : getText()?.SignUp?.signIn}
                            </span>
                          </CustomButton>
                        </a>
                      </Link>
                    </Col>
                  </Row>
                </Form>
              ) : (
                <>
                  <Row className={styles.congratulationsSection}>
                    <span className={`${styles.imageContainer}`}>
                      <Icon
                        name="mail-read"
                        width={windowWidth > 992 ? 96 : 63}
                        height={windowWidth > 992 ? 96 : 59}
                        color="#076DDC"
                      />
                    </span>
                    <span className={`${styles.welcomeToEPallet} lh-sm `}>
                      {getText().SignUp.welcomeToEPallet}
                    </span>
                    <span className={`${styles.emailSent} lh-lg`}>
                      {getText().SignUp.emailHasBeenSent}
                      <span
                        className={`font-regular-base lh-lg text-primary ms-2`}
                      >
                        {userEmail}
                      </span>
                      {getText()?.SignUp?.validateYourEmailAddressAsCustomer}
                    </span>
                    <CustomButton
                      className={styles.resendEmail}
                      text="Resend Email"
                      theme="primary"
                      loading={buttonLoading}
                      disabled={false}
                      onClick={() => {
                        resendValidationEmail();
                      }}
                    />
                  </Row>
                </>
              )}
            </>
          )}
        </Col>
      </Row>
    </>
  );
};
export default SignUpPage;
