export const TIMEOUT_FOR_DISPLAYING_TOAST = 10000;
export const APP_URL = process.env.NEXT_PUBLIC_APP_URL;

export const erbConfiguration = {
  apiDomain: process.env.ERB_API_DOMAIN
};
export const fabricConfiguration = {
  cdnDomain: process.env.FABRIC_CDN_DOMAIN,
  apiDomain: process.env.FABRIC_API_DOMAIN,
  account: process.env.FABRIC_ACCOUNT,
  stage: process.env.FABRIC_STAGE,
  site: process.env.NEXT_PUBLIC_FABRIC_SITE,
  channel: process.env.FABRIC_CHANNEL,
  copilotAPI: process.env.FABRIC_API_COPILOT
};

export const EPALLETERP = {
  BASE_URL: process.env.ERP_BASE_API_URL
};
