import { Col, Form, Modal, Radio, Row, notification } from "antd";
import Field from "@components/shared/Fields";
import styles from "./styles.module.scss";
import {
  shippingHoursOption,
  getTruckSizesRestrictionsOptions,
} from "src/server/utils/commonUtils";
import Button from "~/src/components/shared/Button";
import DataLoader from "~/src/components/shared/DataLoader";
import { useEffect, useState, useContext } from "react";
import Select from "@components/shared/Select";
import { getCookie } from "src/server/utils/commonUtils";
import { useRouter } from "next/router";
import SearchLocationInput from "src/components/shared/AutocompleteAddress";
import Script from "next/script";
import { AppContext } from "@src/libs/context";
import { getText } from "~/src/libs/resources";
import CustomCheckbox from "@components/shared/Checkbox";
import { RuleObject } from "antd/es/form";
import LimitedAccessModal from "src/components/shared/AddressDrawer/LimitedAccessModal";
import Icon from "src/components/shared/Icon";
import { getMetroLimitedAccessOptions } from "src/components/shared/AddressDrawer/limitedAccessUtil";
import { phoneValidator } from "~/src/libs/utils/phoneValidator";
import useClient from "~/src/server/utils/client";

interface ManageAccountProps {
  updateQuery?: boolean;
}
const ManageAccount = ({ updateQuery }: ManageAccountProps) => {
  const { query } = useRouter();
  const router = useRouter();
  const update = query?.update;
  const [componentDisabled, setComponentDisabled] = useState<boolean>(
    update || updateQuery ? false : true
  );
  useEffect(() => {
    if (updateQuery) {
      router.push(
        {
          pathname: "/account/accountInfo",
          query: { update: "true" },
        },
        undefined,
        { shallow: true }
      );
    }
  }, []);
  const initialValues = {
    email: "",
    contact_name: "",
    company_name: "",
    title: "",
    fax: "",
    company_email: "",
    reseller_id_or_ein: "",
    is_reseller: true,
    address: "",
    post_zip: "",
    state: "",
    city: "",
    facility_contact_name: "",
    phone_ext: "",
    facility_contact_email: "",
    truck_size_restrictions: "",
    for_delivery_from: "",
    for_delivery_to: "",
    restrictions_height: "",
    restrictions_weight: "",
    checkboxGroup: ["A", "D", "E"],
    Manageyouremailpreferences: "",
  };
  const [initialValuesApi, setInitialValuesApi] = useState<any>(initialValues);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [isAddressFieldValid, setIsAddressFieldValid] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<any>("");
  const [showHeightWeightFields, setShowHeightWeightFields] =
    useState<boolean>(false);
  const [isLimitedAccessSelected, setLimitedAccessSelected] =
    useState<boolean>();
  const [disabledSave, setDisabledSave] = useState<boolean>(false);
  const [limitedAccessValues, setLimitedAccessValues] = useState<any>();
  const [isLimitedModalOpen, setIsLimitedModalOpen] = useState<boolean>(false);
  const [isOtherSelected, setIsOtherSelected] = useState<boolean>(false);
  const [showDeliveryModals, setShowDeliveryModals] = useState(false);
  const isNoDeliveryChecked =
    initialValuesApi?.shipping_address?.no_delivery_requirements;
  const [checkboxState, setCheckboxState] = useState({
    no_delivery_requirements: isNoDeliveryChecked,
    limited_access: isNoDeliveryChecked
      ? false
      : initialValuesApi?.shipping_address?.limited_access,
    appointment_required: isNoDeliveryChecked
      ? false
      : initialValuesApi?.shipping_address?.appointment_required,
    inside_delivery_required: isNoDeliveryChecked
      ? false
      : initialValuesApi?.shipping_address?.inside_delivery_required,
    lift_gate_required: isNoDeliveryChecked
      ? false
      : initialValuesApi?.shipping_address?.lift_gate_required,
    residential_delivery: isNoDeliveryChecked
      ? false
      : initialValuesApi?.shipping_address?.residential_delivery,
    disableOthers: isNoDeliveryChecked ? true : false,
  });
  const handleDeliveryCheckboxChange = (e?: any) => {
    setShowDeliveryModals(true);
  };
  const handleCheckboxChange = (value: any) => {
    if (value === "limited_access") {
      setLimitedAccessSelected(!isLimitedAccessSelected);
      setIsLimitedModalOpen(!isLimitedAccessSelected);
    }

    if (value === "no_delivery_requirements") {
      handleDeliveryCheckboxChange();
      return;
    }
    setCheckboxState((prevState: any) => ({
      ...prevState,
      [value]: !prevState[value],
      disableOthers: false,
    }));
  };

  const handleModalOk = () => {
    setShowDeliveryModals(false);

    setCheckboxState((prevState) => ({
      ...prevState,
      no_delivery_requirements: true,
      limited_access: false,
      appointment_required: false,
      inside_delivery_required: false,
      lift_gate_required: false,
      residential_delivery: false,
      disableOthers: true,
    }));
    form.validateFields(["checkboxGroup"]);
  };

  const handleModalCancel = () => {
    setShowDeliveryModals(false);

    setCheckboxState((prevState) => ({
      ...prevState,
      no_delivery_requirements: false,
      disableOthers: false,
    }));
    form.validateFields(["checkboxGroup"]);
  };
  const {
    googlePlacesScript,
    setGooglePlacesScript,
    customerStatus,
    commonStates,
    getShippingAddresses,
    getCustomerStatus,
  } = useContext<any>(AppContext);
  const client = useClient({});

  useEffect(() => {
    getCustomerData();
  }, []);
  useEffect(() => {
    errorMessage ? form?.validateFields() : null;
  }, [errorMessage]);
  const validateCheckboxGroup = async () => {
    const hasChecked = Object.values(checkboxState).some((val) => val);
    if (!hasChecked) {
      return Promise.reject(new Error("Please select at least one option."));
    }
    return Promise.resolve();
  };
  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabledSave(hasErrors);
  };
  const onFinish = async (values: any) => {
    handleFormChange();
    let data;
    const metroLimitedAccessOther = {
      metro_limited_access_other: isOtherSelected
        ? form?.getFieldValue("shipping_address")?.metro_limited_access_other
        : "",
    };
    values.shipping_address = {
      ...values.shipping_address,
      ...checkboxState,
      ...metroLimitedAccessOther,
    };
    try {
      setButtonLoader(true);

      data = await client.basic.post(
        "/api/ERP/customer/profile",
        {
          data: { ...initialValuesApi, ...values },
        },
        {
          headers: { sessionid: getCookie("sessionid") },
        }
      );
      notification.success({
        message: `Your profile has been successfully updated!`,
        duration: 4,
      });
      setComponentDisabled(true);
      getCustomerData();
      getShippingAddresses();
      getCustomerStatus();
      setButtonLoader(false);
    } catch (error: any) {
      setButtonLoader(false);
      if (error?.response?.data?.shipping_address)
        setErrorMessage(error?.response?.data?.shipping_address);
      else if (error?.response?.data) {
        notification.error({
          message: `Email preferences- : ${
            JSON?.parse(error?.response?.data)[0]?.detail
          }`,
          duration: 4,
        });
      } else
        notification.error({
          message: getText()?.Account?.messages?.canNotUpdateProfile,
          duration: 4,
        });
      setComponentDisabled(false);
    }
  };
  const onFinishFailed = (errorInfo: any) => {};
  const editForm = async () => {
    setComponentDisabled(false);
  };
  const getCustomerData = async () => {
    let data;
    setIsLoading(true);
    try {
      data = await client.basic.get("/api/ERP/customer/profile", {
        headers: { sessionid: getCookie("sessionid") },
      });
    } catch (error: any) {}
    const responseData = data?.data;
    const checkboxes = {
      checkboxGroup: [
        responseData?.shipping_address?.no_delivery_requirements
          ? "no_delivery_requirements"
          : "Z",
        responseData?.shipping_address?.residential_delivery
          ? "residential_delivery"
          : "Z",
        responseData?.shipping_address?.lift_gate_required
          ? "lift_gate_required"
          : "Z",
        responseData?.shipping_address?.inside_delivery_required
          ? "inside_delivery_required"
          : "Z",
        responseData?.shipping_address?.appointment_required
          ? "appointment_required"
          : "Z",
        responseData?.shipping_address?.limited_access ? "limited_access" : "Z",
      ],
    };
    setInitialValuesApi(responseData);
    form.setFieldsValue({
      ...responseData,
      ...checkboxes,
      shipping_address: {
        ...responseData?.shipping_address,
        truck_size_restrictions:
          responseData?.shipping_address?.truck_size_restrictions === ""
            ? undefined
            : responseData?.shipping_address?.truck_size_restrictions,
      },
    });
    setCheckboxState({
      no_delivery_requirements:
        responseData?.shipping_address?.no_delivery_requirements,
      limited_access: responseData?.shipping_address?.limited_access,
      appointment_required:
        responseData?.shipping_address?.appointment_required,
      inside_delivery_required:
        responseData?.shipping_address?.inside_delivery_required,
      lift_gate_required: responseData?.shipping_address?.lift_gate_required,
      residential_delivery:
        responseData?.shipping_address?.residential_delivery,
      disableOthers: responseData?.shipping_address?.no_delivery_requirements
        ? true
        : false,
    });
    setShowHeightWeightFields(
      responseData?.shipping_address?.truck_size_restrictions !== "None"
    );
    setLimitedAccessSelected(responseData?.shipping_address?.limited_access);
    setIsOtherSelected(
      responseData?.shipping_address?.metro_limited_access === "Other"
    );
    setIsLoading(false);
  };
  const cancelEdit = () => {
    form.resetFields();
    setComponentDisabled(true);
    getCustomerData();
  };
  const handleSelectLocation = (value: any) => {
    const shippingAddressData = form?.getFieldValue("shipping_address");
    const newAddress = {
      state: value?.state,
      city: value?.city,
      country: value?.country,
      post_zip: value?.zipCode,
      address: value?.formatted_address,
    };
    form?.setFieldValue("shipping_address", {
      ...shippingAddressData,
      ...newAddress,
    });
    form?.validateFields();
  };
  const locationValidation = (
    _: RuleObject,
    fieldName: string,
    value: string
  ) => {
    if (errorMessage) {
      let keys = Object?.keys(errorMessage);
      if (keys?.includes(fieldName)) {
        let message = errorMessage[fieldName];
        delete errorMessage[fieldName];
        setErrorMessage("");
        return Promise.reject(message || `Invalid ${fieldName}`);
      }
    } else if (!value && fieldName !== "city") {
      return Promise.reject("This field should not be empty");
    } else {
      return Promise.resolve();
    }
  };
  const generalValidator = (
    _: RuleObject,
    value: any,
    max: number,
    required?: boolean
  ) => {
    if (!value && required) {
      return Promise.reject("This field should not be empty");
    }
    if (!value && !required) {
      return Promise.resolve();
    }
    if (value.length > max) {
      return Promise.reject(`No more than ${max} characters.`);
    }
    return Promise.resolve();
  };
  const onRestrictionChange = (value: string) => {
    if (value === "None") {
      setShowHeightWeightFields(false);
      const heightWeight = {
        restrictions_height: "",
        restrictions_weight: "",
      };
      form?.setFieldValue("shipping_address", {
        ...form?.getFieldValue("shipping_address"),
        ...heightWeight,
      });
    } else setShowHeightWeightFields(true);
  };
  useEffect(() => {
    form?.setFieldValue("shipping_address", {
      ...form?.getFieldValue("shipping_address"),
      ...limitedAccessValues,
    });
  }, [limitedAccessValues]);

  const onMetroLimitedSelectChange = (value: string) => {
    setIsOtherSelected(value === "Other");
  };

  return (
    <>
      <Form
        className={`${styles.manageAccountStyle} ${
          componentDisabled ? styles.componentDisabled : ""
        }`}
        disabled={componentDisabled}
        initialValues={initialValuesApi}
        onFinish={onFinish}
        autoComplete="off"
        onFinishFailed={onFinishFailed}
        form={form}
      >
        {isLoading ? (
          <div className={styles.loaderContainer}>
            <DataLoader />
          </div>
        ) : null}
        <Row gutter={[16, 12]} className="mb-6">
          <Col xs={{ span: 24 }} md={{ span: 8 }}>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "This field should not be empty" },
              ]}
            >
              <Field
                labelUp={componentDisabled}
                disabled={true}
                placeholder="Email"
                label="Email"
                className="manageAccountField"
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 8 }}>
            <Form.Item
              name="contact_name"
              rules={[
                () => ({
                  validator: (_, value) =>
                    generalValidator(_, value, 255, true),
                }),
              ]}
            >
              <Field
                labelUp={componentDisabled}
                disabled={componentDisabled}
                placeholder="Contact Name"
                label="Contact Name"
                className="manageAccountField"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 12]} className="mb-6">
          <Col xs={{ span: 24 }} md={{ span: 8 }}>
            <Form.Item
              name="company_name"
              rules={[
                () => ({
                  validator: (_, value) =>
                    generalValidator(_, value, 255, true),
                }),
              ]}
            >
              <Field
                labelUp={componentDisabled}
                disabled={componentDisabled}
                placeholder="Company Name"
                label="Company Name"
                className="manageAccountField"
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 8 }}>
            <Form.Item
              name="title"
              rules={[
                () => ({
                  validator: (_, value) =>
                    generalValidator(_, value, 200, true),
                }),
              ]}
            >
              <Field
                labelUp={componentDisabled}
                disabled={componentDisabled}
                placeholder="Title"
                label="Title"
                className="manageAccountField"
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 8 }}>
            <Form.Item
              name="fax"
              rules={[
                () => ({
                  validator: (_, value) =>
                    generalValidator(_, value, 50, false),
                }),
              ]}
            >
              <Field
                labelUp={componentDisabled}
                disabled={componentDisabled}
                placeholder="Fax"
                label="Fax"
                className="manageAccountField"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 12]}>
          <Col xs={{ span: 24 }} md={{ span: 8 }}>
            <Form.Item
              name="company_email"
              rules={[
                {
                  type: "email",
                  required: false,
                  message: "Please input a valid email.",
                },
              ]}
            >
              <Field
                labelUp={componentDisabled}
                disabled={componentDisabled}
                placeholder="Company Email"
                label="Company Email"
                className="manageAccountField"
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 8 }}>
            <Form.Item
              name="reseller_id_or_ein"
              rules={[
                () => ({
                  validator: (_, value) =>
                    generalValidator(_, value, 12, false),
                }),
              ]}
            >
              <Field
                labelUp={componentDisabled}
                disabled={componentDisabled}
                placeholder="Reseller ID or EIN"
                label="Reseller ID or EIN"
                className="manageAccountField"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mt-20 mb-12" gutter={[0, 16]}>
          <Col span={24} className="font-bold-base text-oxfordBlue">
            {getText()?.Account?.attestMessage}
          </Col>
          <Col span={24}>
            <Form.Item name="is_reseller">
              <Radio.Group disabled={true}>
                <Radio value={true}> {getText()?.Account?.yesAnswer} </Radio>
                <Radio value={false}> {getText()?.Account?.noAnswer} </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Form.List name="shipping_address">
          {(fields) => (
            <>
              <Row gutter={[16, 12]}>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  {!googlePlacesScript && (
                    <Script
                      src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_PLEACES_KEY}&libraries=places`}
                      onLoad={() => {
                        setGooglePlacesScript(true);
                      }}
                    />
                  )}
                  {googlePlacesScript && (
                    <Form.Item
                      name="address"
                      rules={[
                        () => ({
                          validator: (_, value) => {
                            {
                              if (!value) {
                                setIsAddressFieldValid(false);
                                return Promise.reject(
                                  "This field should not be empty"
                                );
                              } else {
                                setIsAddressFieldValid(true);
                                return Promise.resolve();
                              }
                            }
                          },
                        }),
                      ]}
                    >
                      <SearchLocationInput
                        className={styles.field}
                        disabled={componentDisabled}
                        placeHolder="Address"
                        defaultValue={
                          initialValuesApi?.shipping_address?.address
                        }
                        extraAction={handleSelectLocation}
                        name="currentLocation"
                        types={["address"]}
                        isBilling={false}
                        validInput={isAddressFieldValid}
                      />
                    </Form.Item>
                  )}
                </Col>
                <Col xs={{ span: 12 }} md={{ span: 4 }}>
                  <Form.Item
                    name="post_zip"
                    rules={[
                      () => ({
                        validator: (_, value) =>
                          locationValidation(_, "post_zip", value),
                      }),
                    ]}
                  >
                    <Field
                      labelUp={componentDisabled}
                      disabled={componentDisabled}
                      placeholder="Zip Code"
                      label="Zip Code"
                      className="manageAccountField"
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 12 }} md={{ span: 4 }}>
                  <Form.Item
                    name="state"
                    rules={[
                      () => ({
                        validator: (_, value) =>
                          locationValidation(_, "state", value),
                      }),
                    ]}
                  >
                    {componentDisabled ? (
                      <Field
                        labelUp={componentDisabled}
                        disabled={componentDisabled}
                        placeholder="State"
                        label="State"
                        className="manageAccountField"
                      />
                    ) : (
                      <Select
                        placeholder="State"
                        showSearch={true}
                        options={commonStates}
                        className="manageAccountSelect"
                        filterOption={(input: string, option: any) =>
                          (option?.label ?? "")
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase())
                        }
                      ></Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <Form.Item
                    name="city"
                    rules={[
                      () => ({
                        validator: (_, value) =>
                          locationValidation(_, "city", value),
                      }),
                    ]}
                  >
                    <Field
                      labelUp={componentDisabled}
                      disabled={componentDisabled}
                      placeholder="City"
                      label="City"
                      className="manageAccountField"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 12]} className="mt-6">
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <Form.Item
                    name="phone_number"
                    rules={[
                      () => ({
                        validator: (_, value) =>
                          phoneValidator(_, value, "phone_number"),
                      }),
                    ]}
                  >
                    <Field
                      labelUp={componentDisabled}
                      disabled={componentDisabled}
                      placeholder="Facility phone number"
                      label="Facility phone number"
                      className="manageAccountField"
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <Form.Item
                    name="phone_ext"
                    rules={[
                      () => ({
                        validator: (_, value) =>
                          generalValidator(_, value, 6, false),
                      }),
                    ]}
                  >
                    <Field
                      labelUp={componentDisabled}
                      disabled={componentDisabled}
                      placeholder="Phone EXT"
                      label="Phone EXT"
                      className="manageAccountField"
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <Form.Item
                    name="facility_contact_email"
                    rules={[
                      {
                        required: true,
                        message: "This field should not be empty.",
                      },
                      { type: "email", message: "Please input a valid email." },
                    ]}
                  >
                    <Field
                      labelUp={componentDisabled}
                      disabled={componentDisabled}
                      placeholder="Facility email"
                      label="Facility email"
                      className="manageAccountField"
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <Form.Item
                    name="truck_size_restrictions"
                    rules={[
                      {
                        required: true,
                        message: "This field should not be empty",
                      },
                    ]}
                  >
                    {componentDisabled ? (
                      <Field
                        labelUp={componentDisabled}
                        disabled={componentDisabled}
                        placeholder="Truck size restrictions"
                        label="Truck size restrictions"
                        className="manageAccountField"
                      />
                    ) : (
                      <Select
                        placeholder="Truck size restrictions"
                        options={getTruckSizesRestrictionsOptions()}
                        className="manageAccountSelect"
                        onChange={onRestrictionChange}
                      ></Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <Form.Item name="metro_limited_access">
                    {componentDisabled ? (
                      <Field
                        labelUp={componentDisabled}
                        disabled={componentDisabled}
                        placeholder="Please select Metro Area (Optional)"
                        label="Metro Limited Access/High-Cost Metro"
                        className="manageAccountField"
                      />
                    ) : (
                      <Select
                        options={getMetroLimitedAccessOptions()}
                        className="manageAccountSelect"
                        placeholder="Please select Metro Area (Optional)"
                        getPopupContainer={(trigger: any) =>
                          trigger?.parentElement
                        }
                        onChange={onMetroLimitedSelectChange}
                      />
                    )}
                  </Form.Item>
                </Col>
                {isOtherSelected && (
                  <Col xs={{ span: 24 }} md={{ span: 8 }}>
                    <Form.Item
                      name="metro_limited_access_other"
                      rules={[
                        { max: 250, message: "No more than 250 characters." },
                        {
                          required: true,
                          message: "This field should not be empty.",
                        },
                      ]}
                    >
                      <Field
                        className="manageAccountField"
                        placeholder="Other"
                        label="Other"
                        labelUp={componentDisabled}
                        disabled={componentDisabled}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
              <Row gutter={[16, 12]} className="mt-20 mb-12">
                <Col span={24} className="font-bold-base text-oxfordBlue pb-8">
                  {getText()?.Account?.shippingHoursQuestion}
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <Form.Item name="for_delivery_from">
                    {componentDisabled ? (
                      <Field
                        labelUp={componentDisabled}
                        disabled={componentDisabled}
                        placeholder="From"
                        label="From"
                        id="for_delivery_from"
                      />
                    ) : (
                      <Select
                        placeholder="From"
                        options={shippingHoursOption}
                        className="manageAccountSelect"
                      ></Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <Form.Item name="for_delivery_to">
                    {componentDisabled ? (
                      <Field
                        labelUp={componentDisabled}
                        disabled={componentDisabled}
                        placeholder="To"
                        label="To"
                        id="for_delivery_to"
                      />
                    ) : (
                      <Select
                        placeholder="To"
                        options={shippingHoursOption}
                        className="manageAccountSelect"
                      ></Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              {showHeightWeightFields && (
                <Row gutter={[16, 12]}>
                  <Col xs={{ span: 24 }} md={{ span: 8 }}>
                    <Form.Item
                      name="restrictions_height"
                      rules={[
                        () => ({
                          validator: (_, value) =>
                            generalValidator(_, value, 200, false),
                        }),
                      ]}
                    >
                      <Field
                        labelUp={componentDisabled}
                        disabled={componentDisabled}
                        placeholder="Facility height restriction"
                        label="Facility height restriction"
                        className="manageAccountField"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 8 }}>
                    <Form.Item
                      name="restrictions_weight"
                      rules={[
                        () => ({
                          validator: (_, value) =>
                            generalValidator(_, value, 200, false),
                        }),
                      ]}
                    >
                      <Field
                        labelUp={componentDisabled}
                        disabled={componentDisabled}
                        placeholder="Road Facility weight restriction"
                        label="Road Facility weight restriction"
                        className="manageAccountField"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </>
          )}
        </Form.List>
        <Modal
          open={showDeliveryModals}
          onCancel={handleModalCancel}
          onOk={handleModalOk}
          okText="Complete now"
          cancelText="Cancel"
          centered
          closable={false}
          maskClosable={false}
        >
          <div className="text-center">
            <p className="font-semibold-base">
              {getText()?.Account?.AddressForm?.deliveryModalTitle}
            </p>
            <p>{getText()?.Account?.AddressForm?.deliveryModalContent}</p>
          </div>
        </Modal>
        <div className="d-flex">
          <Form.Item
            name="checkboxGroup"
            className="pt-12 pb-20"
            rules={[
              {
                validator: validateCheckboxGroup,
              },
            ]}
          >
            <Row className={styles.checkboxGroup}>
              {((initialValuesApi?.shipping_address?.no_delivery_requirements &&
                componentDisabled) ||
                !componentDisabled) && (
                <CustomCheckbox
                  value="no_delivery_requirements"
                  label={
                    getText()?.Account?.AddressForm?.noDeliveryRequirements
                  }
                  disabled={componentDisabled}
                  hasInfoIcon={true}
                  checked={checkboxState.no_delivery_requirements}
                  onChange={() =>
                    handleCheckboxChange("no_delivery_requirements")
                  }
                />
              )}
              {((initialValuesApi?.shipping_address?.residential_delivery &&
                componentDisabled) ||
                !componentDisabled) && (
                <CustomCheckbox
                  value="residential_delivery"
                  label={getText()?.Account?.residentialDeliveryRequired}
                  disabled={componentDisabled || checkboxState?.disableOthers}
                  hasInfoIcon={true}
                  checked={checkboxState.residential_delivery}
                  onChange={() => handleCheckboxChange("residential_delivery")}
                />
              )}
              {((initialValuesApi?.shipping_address?.lift_gate_required &&
                componentDisabled) ||
                !componentDisabled) && (
                <CustomCheckbox
                  value="lift_gate_required"
                  label={getText()?.Account?.liftGateRequirements}
                  disabled={componentDisabled || checkboxState?.disableOthers}
                  hasInfoIcon={true}
                  checked={checkboxState.lift_gate_required}
                  onChange={() => handleCheckboxChange("lift_gate_required")}
                />
              )}
              {((initialValuesApi?.shipping_address?.inside_delivery_required &&
                componentDisabled) ||
                !componentDisabled) && (
                <CustomCheckbox
                  value="inside_delivery_required"
                  label={getText()?.Account?.insideDeliveryRequired}
                  disabled={componentDisabled || checkboxState?.disableOthers}
                  hasInfoIcon={true}
                  checked={checkboxState.inside_delivery_required}
                  onChange={() =>
                    handleCheckboxChange("inside_delivery_required")
                  }
                />
              )}
              {((initialValuesApi?.shipping_address?.appointment_required &&
                componentDisabled) ||
                !componentDisabled) && (
                <CustomCheckbox
                  value="appointment_required"
                  label={getText()?.Account?.deliveryAppointmentRequired}
                  disabled={componentDisabled || checkboxState?.disableOthers}
                  hasInfoIcon={true}
                  checked={checkboxState.appointment_required}
                  onChange={() => handleCheckboxChange("appointment_required")}
                />
              )}
              {((initialValuesApi?.shipping_address?.limited_access &&
                componentDisabled) ||
                !componentDisabled) && (
                <CustomCheckbox
                  value="limited_access"
                  label={getText()?.Account?.limitedAccess}
                  disabled={componentDisabled || checkboxState?.disableOthers}
                  hasInfoIcon={true}
                  checked={checkboxState.limited_access}
                  onChange={() => handleCheckboxChange("limited_access")}
                />
              )}
              {(isLimitedAccessSelected && !componentDisabled && !checkboxState?.disableOthers) && (
                <Button
                  hasIcon={true}
                  className={styles?.showMoreIcon}
                  theme="link"
                  onClick={() => setIsLimitedModalOpen(true)}
                  icon={<Icon classesName={styles?.icon} name="arrow" />}
                  text={getText()?.Account?.AddressForm?.selectLimitedAccess}
                />
              )}
            </Row>
          </Form.Item>
        </div>
        {!componentDisabled && (
          <Row className={styles.btnForm}>
            <Form.Item>
              <Button theme={"secondary"} onClick={cancelEdit}>
                {getText()?.Account?.cancelButton}
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                theme="primary"
                htmlType="submit"
                className="login-form-button"
                loading={buttonLoader}
                disabled={disabledSave}
              >
                {getText()?.Account?.saveButton}
              </Button>
            </Form.Item>
          </Row>
        )}
        <LimitedAccessModal
          isLimitedModalOpen={isLimitedModalOpen}
          setIsLimitedModalOpen={setIsLimitedModalOpen}
          setLimitedAccessValues={setLimitedAccessValues}
          initialAddress={initialValuesApi?.shipping_address}
          isLimitedAccessSelected={isLimitedAccessSelected}
          limitedAccessValues={limitedAccessValues}
        />
      </Form>
      {componentDisabled && (
        <Button
          theme={"primary"}
          onClick={editForm}
          className={styles.editBtn}
          disabled={!customerStatus?.profile_completed}
        >
          Edit
        </Button>
      )}
    </>
  );
};
export default ManageAccount;
