import { InputNumber, Button } from "antd";
import Icon from "../Icon";
import Styles from "./styles.module.scss";
import { QuantityInputProps } from "./types";
import { getText } from "~/src/libs/resources";

const QuantityInput = ({
  min,
  max,
  value,
  onPlusClick,
  onMinusClick,
  onChange,
  isDisabled,
  enterKeyHandler,
  isPlusDisabled,
  isInputDisabled = false // <-- Default value set to false
}: QuantityInputProps) => {
  return (
    <div onBlur={enterKeyHandler}>
      <InputNumber
        onPressEnter={enterKeyHandler}
        controls={false}
        min={min}
        max={max}
        type="number"
        aria-label={getText()?.General?.enterQTY}
        onKeyDown={(event: any) => {
          if (event.key === "." || event.key === "+" ||event.key === "-") {
            event.preventDefault();
          }
        }}
        onKeyUp={(event: any) => {
          if (event.key === "." || event.key === "+" ||event.key === "-") {
            event.preventDefault();
          }
        }}
        onChange={onChange}
        value={Number(value)}
        className={`${Styles.quantityInput} bg-secondaryLighter font-bold-h6`}
        addonAfter={
          <Button
            icon={<Icon name="PlusIcon" width={12} height={12} />}
            className={`${Styles.plusButton}`}
            onClick={onPlusClick}
            disabled={value === max || isPlusDisabled}
            onMouseDown={(e) => e.preventDefault()}
            aria-label={getText()?.General?.increaseQTY}
          />
        }
        addonBefore={
          <Button
            icon={<Icon name="MinusIcon" width={12} height={2} />}
            className={`${Styles.minusButton}`}
            onClick={onMinusClick}
            disabled={isDisabled}
            onMouseDown={(e) => e.preventDefault()}
            aria-label={getText()?.General?.decreaseQTY}
          />
        }
       readOnly={isInputDisabled} // <-- Condition based on the prop value
      />
    </div>
  );
};

export default QuantityInput;
