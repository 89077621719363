import Icon from "@components/shared/Icon";
import CustomButton from "@components/shared/Button";
import styles from "./styles.module.scss";
import RequestForm from "@components/shared/requestForm";
import { useState } from "react";
import { getText } from "~/src/libs/resources";
const NoResult = ({ iconSize, isSearchPage, closeMenu }: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  return (
    <>
      {isSearchPage && (
        <button
          onClick={closeMenu}
          className={`${styles.closeMenu} w-100 px-1 pt-8`}
        >
          <Icon name="cross" />
        </button>
      )}
      <div className={`${styles.noResult} pb-20 p-12`}>
        <div className={`${styles.iconWrapper}`}>
          <Icon name="group" width={iconSize} height={iconSize} />
        </div>
        <h4 className="font-bolder-h4 mb-4 mt-8">
          {getText()?.Search?.canNotFindAProduct}
        </h4>
        {isSearchPage && (
          <p className="font-regular-base">
            {getText()?.Search?.tryCheckingYourSpelling}
          </p>
        )}
        <CustomButton theme="primary" onClick={showModal}>
          {getText()?.Search?.requestIt}
        </CustomButton>
          <RequestForm
            setIsModalOpen={setIsModalOpen}
            isModalOpen={isModalOpen}
          />
      </div>
    </>
  );
};

export default NoResult;
