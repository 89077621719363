import { Form, Modal, Row, Checkbox, Col } from "antd";
import { useEffect } from "react";
import CustomCheckbox from "@components/shared/Checkbox";
import styles from "./styles.module.scss";
import { getText } from "~/src/libs/resources";
import { LimitedAddressModalProps } from "./types";

const LimitedAccessModal = ({
  setIsLimitedModalOpen,
  isLimitedModalOpen,
  setLimitedAccessValues,
  initialAddress,
  isLimitedAccessSelected,
  limitedAccessValues,
}: LimitedAddressModalProps) => {
  const [form] = Form.useForm();
  const checkBoxes: any = getText()?.Account?.AddressForm?.limitedAccessModal;
  const keys = Object?.keys(checkBoxes);
  useEffect(() => {
    if (initialAddress) {
      const addressKeys = Object?.keys(initialAddress);
      let key: any;
      var initialValues: any = [];
      for (key of addressKeys) {
        if (initialAddress[key] === true && keys?.includes(key)) {
          initialValues.push(key);
        }
      }

      form?.setFieldValue("checkboxGroup", initialValues);
    }
  }, [initialAddress]);
  useEffect(() => {
    if (isLimitedAccessSelected ===false) onFinish({}, false);
  }, [isLimitedAccessSelected]);

  const onFinish = (values: any, isLimitedAccessSelected?: boolean) => {
    let selectedValues: any = {};
    if (isLimitedAccessSelected) {
      for (let element of keys) {
        if (values?.checkboxGroup?.includes(element)) {
          selectedValues[element] = true;
        } else {
          selectedValues[element] = false;
        }
      }
    } else {
      for (let element of keys) {
        selectedValues[element] = false;
      }
    }
    setLimitedAccessValues(selectedValues);
  };
  const onCancel = () => {
    let key: any;
    let initialValues: any = [];
    if (limitedAccessValues) {
      const addressKeys = Object?.keys(limitedAccessValues);
      for (key of addressKeys) {
        if (limitedAccessValues[key] === true) {
          initialValues.push(key);
        }
      }
    } else if (initialAddress) {
      const addressKeys = Object?.keys(initialAddress);
      for (key of addressKeys) {
        if (initialAddress[key] === true && keys?.includes(key)) {
          initialValues.push(key);
        }
      }
    }
    form?.setFieldValue("checkboxGroup", initialValues);
    setIsLimitedModalOpen(false);
  };

  return (
    <Modal
      title="Please select all that apply"
      open={isLimitedModalOpen}
      width={800}
      okText="Save"
      onOk={() => {
        onFinish(form?.getFieldsValue(), true);
        setIsLimitedModalOpen(false);
      }}
      onCancel={onCancel}
    >
      <Form form={form} onFinish={onFinish} className="pt-20">
        <Form.Item name="checkboxGroup">
          <Checkbox.Group>
            <Row className={styles?.checkboxGroup}>
              {keys?.map((item: any) => (
                <Col span={12} key={item}>
                  <CustomCheckbox label={checkBoxes[item]} value={item} />
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default LimitedAccessModal;
