import { Col, Form, Modal, Row, Checkbox } from "antd";
import CustomButton from "~/src/components/shared/Button";
import Field from "~/src/components/shared/Fields";
import Icon from "~/src/components/shared/Icon";
import { getText } from "~/src/libs/resources";
import styles from "./styles.module.scss";
import useHaggle from "~/src/utilities/hooks/useHaggle";
import { useState } from "react";
interface JoinHaggleProps {
  isOpen: boolean;
  setOpenModal: any;
}
const JoinHaggle = ({ isOpen, setOpenModal }: JoinHaggleProps) => {
  const [isChecked, setIsChecked] = useState(false);
  const { joinHaggleHandler } = useHaggle();
  const [form] = Form.useForm();
  const checkboxValidator = (_: any, value: any) => {
    if (!value) {
      return Promise.reject(
        "Please agree to our terms and conditions to join haggle."
      );
    }
    return Promise.resolve();
  };
  return (
    <Modal
      footer={null}
      open={isOpen}
      onCancel={() => setOpenModal(false)}
      className={`${styles.joinHaggleWrapper} text-oxfordBlue`}
    >
      <Icon
        name="haggleRewards"
        classesName="pe-4 mt-15"
        width={34}
        height={40}
      />
      <h3 className="font-bold-h3 mb-9">{getText()?.Haggle?.joinHaggle}</h3>
      <p className="font-regular-sm">
        {getText()?.Haggle?.createAccount}
        <span className="font-bold-sm">—{getText()?.Haggle?.forFree}</span>
      </p>
      <Form
        className="pt-8"
        onFinish={(values) => {
          joinHaggleHandler(values, setOpenModal);
        }}
        autoComplete="off"
        form={form}
      >
        <Row gutter={[16, 12]}>
          <Col xs={{ span: 24 }}>
            <Form.Item
              className={styles.email}
              name="email"
              rules={[
                { required: true, message: "This field should not be empty" },
              ]}
            >
              <Field placeholder="Email" label="Email" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="agreeToTerms"
          rules={[{ validator: (_)=>checkboxValidator(_,isChecked) }]}
        >
          <Checkbox
            className="lh-md"
            checked={isChecked}
            onClick={() => setIsChecked(!isChecked)}
          >
            <span className="font-regular-xs">
              {getText()?.Haggle?.haggleSign}
              <CustomButton
                href="/haggle"
                theme="link"
                className={`${styles.haggleLink} font-bold-xs mx-2`}
              >
                {getText()?.Haggle?.currentTerms}
              </CustomButton>
              {getText()?.Haggle?.receiveHaggle}
            </span>
          </Checkbox>
        </Form.Item>
        <Row>
          <Col className={`gutter-row`} xs={{ span: 24 }}>
            <CustomButton
              className="w-100 py-5 h-auto my-1"
              htmlType="submit"
              theme="primary"
            >
              <span className="font-bold-base">
                {getText()?.General?.SignIn}
              </span>
            </CustomButton>
          </Col>
        </Row>
        <p className="font-regular-sm mb-15 mt-12">
          {getText()?.Haggle?.explore}
          <CustomButton
            href="/haggle"
            theme="link"
            className={`${styles.haggleLink} font-bold-xs ms-2`}
          >
            {getText()?.Account?.learnMore}
          </CustomButton>
        </p>
      </Form>
    </Modal>
  );
};
export default JoinHaggle;
