import { useState, useContext, useEffect, useCallback } from "react";
import { notification } from "antd";
import { AppContext } from "~/src/libs/context";
import { promoHandler } from "~/src/libs/utils/promoHandler";
import { useRouter } from "next/router";
import { getCookie } from "src/server/utils/commonUtils";
import useCompareProducts from "~/src/utilities/hooks/useCompareProducts";
import { getText } from "~/src/libs/resources";
import { AppliedFilter,ProductProps } from "../types";
import {
  useSetProductQuantity,
  useAddItemToPallet,
  useBuildMixedPallet,
  useChangeName,
  useChangePalletCustomerName,
  useGetMixedPalletProducts,
  useGetPalletById,
  useSetPalletProductQuantity,
  usePalletById,
} from "~/src/services/pallet/hooks";
import { isNil } from "lodash";
import {
  AddItemToCartPayload,
  AddItemToPalletPayload,
} from "~/src/types/pallet";
import { useDispatch } from "react-redux";
import {
  setIsLoaded,
  setLoading,
  setPalletItems,
  setPalletLoadedProgress,
  setPalletName,
  setVendorProducts,
} from "~/src/libs/utils/mixedPallet";
import Selector from "~/src/modules/MixedPallet/selector";
import { useTypedSelector } from "~/src/libs/utils/useTypedSelector";
import { MixedPalletContext } from "~/src/libs/context/mixedPallet";
import { setProductListInfo } from "~/src/libs/utils/products";
import useClient from "~/src/server/utils/client";
export const useCardLogic = ({
  customerStatus,
  isGuestUser,
  id,
  titleOfList,
  product,
}: ProductProps) => {
  const router = useRouter();
  const [favClick, setFavClick] = useState(false);
  const dispatch = useDispatch();
  const { sortByVal, searchVal, setPalletId, palletId } =
    useContext<any>(MixedPalletContext);
  const {
    palletName,
    loading,
    vendorId,
    vendorName,
    offset,
    appliedPalletFilters
  } = useTypedSelector(Selector.getMixedPallet);

  const [showShoppingListDialog, setShowToShoppingListDialog] =
    useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [itemShoppingLists, setItemShoppingLists] = useState<any>();
  const [inCart, setInCart] = useState<any>(false);
  const {
    customerStatus: contextCustomerStatus,
    guestUser: contextGuestUser,
    defaultPostZip,
    cart,getCartCount
  } = useContext<any>(AppContext);
  const { compareProductHandler } = useCompareProducts();
  const [addToCartStatus, setAddToCartStatus] = useState(false);
  const [isWishlist, setIsWishlist] = useState<boolean>(false || product?.is_wished);
  const [validCustomerStatus, setValidCustomerStatus] = useState<any>();
  const [validGuestUser, setValidGuestUser] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const client = useClient({});
  const {
    apiCall: addItemToPalletApi,
    response: addItemToPalletResponse,
    error: addItemToPalletError,
    isLoading: addItemToPalletLoading,
  } = useAddItemToPallet();

  const {
    apiCall: changePalletName,
    response: changePalletNameResponse,
    error: changePalletNameError,
    isLoading: changePalletNameLoading,
  } = useChangePalletCustomerName();

  const { apiCall: changeNameApi, response: changeNameResponse } =
    useChangeName();

  const {
    apiCall: getMixedPalletProducts,
    response: getMixedPalletProductsResponse,
    error: getMixedPalletProductsError,
    isLoading: getMixedPalletProductsLoading,
  } = useGetMixedPalletProducts();

  const {
    apiCall: setPalletProductQuantity,
    response: setPalletProductQuantityResponse,
    error: setPalletProductQuantityError,
    isLoading: setPalletProductQuantityLoading,
  } = useSetPalletProductQuantity();

  const {
    apiCall: palletById,
    response: palletByIdResponse,
    error: palletByIdError,
    isLoading: palletByIdLoading,
  } = usePalletById();

  const {
    apiCall: getPalletById,
    response: getPalletByIdResponse,
    error: getPalletByIdError,
    isLoading: getPalletByIdLoading,
  } = useGetPalletById();

  const {
    apiCall: buildMixedPalletApi,
    response: buildMixedPalletResponse,
    error: buildMixedPalletError,
    isLoading: buildMixedPalletLoading,
  } = useBuildMixedPallet();

  const {
    apiCall: setQuantityApi,
    response: setQuantityResponse,
    error: setQuantityError,
    isLoading: addItemToCartLoading,
  } = useSetProductQuantity();

  useEffect(() => {
    const isLoading =
      addItemToPalletLoading ||
      changePalletNameLoading ||
      addItemToCartLoading ||
      buildMixedPalletLoading ||
      getMixedPalletProductsLoading ||
      getPalletByIdLoading ||
      setPalletProductQuantityLoading ||
      false;

    if (!setLoading || isLoading == loading) return;
    dispatch(setLoading(isLoading));
  }, [
    addItemToPalletLoading,
    changePalletNameLoading,
    getMixedPalletProductsLoading,
    setPalletProductQuantityLoading,
    getPalletByIdLoading,
    addItemToCartLoading,
    buildMixedPalletLoading,
  ]);

  const showModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  useEffect(() => {
    if (!customerStatus && customerStatus === undefined) {
      setValidCustomerStatus(contextCustomerStatus);
    } else {
      setValidCustomerStatus(customerStatus);
    }
  }, [customerStatus]);

  useEffect(() => {
    if (!isGuestUser && isGuestUser === undefined) {
      setValidGuestUser(contextGuestUser);
    } else {
      setValidGuestUser(isGuestUser);
    }
  }, [isGuestUser]);

  const isCustomerDataCompleted =
    !validGuestUser &&
    validCustomerStatus?.profile_completed === true &&
    validCustomerStatus?.email_verified === true;


  useEffect(() => {
    if (!getMixedPalletProductsResponse?.data) return;
    const { results, filters } = getMixedPalletProductsResponse.data;
    dispatch(setVendorProducts(results));
    notification.success({
      message: getText().PDP.messages.productAddedToPallet,
      duration: 3,
    });
  }, [getMixedPalletProductsResponse]);

  const getMixedPallet = useCallback(
    (initialParams = {}) => {
      const params = { ...initialParams } as Record<string, any>;
      if (defaultPostZip) params.post_zip = defaultPostZip;
      const searchQuery = router.query.search;
      if (searchVal && searchQuery) params.search = searchVal;
      params.limit = offset + 6;
      params.offset = offset > 0 ? 0 : offset;
      if (appliedPalletFilters && appliedPalletFilters.length > 0) {
        const filters = [] as any;
        appliedPalletFilters?.forEach((item: any) => {
          const filter = filters.find(
            ({ name }: any) => name === item?.filter?.name
          ) ?? {
            name: item?.filter?.name,
            ids: [],
          };
          filter.ids.push(item?.filter?.id);
        });

        delete router.query?.category;
        delete router.query?.pack;

        filters.forEach(({ ids, name }: AppliedFilter) => {
          params[name] = ids?.toString();
          router.query[name] = ids;
        });

        router.push(router, undefined, { shallow: true });
      }
      getMixedPalletProducts({ params, client });
    },
    [appliedPalletFilters, defaultPostZip, offset, searchVal, router]
  );

  useEffect(() => {
    if (!changePalletNameResponse?.data) return;
    dispatch(setPalletName(changePalletNameResponse.data.name));
  }, [changePalletNameResponse]);

  useEffect(() => {
    if (!palletByIdResponse?.data) return;
    dispatch(setPalletName(palletByIdResponse.data.name));
    const initialParams = {
      status: true,
      limit: 6,
      vendor: vendorId,
      ordering: sortByVal,
      pallet: id,
    };
    getMixedPallet(initialParams);
  }, [palletByIdResponse]);
  useEffect(() => {
    if (!changeNameResponse?.data) return;
    const { data } = buildMixedPalletResponse ?? { data: {} };
    const { products, id } = data;
    router.push(
      `/mixed-pallet-vendor?ordering=delivered_case_price&pallet=${id}&vendor=${products?.[0]?.product?.vendor_id}`
    );
  }, [changeNameResponse]);

  useEffect(() => {
    if (!setPalletProductQuantityResponse) return;
    const params = {
      ...(defaultPostZip ? { post_zip: defaultPostZip } : {}),
    };
    getPalletById({ palletId, params, client });
  }, [setPalletProductQuantityResponse]);

  useEffect(() => {
    if (!palletByIdError) return;
    setLoading(false);
  }, [palletByIdError]);

  useEffect(() => {
    if (isNil(addItemToPalletResponse?.data)) return;

    const { data } = addItemToPalletResponse ?? { data: {} };
    const { id, products, pallet_load_percents, is_loaded } = data;
    dispatch(setPalletItems(products));
    dispatch(setPalletLoadedProgress(pallet_load_percents));
    setPalletId(`${id}`);
    dispatch(setIsLoaded(is_loaded));
    router.query.pallet = id;
    router.push(router, undefined, { shallow: true });

    if (!palletName) {
      changePalletName({
        id,
        payload: {
          data: {
            name: `${vendorName} Mixed Pallet`,
          },
          headers: { sessionId: getCookie("sessionid") },
        },
        client,
      });
    }
    const params = {
      ...(defaultPostZip ? { post_zip: defaultPostZip } : {}),
    };
    palletById({ palletId: id, params, client });
  }, [addItemToPalletResponse]);

  useEffect(() => {
    if (!getPalletByIdResponse?.data) return;
    const { products, pallet_load_percents, is_loaded } =
      getPalletByIdResponse.data;
    dispatch(setPalletItems(products));
    dispatch(setPalletLoadedProgress(pallet_load_percents));
    dispatch(setIsLoaded(is_loaded));
    const initialParams = {
      status: true,
      limit: 6,
      vendor: vendorId,
      ordering: sortByVal,
      pallet: palletId,
    };
    getMixedPallet(initialParams);
  }, [getPalletByIdResponse]);

  useEffect(() => {
    if (isNil(buildMixedPalletResponse?.data)) return;
    const { data } = buildMixedPalletResponse ?? { data: {} };
    const { id } = data;
    router.query.pallet = id;
    notification.success({
      message: getText().PDP.messages.productAddedToPallet,
      duration: 8,
    });
    changeNameApi({
      id,
      payload: {
        data: {
          name: `${vendorName} Mixed Pallet`,
        },
        headers: { sessionId: getCookie("sessionid") },
      },
      client,
    });
  }, [buildMixedPalletResponse]);

  useEffect(() => {
    if (isNil(buildMixedPalletError)) return;
    console.error(buildMixedPalletError);
  }, [buildMixedPalletError]);

  useEffect(() => {
    if (!setQuantityResponse) return;
    const { data } = setQuantityResponse;
    setAddToCartStatus(false);
    setInCart(true);
    addToCartHandler(product, "add_to_cart");
    notification.success({
      message: getText().PDP.messages.ProductAddedSuccessfully,
      duration: 2,
    });
    const params = defaultPostZip ? { post_zip: defaultPostZip } : {};
    getCartCount()
    localStorage.setItem("CartID", data?.cartId);
  }, [setQuantityResponse]);

  useEffect(() => {
    if (isNil(setQuantityError)) return;
    setAddToCartStatus(false);
    notification.error({
      message: getText()?.General?.Messages?.SomethingWrongTryAgain,
      duration: 2,
    });
    console.error(setQuantityError);
  }, [setQuantityError]);

  const addItemToPallet = useCallback(() => {
    if (isNil(palletId)) {
      const payload = {
        data: { product: product?.id, case_quantity: null },
        headers: { sessionId: getCookie("sessionid") },
      } as AddItemToPalletPayload;
      const params = defaultPostZip ? { post_zip: defaultPostZip } : {};
      addItemToPalletApi({ payload, params, client });
    } else {
      const payload = {
        data: { product: product?.id, case_quantity: null },
      } as AddItemToPalletPayload;
      setPalletProductQuantity({ palletId, payload, client });
    }
  }, [palletId, product?.id]);

  const buildMixedPallet = useCallback(() => {
    const payload = {
      data: { product: product?.id, case_quantity: null },
      headers: { sessionId: getCookie("sessionid") },
    } as AddItemToPalletPayload;
    const params = defaultPostZip ? { post_zip: defaultPostZip } : {};
    buildMixedPalletApi({ payload, params, client });
  }, [product?.id]);

  const addToCartHandler = useCallback(
    (item: any, event: string) => {
      window?.dataLayer?.push({ ecommerce: null });
      window?.dataLayer?.push({
        event: event,
        ecommerce: {
          currency: "USD",
          value:
            !validGuestUser &&
            validCustomerStatus?.profile_completed &&
            validCustomerStatus?.email_verified &&
            ( item?.delivered_price),
          items: [
            {
              item_id:  item?.id?.toString(),
              item_name:  item?.name,
              coupon: "",
              currency: "USD",
              discount: 0,
              index: id,
              item_brand:  item?.brand_name || item?.brand?.name,
              item_category:item?.main_category  || item?.main_category_name,
              item_category2: item?.sub_category || item?.sub_category_name,
              item_list_id: "",
              item_list_name:
                titleOfList ||
               item?.sub_category || item?.sub_category_name,
              item_variant:item?.pack_size,
              price:
                !validGuestUser &&
                validCustomerStatus?.profile_completed &&
                validCustomerStatus?.email_verified &&
                 item?.delivered_price,
              quantity: 1,
            },
          ],
        },
      });
    },
    [product]
  );
  const addItemToCart = useCallback(
    (item: any) => {
      setAddToCartStatus(true);
      const payload = {
        data: {
          items: [
            {
              product: item?.slug,
              quantity: item?.sold_in_stack
                ? 2
                :  item?.min_pallet_quantity || 1,
            },
          ],
        },
        headers: { sessionId: getCookie("sessionid") },
      } as AddItemToCartPayload;
      setQuantityApi({ payload, client });
    },
    [product]
  );

  const viewDetailsHandler = useCallback(() => {
    dispatch(setProductListInfo({ id: id }));
    router.push(`/product/${product?.slug}`);
  }, [id]);

  const doWishlistAction = useCallback(
    (item: any) => {
      setShowToShoppingListDialog(true);
      setSelectedItem(item);
    },
    [product]
  );

  useEffect(() => {
    if (cart) {
      const cartPallets = cart?.pallets || [];
      cartPallets?.forEach((pallet: any) => {
        const palletItems = pallet?.pallet_items;
        palletItems?.forEach((cartItem: any) => {
          if (cartItem?.product?.id == (product?.id)) {
            setInCart(true);
          }
        });
      });
    }
  }, [cart]);

  const [itemHasPromo, setItemHasPromo] = useState(false);
  useEffect(() => {
    promoHandler(product, setItemHasPromo);
  }, []);

  const handleCompare = (sku: any) => {
    compareProductHandler(undefined, sku, isGuestUser!); 
  };

  return {
    setAddToCartStatus,
    addItemToCart,
    addItemToPallet,
    showModal,
    buildMixedPallet,
    setShowToShoppingListDialog,
    compareProductHandler,
    viewDetailsHandler,
    doWishlistAction,
    setFavClick,
    setIsWishlist,
    setIsModalOpen,
    addToCartHandler,
    addToCartStatus,
    router,
    validGuestUser,
    validCustomerStatus,
    inCart,
    favClick,
    isWishlist,
    isCustomerDataCompleted,
    setProductListInfo,
    palletId,
    vendorId,
    itemHasPromo,
    showShoppingListDialog,
    selectedItem,
    itemShoppingLists,
    isModalOpen,
    handleCompare, 
  };
};
