import React, { useState } from "react";
import { Button } from "antd";
import Style from "./style.module.scss";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useWindowResize } from "@src/utilities/hooks/useWindowResize.js";

const stripePromise = loadStripe(
  process.env.STRIPE_PUBLISHABLE_KEY || "pk_test_oBLTJgeLNOMG8d0ZECkSwyBF"
);

const CreditCardForm = ({
  onCancel,
  onAddCreditCard,
  setLoadingState,
}: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string>("");
  const windowWidth = useWindowResize();

  const onSubmit = async (stripe: any, elements: any) => {
    try {
      setLoadingState(true);
      const cardElement = elements.getElement(CardElement);
      const response = await stripe.createToken(cardElement);
      const tokenId = response?.token?.id;

      if (response?.error) {
        setError(response?.error?.message);
      } else {
      }
      if (tokenId) {
        onAddCreditCard(tokenId);
      }
    } catch (error) {
    } finally {
      setLoadingState(false);
    }
  };

  const inputStyleMobile = {
    fontSize: "16px",
  };

  const inputStyleDesktop = {
    fontSize: "inherit",
  };

  return (
    <>
      <CardElement
        onChange={() => setError("")}
        options={{
          hidePostalCode: true,
          style: {
            base: windowWidth < 992 ? inputStyleMobile : inputStyleDesktop,
          },
        }}
      />
      <div className={Style.actionButtonsContainer}>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={() => onSubmit(stripe, elements)} type="primary">
          Add
        </Button>
      </div>
      <div className={Style.errorMessage}>{error}</div>
    </>
  );
};

const CreditCardStipe = (props: any) => (
  <Elements stripe={stripePromise}>
    <CreditCardForm {...props} />
  </Elements>
);

export default CreditCardStipe;
