import { createReducer } from "../../store/utils";
import { MIXED_PALLET_ACTIONS } from "./actions";

const initialState = {
  loading: false,
  palletName: "",
  palletItems: [],
  vendorProducts: [],
  vendorId: 0,
  offset: 0,
  palletPrice: 0,
  vendorProductsCount: 0,
  next: null,
  productInfo: {},
  attributeGroups: {},
  pricesData: null,
  vendorName: "",
  palletLoadedProgress: 0,
  isLoaded: false,
  viewDetails: false,
  sku: "",
  palletFilters: [],
  appliedPalletFilters: [],
};

const setLoading = (state: any, action: any) => {
  return {
    ...state,
    loading: action.payload,
  };
};

const setPalletName = (state: any, action: any) => {
  return {
    ...state,
    palletName: action.payload,
  };
};

const setPalletItems = (state: any, action: any) => {
  return {
    ...state,
    palletItems: action.payload,
  };
};

const setVendorProducts = (state: any, action: any) => {
  return {
    ...state,
    vendorProducts: action.payload,
  };
};

const setPalletLoadedProgress = (state: any, action: any) => {
  return {
    ...state,
    palletLoadedProgress: action.payload,
  };
};

const setPalletFilters = (state: any, action: any) => {
  return {
    ...state,
    palletFilters: action.payload,
  };
};

const setAppliedPalletFilters = (state: any, action: any) => {
  return {
    ...state,
    appliedPalletFilters: action.payload,
  };
};

const setIsLoaded = (state: any, action: any) => {
  return {
    ...state,
    isLoaded: action.payload,
  };
};

const setSKU = (state: any, action: any) => {
  return {
    ...state,
    sku: action.payload,
  };
};

const setViewDetails = (state: any, action: any) => {
  return {
    ...state,
    viewDetails: action.payload,
  };
};

const setVendorId = (state: any, action: any) => {
  return {
    ...state,
    vendorId: action.payload,
  };
};

const setVendorName = (state: any, action: any) => {
  return {
    ...state,
    vendorName: action.payload,
  };
};

const setOffset = (state: any, action: any) => {
  return {
    ...state,
    offset: action.payload,
  };
};

const setPalletPrice = (state: any, action: any) => {
  return {
    ...state,
    palletPrice: action.payload,
  };
};

const setVendorProductsCount = (state: any, action: any) => {
  return {
    ...state,
    vendorProductsCount: action.payload,
  };
};

const setNext = (state: any, action: any) => {
  return {
    ...state,
    next: action.payload,
  };
};
const setProductInfo = (state: any, action: any) => {
  return {
    ...state,
    productInfo: { ...state.productInfo, ...action.payload },
  };
};

const setAttributeGroups = (state: any, action: any) => {
  return {
    ...state,
    attributeGroups: action.payload,
  };
};

const setPricesData = (state: any, action: any) => {
  return {
    ...state,
    pricesData: action.payload,
  };
};

const initiateMixedPallet = (state: any, action: any) => {
  return {
    ...state,
    ...action.payload,
  };
};

export default createReducer(initialState, {
  [MIXED_PALLET_ACTIONS.SET_LOADING]: setLoading,
  [MIXED_PALLET_ACTIONS.SET_PALLET_NAME]: setPalletName,
  [MIXED_PALLET_ACTIONS.SET_PALLET_ITEMS]: setPalletItems,
  [MIXED_PALLET_ACTIONS.SET_VENDOR_PRODUCTS]: setVendorProducts,
  [MIXED_PALLET_ACTIONS.SET_PALLET_LOADED_PROGRESS]: setPalletLoadedProgress,
  [MIXED_PALLET_ACTIONS.SET_PALLET_FILTERS]: setPalletFilters,
  [MIXED_PALLET_ACTIONS.APPLIED_PALLET_FILTERS]: setAppliedPalletFilters,
  [MIXED_PALLET_ACTIONS.SET_IS_LOADED]: setIsLoaded,
  [MIXED_PALLET_ACTIONS.SET_SKU]: setSKU,
  [MIXED_PALLET_ACTIONS.SET_VIEW_DETAILS]: setViewDetails,
  [MIXED_PALLET_ACTIONS.SET_VENDOR_ID]: setVendorId,
  [MIXED_PALLET_ACTIONS.SET_VENDOR_NAME]: setVendorName,
  [MIXED_PALLET_ACTIONS.SET_OFFSET]: setOffset,
  [MIXED_PALLET_ACTIONS.SET_PALLET_PRICE]: setPalletPrice,
  [MIXED_PALLET_ACTIONS.SET_VENDOR_PRODUCTS_COUNT]: setVendorProductsCount,
  [MIXED_PALLET_ACTIONS.SET_NEXT]: setNext,
  [MIXED_PALLET_ACTIONS.SET_PRODUCT_INFO]: setProductInfo,
  [MIXED_PALLET_ACTIONS.SET_ATTRIBUTE_GROUPS]: setAttributeGroups,
  [MIXED_PALLET_ACTIONS.SET_PRICES_DATA]: setPricesData,
  [MIXED_PALLET_ACTIONS.INITIATE_MIXED_PALLET]: initiateMixedPallet,
});
