import React from "react";
import { useState } from "react";
import { Modal } from "antd";
import CustomButton from "@components/shared/Button";
import { LoginModalProps } from "./types";
import styles from "./styles.module.scss";
import classnames from "classnames";
import { useRouter } from "next/router";
const LoginModal = ({
  signUpLabel,
  signInLabel,
  buttonText,
  hasIcon,
  Icon,
  className,
  dialogMessage
}: LoginModalProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const router = useRouter();
  const handleOk = () => {
    router?.push(
      `/?epallet_login=true&source_path=${router?.asPath?.replace("&", "__")}`
    );
    setIsModalOpen(false);
  };
  const handleSignUpOk = () => {
    router?.push("/?epallet_signup=true");
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div className={styles.logInModal}>
      <CustomButton
        theme="secondary"
        onClick={showModal}
        className={classnames(styles.logInBttn, "font-bold-xxs", className)}
        hasIcon={hasIcon}
        Icon={Icon}
      >
        {buttonText}
      </CustomButton>
      <Modal
        title="Login to continue"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className={styles.Modal}
        footer={[
          signInLabel && (
            <CustomButton theme="secondary" className="mb-6" onClick={handleOk}>
              {signInLabel}
            </CustomButton>
          ),
          signUpLabel && (
            <CustomButton theme="primary" onClick={handleSignUpOk}>
              {signUpLabel}
            </CustomButton>
          )
        ]}
      >
        <p>{dialogMessage}</p>
      </Modal>
    </div>
  );
};

export default LoginModal;
