import { createReducer } from "../../store/utils";
import { cloneDeep } from "lodash";
import { PLP_PRODUCTS } from "./action";
const initialState = {
  allPlpProducts: [],
  plpProducts: [],
  productsSKUs: [],
  plpCachedData: {},
};

const setAllPlpProducts = (state: any, action: any) => {
  return {
    ...state,
    allPlpProducts: cloneDeep(action.payload),
  };
};

const setProductSKUs =(state: any, action: any) => {
  return {
    ...state,
    productsSKUs: cloneDeep(action.payload),
  };
};

const setPlpProducts = (state: any, action: any) => {
  return {
    ...state,
    plpProducts: cloneDeep(action.payload),
  };
};

const setProductListInfo = (state: any, action: any) => {
  return {
    ...state,
    productListInfo: cloneDeep(action.payload ?? state.productListInfo),
  };
};

const setPlpCachedData = (state: any, action: any) => {
  return {
    ...state,
    plpCachedData: cloneDeep(action.payload),
  };
};

export default createReducer(initialState, {
  [PLP_PRODUCTS.SET_ALL_PLP_PRODUCTS]: setAllPlpProducts,
  [PLP_PRODUCTS.SET_PLP_PRODUCTS]: setPlpProducts,
  [PLP_PRODUCTS.SET_PRODUCT_LIST_INFO]: setProductListInfo,
  [PLP_PRODUCTS.SET_PLP_CACHED_DATA]: setPlpCachedData,
  [PLP_PRODUCTS.SET_PRODUCTS_SKUs]:setProductSKUs
});
