import Header from "@components/shared/Header";
import Footer from "@components/shared/Footer";
import { LayoutProps } from "./types";
import React from "react";
import SlickBanner from "../shared/SlickBanner";

export function Layout({
  children,
  layoutData,
  withoutHeaderProps = false,
  withoutFooterProps = false,
  withoutSlickBannerProps = false,
}: LayoutProps) {
  const headerProps = layoutData?.filter(
    (props: any) => props.id === "Header"
  )?.[0]?.params;
  const footerProps = layoutData?.filter(
    (props: any) => props.id === "Footer"
  )?.[0]?.params;

  const slickBannerProps = layoutData?.filter(
    (props: any) => props.id === "SlickBanner"
  )?.[0]?.params;

  return (
    <>
      {!withoutSlickBannerProps && <SlickBanner {...slickBannerProps} />}
      {!withoutHeaderProps && <Header {...headerProps} />}
      <div id="root-page">{children}</div>
      {!withoutFooterProps && <Footer {...footerProps} />}
    </>
  );
}
