import { Skeleton } from "antd";
import React from "react";
import { useWindowResize } from "@src/utilities/hooks/useWindowResize.js";
import styles from "./style.module.scss";

interface CarouselSkeletonProps {
  type?: string;
}

function CarouselSkeleton({ type }: CarouselSkeletonProps) {
  const windowWidth = useWindowResize();

  const boxLength =
    windowWidth > 1441
      ? Array(type === "mixMatchCarousel" ? 3 : 4).fill("1")
      : windowWidth > 768
      ? Array(3).fill("1")
      : Array(1).fill("1");

  return (
    <div className={`${type==="sponsored"? "py-8" : "py-30"} container`}>
      <div className={styles.topContainer}>
        <Skeleton active className={styles.title} paragraph={{ rows: 0 }} />
        <Skeleton active className={styles.rightArrow} paragraph={false} />
      </div>
      <div className={styles.boxContainer}>
        {boxLength.map((item: any, index: number) => (
          <Skeleton
            key={index}
            className={styles.box}
            active
            paragraph={false}
          />
        ))}
      </div>
    </div>
  );
}

export default CarouselSkeleton;
