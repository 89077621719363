import { useEffect } from "react";
import styles from "./styles.module.scss";

const HubSpotForm = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID,
          formId: process.env.NEXT_PUBLIC_NEWSLETTER_FORM_ID,
          target: "#hubspotForm",
        });
      }
    });
  }, []);

  return <div id="hubspotForm" className={styles.hubSpotForm}/>;
};

export default HubSpotForm