import { notification } from "antd";
import axios from "axios";
import { useContext } from "react";
import { AppContext } from "~/src/libs/context";
import { getText } from "~/src/libs/resources";
import { getCookie } from "~/src/server/utils/commonUtils";

const useHaggle = () => {
  const {
    getCustomerStatus
  } = useContext<any>(AppContext);
  const joinHaggleHandler = async(values:any,setOpenModal:any) =>{
    try {
        await axios.post(
          `/api/ERP/customer/haggle_rewards/joinHaggles`,
          {
            headers: { sessionId: getCookie("sessionid") },
            data: {
              haggle_customer_email: values?.email,
            },
          }
        );
        getCustomerStatus()
        notification.success({
          message: `Join Haggle email sent successfully`,
          duration: 4,
        });
        setOpenModal(false);
      } catch (error: any) {
        notification.error({
          message: getText().General.Messages.SomethingWrongTryAgain,
          duration: 4,
        });
      }
  }
  const getHaggleRewardsHistory = async (setIsLoading:any, setRewardsData:any) => {
    try {
      setIsLoading(true);
      const data = await axios.get("/api/ERP/customer/haggle_rewards", {
        headers: { sessionid: getCookie("sessionid") },
      });
      setRewardsData(data?.data?.results);
      setIsLoading(false);
    } catch (error: any) {}
  };
  return {
    joinHaggleHandler: joinHaggleHandler,
    getHaggleRewardsHistory:getHaggleRewardsHistory
  };
};

export default useHaggle;
