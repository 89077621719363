import { PaginationProps } from "../pagination/types";
import { Pagination, Row, Skeleton } from "antd";
import Style from "./style.module.scss";
import { useEffect } from "react";

const Index = ({
  total = 100,
  pageSize = 24,
  current = 0,
  setCurrent,
  isProductsLoading,
}: PaginationProps & {
  className?: string;
}) => {
  const onChange = (page: number) => {
    setCurrent(page);
  };

  useEffect(() => {
    setTimeout(()=>{
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    },100)
  }, [current]);
  const skeletonPages = Array.from({ length: 5 }, (_, index) => (
    <div key={index} className="mx-4">
      <Skeleton.Avatar active size="small" shape="circle" />
    </div>
  ));

  return (
    <>
    {(total > pageSize) &&
      <div className={Style.pagination}>
      {isProductsLoading ? (
        <Row align="middle" justify="center">{skeletonPages}</Row>
      ) : (
        <Pagination
          defaultCurrent={1}
          responsive={true}
          current={current}
          pageSize={pageSize}
          total={total}
          hideOnSinglePage={true}
          onChange={onChange}
        />
      )}
    </div>}
    </>
  );
};

export default Index;
