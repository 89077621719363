import { notification } from "antd";
import axios from "axios";
import { getCookie } from "src/server/utils/commonUtils";
import { getText } from "~/src/libs/resources";

const useWishlist = () => {
  const wishlist = async (
    productSlug: any,
    type: string,
    shoppingList?: string
  ) => {
    let status = false;
    try {
      if (type === "wish") {
        await axios
          .post("/api/ERP/customer/wishlist/wish", {
            headers: { sessionId: getCookie("sessionid") },

            data: {
              product: productSlug,
              wishlist_uids: shoppingList,
            },
          })
          .then((res) => {
            status = true;
          })
          .catch((error) => {
            status = false;
          });
      } else if (type === "unwish") {
        const data = {
          items: [{ product: productSlug }],
          wishlist_uids: shoppingList,
        };

        await axios
          .post("/api/ERP/customer/wishlist/unwish", {
            data,
            headers: { sessionId: getCookie("sessionid") },
          })
          .then((res) => {
            status = true;
          })
          .catch((err) => {
            status = false;
          });
      }
    } catch (error) {
      status = false;
    }

    return status;
  };

  const selectedWishlist = async (productSlugs: any, shoppingList?: string) => {
    let status = false;
    try {
      await axios
        .post("/api/ERP/customer/wishlist/wish/multiple_wish", {
          headers: { sessionId: getCookie("sessionid") },

          data: {
            product_slugs: productSlugs,
            wishlist_uids: shoppingList,
          },
        })
        .then((res) => {
          status = true;
        })
        .catch((error) => {
          status = false;
        });
    } catch (error) {
      status = false;
    }

    return status;
  };
  const addNewWishList = async (values: any, setIsSubmitLoading:any, getShoppingList:any, setShowSuccessModal:any, setShoppingListUpdated?:any,shoppingListUpdated?:boolean) => {
    setIsSubmitLoading(true);
    try {
      setShoppingListUpdated && setShoppingListUpdated(false);
      await axios
        .post("/api/ERP/customer/wishlist/create", {
          headers: { sessionId: getCookie("sessionid") },
          data: { name: values?.shoppingListName },
        })
        .then((res) => {
          getShoppingList();
          setShowSuccessModal(false);
          notification.success({
            message: `${values.shoppingListName} created successfully.`,
            duration: 2,
          });
          setIsSubmitLoading(false);
          setShoppingListUpdated && setShoppingListUpdated(true);
        })
    } catch (error) {
      notification.error({
        message: `${getText().General.Messages.SomethingWrongTryAgain}`,
        duration: 2,
      });
    }
  };
  return { wishlist, selectedWishlist, addNewWishList };
};
export default useWishlist;
