import { AccordionProps } from "./types";
import { Collapse, ConfigProvider } from "antd";
import React, { useState } from "react";
import Style from "./style.module.scss";
import { _accordionTheme } from "~/src/themes/ePalletTheme";
import classnames from "classnames";

const Accordion = ({
  header,
  className,
  disabled,
  closedIcon,
  openedIcon,
  content,
  ...props
}: AccordionProps): JSX.Element => {
  const { Panel } = Collapse;
  const [openPanels, setOpenPanels] = React.useState<string[]>([]);
  type ExpandIconPosition = "end";
  const [expandIconPosition, setExpandIconPosition] =
    useState<ExpandIconPosition>("end");
  const containerClasses = classnames(
    Style.Collapse,
    {
      [Style["accordion-disabled"]]: disabled
    },
    className
  );

  const setActivePanel = (activePanel: any) => {
    setOpenPanels(activePanel);
    if (activePanel?.length > 0) {
      setOpenPanels(activePanel);
    }
  };

  return (
    <ConfigProvider theme={_accordionTheme}>
      <Collapse
        bordered={false}
        activeKey={openPanels}
        accordion={true}
        className={`${containerClasses} ${
          openPanels?.includes(header) ? "active" : ""
        }`}
        expandIconPosition={expandIconPosition}
        expandIcon={({ isActive }) => (isActive ? openedIcon : closedIcon)}
        onChange={setActivePanel}
        collapsible={disabled ? 'disabled':'header'}
        {...props}
      >
        <Panel header={header} key={header + ""}>
          {content}
        </Panel>
      </Collapse>
    </ConfigProvider>
  );
};

export default Accordion;
