import Button from "@components/shared/Button";
import { Row, Col } from "antd";
import styles from "../styles.module.scss";
import { useContext } from "react";
import { AppContext } from "~/src/libs/context";
import Link from "next/link";
import { setCookie } from "~/src/server/utils/commonUtils";

const CompareActions = () => {
  const {
    setCompareProductDetails,
    setCompareProductsSku,
    setShowProductCompare
  } = useContext<any>(AppContext);

  const removeCompareItems = () => {
    setShowProductCompare(false);
    setCompareProductDetails([]);
    setCompareProductsSku([]);
    setCookie("isCompareActive", "false");
    setCookie("compareSkus", JSON.stringify([]));
  };

  return (
    <Row className={`${styles.actionContainerWrapper}`} justify="space-between">
      <Col
        xl={{ span: 12 }}
        lg={{ span: 12 }}
        md={{ span: 6 }}
        sm={{ span: 7.5 }}
        xs={{ span: 7.5 }}
        className="gutter-row"
      >
        <Button
          theme="secondary"
          text="Cancel"
          onClick={() => removeCompareItems()}
          className={`${styles.actionButton} font-bold-base lh-sm px-14`}
        />
      </Col>

      <Col
        xl={{ span: 12 }}
        lg={{ span: 12 }}
        md={{ span: 18 }}
        sm={{ span: 15 }}
        xs={{ span: 15 }}
        className="gutter-row"
      >
        <Link href="/compare">
          <a
            className={`${styles.actionButton} ${styles.compareProductsButton} font-bold-base lh-sm px-14`}
          >
            Compare
          </a>
        </Link>
      </Col>
    </Row>
  );
};

export default CompareActions;
