import classnames from "classnames";
import { TagProps } from "./types";
import { Tag } from "antd";
import styles from "./style.module.scss";
import Icon from "@components/shared/Icon";
import { memo } from "react";

const Tags = ({
  text,
  style,
  theme,
  className,
  size = "sm",
  hasIcon = false,
  iconName,
  iconWidth,
  iconHeight,
  iconRight = false,
  ...props
}: TagProps) => {
  const tagIcon = hasIcon ? Icon ? <Icon /> : null : null;

  const containerClasses = classnames(
    className,
    styles.tag,
    styles[`tag-${theme}`],
    styles[`tag-${size}`]
  );

  const tagContent = hasIcon ? (
    iconRight ? (
      <span className={styles.IconStyle}>
        {text} <Icon name={iconName} width={iconWidth} height={iconHeight} />
      </span>
    ) : (
      <span className={styles.iconStyle}>
        <Icon name={iconName} width={iconWidth} height={iconHeight} /> {text}
      </span>
    )
  ) : (
    <span className={styles.IconStyle}>{text}</span>
  );

  return (
    <Tag className={containerClasses} style={style} {...props}>
      {tagContent}
    </Tag>
  );
};

export default memo(Tags);
