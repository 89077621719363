import { Col, Radio, RadioChangeEvent, Row, Tooltip, Progress } from "antd";
import Icon from "~/src/components/shared/Icon";
import Tags from "~/src/components/shared/Tag";

import styles from "./styles.module.scss";
import CreditCardType from "../CreditCardType";
import { getText } from "~/src/libs/resources";
import { getPriceFormatted } from "~/src/libs/utils/getPriceFormatted";

const CreditLines = ({
  cards,
  defaultCard,
  setCardAsDefault,
}: {
  cards: CreditCardType[];
  defaultCard: CreditCardType;
  setCardAsDefault: Function;
}) => {
  const onChange = (e: RadioChangeEvent) => {
    setCardAsDefault(e.target.value);
  };

  return (
    <div className={styles.creditCards}>
      <div className={styles.cardsWrapper}>
        <Radio.Group
          className={styles.buttonGroup}
          onChange={onChange}
          value={defaultCard?.id}
        >
          <Row gutter={[16, 16]}>
            {cards?.map((item: CreditCardType) => {
              const po_credit_limit = item?.po_credit_limit ?? 0;
              const po_credit_limit_formatted = getPriceFormatted(
                po_credit_limit,
                "USD"
              );
              const current_po_debt = parseFloat(
                item?.current_po_debt.toFixed(2)
              );
              const current_po_debt_formatted = getPriceFormatted(
                current_po_debt,
                "USD"
              );
              const availablePrice = po_credit_limit - current_po_debt;
              const availablePriceFormatting = getPriceFormatted(
                availablePrice,
                "USD"
              );
              const percent = (availablePrice * 100) / po_credit_limit;
              return (
                <Col
                  key={item?.id}
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                  xl={{ span: 8 }}
                  xxl={{ span: 6 }}
                >
                  <div className={styles.itemBox}>
                    <div className={styles.titleContainer}>
                      <Icon name="mastercard" width={22} height={22} />
                      {item?.is_default ? (
                        <Radio value={item?.id}></Radio>
                      ) : (
                        <Tooltip title="Make default" placement="bottom">
                          <Radio value={item?.id}></Radio>
                        </Tooltip>
                      )}
                    </div>
                    <h3 className="font-bold-base mb-5">{item?.card_type}</h3>

                    <div className="pb-4">
                      <div className={styles.availableCredit}>
                        <h3 className="font-bold-xs mb-0">
                          {getText()?.Account?.cardBalance}
                        </h3>
                        <span className="font-medium-xs">
                          ${current_po_debt_formatted}
                        </span>
                      </div>
                    </div>
                    <div className="pb-4">
                      <div className={styles.availableCredit}>
                        <h3 className="font-bold-xs mb-0">
                          {getText()?.Account?.paymentTerms}
                        </h3>
                        <span className="font-medium-xs">
                          {item?.number_of_days_for_payment}
                          {getText()?.Account?.days}
                        </span>
                      </div>
                    </div>
                    <div className="pb-6">
                      <div className={styles.availableCredit}>
                        <h3 className="font-bold-xs mb-0">
                          {getText()?.Account?.availableCredit}
                        </h3>
                        <span>
                          ${availablePriceFormatting}/$
                          {po_credit_limit_formatted}
                        </span>
                      </div>

                      <span className="font-medium-xs mb-0">
                        <Progress
                          strokeColor="green"
                          showInfo={false}
                          percent={percent}
                        />
                      </span>
                    </div>
                    <div className={styles.lastRow}>
                      <div className={styles.icons}></div>
                      {item?.is_default ? (
                        <Tags theme="light" text="Default" size="sm" />
                      ) : (
                        <a
                          className={`m-0 ${styles.makeDefault}`}
                          onClick={() => setCardAsDefault(item?.id)}
                        >
                          <h6 className="m-0 font-semibold-xs">
                            {getText()?.Account?.makeDefaultButton}
                          </h6>
                        </a>
                      )}
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Radio.Group>
      </div>
    </div>
  );
};
export default CreditLines;
