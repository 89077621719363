const truncateString = (
  originalText: string,
  maxLength: number,
  setHasEllipsis: any,
  hasEllipsis: boolean
) => {
  const words = originalText.split(" ");
  let currentLine = "";
  const lines = [];
  for (const word of words) {
    if ((currentLine + word).length <= maxLength) {
      currentLine += (currentLine === "" ? "" : " ") + word;
    } else {
      lines.push(currentLine);
      currentLine = word;
    }
  }
  if (currentLine.length > 0) {
    lines.push(currentLine);
  }
  const result = lines.slice(0, 2).join("\n");
  if (lines?.length > 2) {
    if (!hasEllipsis) {
      setHasEllipsis(true);
    }
  }
  return result;
};

export default truncateString;
