import styles from "./styles.module.scss";
import { Modal } from "antd";
import { useEffect } from "react";
const RemoveModal = ({ confirmRemove, closeModal, confirmationText }: any) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);
  const onHideModal = () => {
    document.body.style.overflow = "auto";
  };
  return (
    <Modal
      open={true}
      title="Delete Confirmation"
      onCancel={() => {
        closeModal();
        onHideModal();
      }}
      onOk={() => {
        confirmRemove();
        onHideModal();
      }}
    >
      <div className={styles.closeModal}>
        <label>{confirmationText}</label>
      </div>
    </Modal>
  );
};

export default RemoveModal;
