import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import axios, { AxiosError } from "axios";
import useIsMounted from "./useIsMounted";

export interface UseApiCallOptions<ResultType> {
  onError?: (error: any) => void;
  rejectOnError?: boolean;
  onSuccess?: (result: ResultType) => void;
  onFinish?: () => void;
  onStart?: () => void;
}

export interface ApiCallOptions {
  abortController: null | AbortController;
}

export type ApiFnParametersWithoutOptions<T> = T extends (
  ...args: infer ArgT
) => Promise<any>
  ? ArgT
  : never;

export const useApiCall = <ApiCallFnT extends (...args: any) => Promise<any>>(
  apiCallFn: ApiCallFnT,
  useApiCallOptions: UseApiCallOptions<ReturnType<ApiCallFnT> | null> = {}
) => {
  const isMounted = useIsMounted();
  const abortControllerRef = useRef<AbortController | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError | null>(null);
  const [response, setResponse] =
    useState<Awaited<ReturnType<ApiCallFnT> | null>>(null);
  const reset = useCallback(() => {
    setIsLoading(false);
    setError(null);
    setResponse(null);
  }, []);
  const makeRequest = useCallback(
    async (
      callbackParams: ApiFnParametersWithoutOptions<ApiCallFnT>,
      abortController: AbortController,
      options: UseApiCallOptions<ReturnType<ApiCallFnT>>
    ): Promise<ReturnType<ApiCallFnT> | null> => {
      setIsLoading(true);
      options.onStart && options.onStart();
      let localError, result;
      try {
        result = await apiCallFn(...callbackParams, {
          abortController,
        });

        if (!isMounted) return null;

        if (result?.status > 299) {
          throw new Error(`Request failed with ${result.status}`);
        }

        setResponse(result);
        setError(null);
        localError = null;
        options.onSuccess && options?.onSuccess(result);
      } catch (e: any) {
        localError = e;
        if (isMounted()) {
          setError(e);
          if (!axios.isCancel(localError)) {
            setResponse(null);
          }
          options.onError && options?.onError(e);
        }
      } finally {
        if (isMounted()) {
          setIsLoading(false);
          options.onFinish && options?.onFinish();
        }
      }
      if (options.rejectOnError && localError) {
        return Promise.reject(localError);
      }
      return localError ? localError : result;
    },
    [apiCallFn, isMounted, reset]
  );

  useEffect(() => {
    return () => abortControllerRef?.current?.abort();
  }, []);

  const apiCall = useCallback(
    (...callbackParams: ApiFnParametersWithoutOptions<ApiCallFnT>) => {
      const abortController = new AbortController();
      abortControllerRef.current = abortController;
      return {
        promise: makeRequest(
          callbackParams,
          abortController,
          useApiCallOptions
        ),
        abortController,
      };
    },
    [makeRequest, useApiCallOptions]
  );

  return useMemo(
    () => ({
      isLoading,
      error,
      response,
      apiCall,
      reset,
    }),
    [isLoading, error, response, apiCall, reset]
  );
};
