export const dataSource = {
  FEATURED_PRODUCT: "Featured products",
  POPULAR_THIS_MONTH: "Popular this month",
  YOU_MIGHT_LIKE: "You might like",
  RECENTLY_VIEWED: "Recently viewed",
  OTHER_PRODUCTS: "Other products",
  RELATED_PRODUCTS: "Related products",
  RECOMMENDED_SEARCHES: "Recommended searches",
  BEST_SELLERS: "Best sellers",
  VIEWED_ITEMS: "Viewed Items",
  MIX_MATCH_VOLUME_DISCOUNT:"Mix Match Volume Discount",
  HAGGLE_REWARDS: "Haggle products"
};
