import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Tabs } from "antd";
import ManageAccount from "./ManageAccount";
import MyAddresses from "./MyAddresses";
import PaymentMethods from "./PaymentMethods";
import UpdatePassword from "./UpdatePassword";
import { AppContext } from "~/src/libs/context";
import { getText } from "~/src/libs/resources";
import { getCookie } from "cookies-next";
import SEO from "@components/shared/SEO";
import useClient from "~/src/server/utils/client";

interface AccountInformationProps {
  update?: boolean;
}

const AccountInformation = ({update}:AccountInformationProps) => {
  const router = useRouter();
  const {
    accountActiveTab,
    setAccountActiveTab,
    setMainMenuOpen,
    customerStatus,
  } = useContext<any>(AppContext);
  const client = useClient({});
  const { setIsSessionExpired } = useContext<any>(AppContext);
  const [allowedTabItems, setAllowedTabItems] = useState<any[]>();
  const [currentActiveTab, setCurrentActiveTab] = useState<string>();

  useEffect(() => {
    if (!accountActiveTab) {
      setAccountActiveTab("manageAccount");
    }
    router.push(`/account/accountInfo`);
    setCurrentActiveTab(accountActiveTab);
    setMainMenuOpen(false);
  }, []);

  const handleTabChanged = async (key: any) => {
    setCurrentActiveTab(key);
    try {
      const response = await client.basic.get("/api/ERP/customer/status", {
        headers: { sessionid: getCookie("sessionid") },
      });
      if (!response?.data?.is_authenticated) {
        setIsSessionExpired(true);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const tabsItems = [
    {
      label: `Manage Account`,
      key: "manageAccount",
      children: <ManageAccount updateQuery={update}/>,
    },
    {
      label: `My Addresses`,
      key: "myAddresses",
      children: <MyAddresses />,
    },
    {
      label: `Payment  Methods`,
      key: "paymentMethods",
      children: <PaymentMethods />,
    },
    {
      label: `Update Password`,
      key: "updatePassword",
      children: <UpdatePassword />,
    },
  ];
  useEffect(() => {
    if (
      !customerStatus?.permissions?.includes("MANAGE_SHIPPING_ADDRESSES") &&
      !customerStatus?.permissions?.includes("VIEW_SHIPPING_ADDRESSES")
    ) {
      const tabIndex = tabsItems.findIndex((obj) => obj?.key === "myAddresses");
      if (tabIndex > -1) {
        tabsItems.splice(tabIndex, 1);
        if (accountActiveTab === "myAddresses")
          setCurrentActiveTab("manageAccount");
      }
    }
    setAllowedTabItems(tabsItems);
  }, [customerStatus]);

  return (
    <>
      <SEO
        title={getText().Account?.SEO?.accountInfo?.title}
        description={getText().Account?.SEO?.accountInfo?.description}
      />
      <div className={styles.accountInformation}>
        <h2 className="mt-20 mb-24 font-bold-h2">
          {getText()?.Account?.accountInformationTitle}
        </h2>
        <Tabs
          activeKey={currentActiveTab}
          items={allowedTabItems}
          onTabClick={handleTabChanged}
          destroyInactiveTabPane={true}
        />
      </div>
    </>
  );
};

export default AccountInformation;
