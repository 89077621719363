import { API_URL } from "~/src/data/apiURLs";
import {
  AddItemToCartPayload,
  AddItemToPalletParams,
  AddItemToPalletPayload,
  ChangeNamePayload,
} from "~/src/types/pallet";

export const addItemToPallet = async ({
  payload,
  params,
  client,
}: {
  payload: AddItemToPalletPayload;
  params: AddItemToPalletParams;
  client: any;
}) => {
  return client.basic.post(API_URL.PALLET_ADD_ITEM(), payload, { params });
};

export const setPalletProductQuantity = async ({
  palletId,
  payload,
  client,
}: {
  palletId: string;
  payload: AddItemToPalletPayload;
  client: any;
}) => {
  return client.basic.post(
    API_URL.PALLET_SET_PRODUCT_QUANTITY(palletId),
    payload
  );
};

export const changePalletCustomerName = async ({
  id,
  payload,
  client,
}: {
  id: string;
  payload: ChangeNamePayload;
  client: any;
}) => {
  return client.basic.post(API_URL.PALLET_CHANGE_NAME(id), payload);
};

export const getMixedPalletById = async ({
  palletId,
  params,
  client,
}: {
  palletId: string;
  params: AddItemToPalletParams;
  client: any;
}) => {
  return client.basic.get(API_URL.PALLET_SET_PRODUCT_QUANTITY(palletId), {
    params,
  });
};

export const getMixedPalletProducts = async ({
  params,
  client,
}: {
  params: AddItemToPalletParams;
  client: any;
}) => {
  return client.basic.get(API_URL.PALLET_MIXED_PRODUCTS(), {
    params,
  });
};

export const getPalletById = async ({
  palletId,
  params,
  client,
}: {
  palletId: string;
  params: AddItemToPalletParams;
  client: any;
}) => {
  return client.basic.get(API_URL.PALLET_GET_BY_ID(palletId), { params });
};

export const setProductQuantity = async ({
  payload,
  client,
}: {
  payload: AddItemToCartPayload;
  client: any;
}) => {
  return client.basic.post(API_URL.SET_PRODUCT_QUANTITY(), payload);
};

export const getCart = async ({
  params,
  client,
}: {
  params: AddItemToPalletParams;
  client: any;
}) => {
  return client.basic.get(API_URL.GET_CART(), {
    params,
  });
};
