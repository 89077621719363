import { HeaderProps } from "./types";
import Navbar from "./Navbar";
import HeaderStoreLocator from "./HeaderStoreLocator";
import styles from "./styles.module.scss";
import classnames from "classnames";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "~/src/libs/context";

const Header = ({
  guestLabel,
  guestUrl,
  loggedInLabel,
  loggedInUrl,
  headerLinks,
  logoImage,
  signInLabel,
  signInLink,
  signUpLabel,
  signUpLink,
  MoveHaggleLinks
}: HeaderProps) => {
  const [openSearch, setOpenSearch] = useState(false);
  const {openMenuSearch} = useContext<any>(AppContext)
  useEffect(()=>{
     setOpenSearch(openMenuSearch)
  },[openMenuSearch])
  return (
    <header className={classnames(styles.headerContainer)}>
      <HeaderStoreLocator
        Mode="inline"
        guestLabel={guestLabel}
        guestUrl={guestUrl}
        loggedInLabel={loggedInLabel}
        loggedInUrl={loggedInUrl}
        headerLinks={headerLinks}
        MoveHaggleLinks={MoveHaggleLinks}
      />
      <Navbar
        Mode="horizontal"
        guestLabel={guestLabel}
        guestUrl={guestUrl}
        loggedInLabel={loggedInLabel}
        loggedInUrl={loggedInUrl}
        headerLinks={headerLinks}
        logoImage={logoImage}
        signInLabel={signInLabel}
        signInLink={signInLink}
        signUpLabel={signUpLabel}
        signUpLink={signUpLink}
        setOpenSearch={setOpenSearch}
        openSearch={openSearch}
        MoveHaggleLinks={MoveHaggleLinks}
      />
    </header>
  );
};

export default Header;
