export const getMetroLimitedAccessOptions = () => {
  return [
    {
      value: "",
      label: "None",
    },
    {
      value: "Chicago Loop Area",
      label: "Chicago Loop Area",
    },
    {
      value: "Downtown Atlanta",
      label: "Downtown Atlanta",
    },
    {
      value: "Greater Metro Boston Area",
      label: "Greater Metro Boston Area",
    },
    {
      value: "Massachusetts Cape Cod Area",
      label: "Massachusetts Cape Cod Area",
    },
    {
      value: "Maryland Shore",
      label: "Maryland Shore",
    },
    {
      value: "New Jersey Shore",
      label: "New Jersey Shore",
    },
    {
      value: "Louisiana",
      label: "Louisiana",
    },
    {
      value: "Points On The San Francisco Peninsula",
      label: "Points On The San Francisco Peninsula",
    },
    {
      value: "Greater Downtown Los Angeles and Hollywood, CA, Area",
      label: "Greater Downtown Los Angeles and Hollywood, CA, Area",
    },
    {
      value: "South Carolina Island Communities",
      label: "South Carolina Island Communities",
    },
    {
      value: "Upstate New York",
      label: "Upstate New York",
    },
    {
      value: "Washington Island, WI",
      label: "Washington Island, WI",
    },
    {
      value: "Washington, D.C",
      label: "Washington, D.C",
    },
    {
      value: "Maryland",
      label: "Maryland",
    },
    {
      value: "Virginia",
      label: "Virginia",
    },
    {
      value: "Downtown Philadelphia",
      label: "Downtown Philadelphia",
    },
    {
      value: "Greater NYC and adjacent NJ Area",
      label: "Greater NYC and adjacent NJ Area",
    },
    {
      value: "Greater Seattle / Tacoma, WA Area",
      label: "Greater Seattle / Tacoma, WA Area",
    },

    {
      value: "Other",
      label: "Other",
    },
  ];
};
