import axios from "axios";
import { fabricConfiguration, erbConfiguration } from "./configBackend";

export const getFabricCommonHeaders = (token: any = null) => ({
  "x-site-context": JSON.stringify({
    account: fabricConfiguration.account,
    stage: fabricConfiguration.stage,
    site: fabricConfiguration.site,
    channel: fabricConfiguration.channel,
    date: new Date().toISOString()
  }),
  "Content-Type": "application/json",
  ...(token ? { Authorization: token } : {})
});

export const axiosFabricCdn = axios.create({
  baseURL: fabricConfiguration.cdnDomain,
  timeout: 30000,
  withCredentials: false
});

axiosFabricCdn.interceptors.request.use(
  (config: any) => {
    config.headers = {
      ...config.headers,
      ...getFabricCommonHeaders()
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const axiosFabric = axios.create({
  baseURL: fabricConfiguration.apiDomain,
  timeout: 30000,
  withCredentials: false
});

axiosFabric.interceptors.request.use(
  (config: any) => {
    config.headers = {
      ...config.headers,
      ...getFabricCommonHeaders()
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * Copilot Endpoints
 */
export const axiosFabricCopilot = axios.create({
  baseURL: fabricConfiguration.copilotAPI,
  timeout: 100000,
  withCredentials: false
});
export const axiosErb = axios.create({
  baseURL: erbConfiguration.apiDomain,
  timeout: 100000,
  withCredentials: false
});
axiosFabricCopilot.interceptors.request.use(
  (config: any) => {
    config.headers = {
      ...config.headers,
      ...getFabricCommonHeaders()
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
