import { Checkbox, ConfigProvider, Tooltip } from "antd";
import { CheckboxProps } from "./types";
import styles from "./style.module.scss";
import { _checkBoxTheme } from "~/src/themes/ePalletTheme";
import { tooltipText } from "./tooltipText";
import Icon from "../Icon";

const CheckBox = ({
  disabled = false,
  label,
  onChange,
  value,
  checked,
  hasInfoIcon,
}: CheckboxProps) => {
  return (
    <ConfigProvider theme={_checkBoxTheme}>
      <div className={styles.checkboxWrapper}>
        <Checkbox
          className={styles.Checkbox}
          onChange={onChange}
          value={value}
          disabled={disabled}
          checked={checked}
        ></Checkbox>
        {hasInfoIcon ? (
          <>
            <Tooltip title={tooltipText(label || "", value || "")}>
              <div className={styles.labelWrapper}>
                {label}
                <Icon name="info" width={20} height={20} />
              </div>
            </Tooltip>
          </>
        ) : (
          <>{label}</>
        )}
      </div>
    </ConfigProvider>
  );
};

export default CheckBox;
