import { fabricConfiguration } from "@src/server/utils/configBackend";

export const GET_PRODUCT_URL = (sku: any, skus: any) =>
  `${fabricConfiguration.copilotAPI}/api-product/v1/product?skus=${
    skus ? JSON.stringify(skus || "") : `["${sku}"]`
  }`;

export const GET_ATTRIBUTE_GROUPS_URL = () =>
  `${fabricConfiguration.copilotAPI}/api-product/v1/product/attribute-group/search`;

export const FILTERS_ORDERS = [
  {
    header: { label: "Order status", key: "Order status" },
    items: [
      { label: "Pending", key: "pending" },
      { label: "Delivered", key: "delivered" },
      { label: "Cancelled", key: "cancelled" },
      { label: "Recalled ", key: "recalled" },
      { label: "Donated ", key: "donated" },
      { label: "Lost Shipment ", key: "lostShipment" }
    ]
  },
  {
    header: { label: "Sort date", key: "Sort date" },
    items: [
      { label: "New to old", key: "New to old" },
      { label: "Old to new", key: "Old to new" }
    ]
  },
  {
    header: { label: "Amount", key: "Amount" },
    items: [
      { label: "Min Amount", key: "minPrice" },
      { label: "Max Amount", key: "maxPrice" }
    ]
  }
];
export const FILTERS_MENU = [
  {
    header: { label: "Dietary", key: "Dietary" },
    items: [
      { label: "Fat free(<0.5g)", key: "fat-free", icon: "fat-free" },
      { label: "Organic", key: "organic", icon: "organic" },
      { label: "Kosher", key: "kosher", icon: "kosher" },
      { label: "Gluten free", key: "gluten-free", icon: "gluten-free" },
      { label: "Halal", key: "halal", icon: "halal" },
      { label: "Low fat", key: "low-fat", icon: "low-fat" },
      { label: "Non Gmo", key: "nonGMO", icon: "nonGMO" },
      { label: "Vegan", key: "vegan", icon: "vegan" }
    ]
  },
  {
    header: { label: "Category", key: "Category" },
    items: [
      { label: "Food", key: "true" },
      { label: "Non Food", key: "false" }
    ]
  },
  {
    header: { label: "Packaging type", key: "Packaging" },
    items: [
      { label: "Bag", key: "BAG" },
      { label: "Plastic Tub", key: "PLASTIC TUB" },
      { label: "Metal Drum", key: "METAL DRUM" }
    ]
  },
  {
    header: { label: "Brand", key: "Brand" },
    items: []
  },
  {
    header: { label: "Manufacturer", key: "Country of manufacturer" },
    items: [
      { label: "US", key: "US" },
      { label: "CA", key: "CA" }
    ]
  },
  {
    header: { label: "Growth country", key: "Country of growth" },
    items: [
      { label: "US", key: "US" },
      { label: "CA", key: "CA" }
    ]
  },
  {
    header: { label: "Certifications", key: "Certifications" },
    items: []
  },
  {
    header: { label: "Price per oz", key: "Price per oz" },
    items: [
      { label: "Fat free(<0.5g)", key: "fat-free", icon: "fat-free" },
      { label: "Organic", key: "organic", icon: "organic" },
      { label: "Kosher", key: "kosher", icon: "kosher" }
    ]
  },
  {
    header: { label: "Unit size (fl oz)", key: "Unit size" },
    items: [
      { label: "4", key: "4.0" },
      { label: "8", key: "8.0" }
    ]
  },
  {
    header: { label: "Shipping/Storage Type", key: "Shipping method" },
    items: [
      { label: "Refrigearated", key: "REFRIGERATED" },
      { label: "Dry", key: "DRY" }
    ]
  }
];

export const DEFAULT_ZIP_CODE = "91301";