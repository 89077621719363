import { Col, Row } from "antd";
import Icon from "~/src/components/shared/Icon";
import Button from "@components/shared/Button";
import styles from "./styles.module.scss";
import { getText } from "~/src/libs/resources";
import CustomButton from "@components/shared/Button";
import Link from "next/link";
import { useContext, useState } from "react";
import { AppContext } from "~/src/libs/context";
import JoinHaggle from "./JoinHaggle";
import DataLoader from "~/src/components/shared/DataLoader";
import HaggleRewards from "../../HaggleRewards";

const HaggleReward = () => {
  const { customerStatus, setCurrent, setContent } = useContext<any>(AppContext);
  const [openModal, setOpenModal] = useState<boolean>(false);
  return (
    <>
      {customerStatus?.user_id ? (
        <div className={`${styles.haggleWrapper} pt-8 px-18 pb-22`}>
          {customerStatus?.is_haggle_customer && (
            <Row gutter={[8, 8]} align="middle" className="mb-11">
              <Col className={styles.iconWrapper}>
                <Icon name="haggle_icon" />
              </Col>
              <Col className={styles.haggleSection}>
                <h5 className="font-bold-h5 mb-0">
                  {getText()?.Account?.haggle}
                </h5>
                <Link href="/account/haggleRewards">
                  <a>
                    <Button
                      className={`font-bold-sm ms-5 p-0 ${styles.link}`}
                      theme={"link"}
                      onClick={()=>{  setContent(<HaggleRewards/>);
                      setCurrent("haggleRewards");}}
                    >
                      {getText()?.Account?.viewDetails}
                    </Button>
                  </a>
                </Link>
              </Col>
            </Row>
          )}
          {!customerStatus?.is_haggle_customer ? (
            <div className={`pt-17 pb-3 px-4 ${styles.joinWrapper}`}>
              <Row className="mb-8" gutter={[16, 16]}>
                <Col
                  className={`font-regular-base ${styles.haggleDesc}`}
                  lg={6}
                  md={12}
                  xs={24}
                >
                  {getText()?.Haggle?.earnRewards}
                  <p className="font-bold-base mb-0">
                    {getText()?.Haggle?.forFree}
                  </p>
                </Col>

                <Col className={styles.border} lg={6} md={12} xs={24}>
                  <p className="font-bold-base mb-4">
                    {getText()?.Haggle?.noMembership}
                  </p>
                  <p className="font-regular-sm mb-0">
                    {getText()?.Haggle?.freeHaggle}
                  </p>
                </Col>
                <Col className={`${styles.withBorder}`} lg={6} md={12} xs={24}>
                  <p className="font-bold-base mb-4">
                    {getText()?.Haggle?.spend}
                  </p>
                  <p className="font-regular-sm mb-0">
                    {getText()?.Haggle?.betterBenefits}
                  </p>
                </Col>
                <Col className={styles.border} lg={6} md={12} xs={24}>
                  <p className="font-bold-base mb-4">
                    {getText()?.Haggle?.enjoyBenefits}
                  </p>
                  <p className="font-regular-sm mb-0">
                    {getText()?.Haggle?.maintainBenefits}
                  </p>
                </Col>
              </Row>
              <Row
                justify="space-between"
                align="middle"
                className="pt-12"
                gutter={[16, 16]}
              >
                <Row align="middle" className="font-bold-h2">
                  <Icon
                    name="haggleRewards"
                    classesName="pe-6"
                    width={34}
                    height={40}
                  />
                  {getText()?.Haggle?.joinHaggle}
                </Row>
                <CustomButton
                  className="font-semibold-xs px-20"
                  theme="primary"
                  text={getText()?.Account?.join}
                  onClick={() => setOpenModal(true)}
                />
              </Row>
            </div>
          ) :(
            <Row>
              {getText()?.checkout?.currentlyHave} $
              {customerStatus?.available_haggle_rewards}{" "}
              {getText()?.Haggle?.asHaggle}
            </Row>
          )}
          <JoinHaggle isOpen={openModal} setOpenModal={setOpenModal} />
        </div>
      ) : (
        <Row justify="center" className="py-20">
          <DataLoader />
        </Row>
      )}
    </>
  );
};
export default HaggleReward;
