import styles from "./styles.module.scss";
import { Tabs } from "antd";
import AddUser from "./AddUser";
import ManageUsersTab from "./ManageUsersTab";
import { AppContext } from "@src/libs/context";
import { useContext, useState, useEffect } from "react";
import SEO from "@components/shared/SEO";
import { getText } from "~/src/libs/resources";

const ManageUsers = () => {
  const { customerStatus } = useContext<any>(AppContext);
  const [viewUsers, setViewUsers] = useState<boolean>(false);
  const tabsItems = [
    {
      label: `Add User`,
      key: "addUser",
      children: <AddUser />
    },
    {
      label: `Manage users`,
      key: "manageusers",
      children: <ManageUsersTab />
    }
  ];
  useEffect(() => {
    const userPermissions = customerStatus?.permissions;
    if (!userPermissions?.includes("MANAGE_USERS")) {
      if (userPermissions?.includes("VIEW_USERS")) setViewUsers(true);
      else setViewUsers(false);
    }
  }, [customerStatus]);

  return (
    <div className={styles.manageUsers}>
      <SEO
        title={getText().Account?.SEO?.manageUsers?.title}
        description={getText().Account?.SEO?.manageUsers?.description}
      />
      <h2 className="mt-20 mb-24 font-bold-h2">
        {viewUsers ? "Users" : "Manage Users"}
      </h2>
      {viewUsers ? (
        <ManageUsersTab />
      ) : (
        <Tabs
          defaultActiveKey="addUser"
          items={tabsItems}
          destroyInactiveTabPane={true}
        />
      )}
    </div>
  );
};

export default ManageUsers;
