import { getText } from "~/src/libs/resources";

export const tooltipText = (label: string, value:string) => {
  let tooltipLabel = "";
  switch (value) {
    case "residential_delivery":
      tooltipLabel = getText()?.Account?.residential_delivery;
      break;
    case "lift_gate_required":
      tooltipLabel = getText()?.Account?.lift_gate_required;
      break;
    case "inside_delivery_required":
      tooltipLabel = getText()?.Account?.inside_delivery_required;
      break;
    case "appointment_required":
      tooltipLabel = getText()?.Account?.appointment_required;
      break;
    case "limited_access":
      tooltipLabel = getText()?.Account?.limited_access;
      break;
    default:
      tooltipLabel = label;
  }

  return tooltipLabel;
};
