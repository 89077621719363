export const getPasswordRequirements = () => {
    const passwordRequirements = [
        "Your password shouldn't be too similar to your other personal information.",
        "Your password must contain at least 8 characters.",
        "Your password should’t be a commonly used password.",
        "Your password should’t be entirely numeric.",
        "Your password should contain at least one capital letter.",
        "Your password must contain at most 20 characters.",
      ];
    return passwordRequirements
  };