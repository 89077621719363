import axios from "axios";
import { getCookie } from "~/src/server/utils/commonUtils";

type IProduct = {
  productSlug: string;
  product: any;
}

type IMixMatchRepository = {
  _data: {
    [productSlug in string]: any;
  },
  cache: (props: IProduct) => void,
  get: (productSlug?: string) => Promise<any | undefined>,
}
export const MixMatchVolumeProductsRepository: IMixMatchRepository = {
  _data: {},
  cache: ({ productSlug, product }: IProduct) => {
    MixMatchVolumeProductsRepository._data[productSlug] = product
  },
  get: async (productSlug) => {
    if (!productSlug) {
      return undefined;
    }
    if (MixMatchVolumeProductsRepository?._data[productSlug]) {
      return (MixMatchVolumeProductsRepository?._data[productSlug])
    }
    try {
      const { data } = await axios.get(`/api/ERP/customer/product/${productSlug}/crossPromotedProducts?pallets=1&limit=10`, {
        headers: { sessionId: getCookie("sessionid") }
      })
      if (data) {
        MixMatchVolumeProductsRepository.cache({
          productSlug: productSlug,
          product: data,
        },)
        return data;
      }
    }
    catch (err) {
      return undefined;
    }
  }
}