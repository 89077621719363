export const FormFields = [
  {
    name: "name",
    placeHolder: "Name",
    required: true,
    rules: [
      {
        required: true,
        message: "Name is required"
      },
      { whitespace: true },
      { min: 3, message: " Must be at least 3 characters" },
    ]
  },
  {
    name: "email",
    placeHolder: "Email",
    required: true,
    rules: [
      {
        required: true,
        message: "Email is required"
      },
      { whitespace: true },
      {
        pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
        message: "Invalid email"
      }
    ]
  },
  {
    name: "phoneNumber",
    placeHolder: "Phone Number",
    required: true,
    rules: [
      {
        required: true,
        message: "Phone Number is required"
      },
      { whitespace: true },
      { len: 10, message: "Phone number must be exactly 10 characters" },
      {
        pattern: /^[0-9]+$/,
        message: `Phone number must be 10 numbers 'no dashes'`
      }
    ]
  },
  {
    name: "productName",
    placeHolder: "Product Name",
    required: true,
    rules: [
      {
        required: true,
        message: "Product Name is required"
      },
      { whitespace: true }
    ]
  },
  {
    name: "productLink",
    placeHolder: "Outside Product Reference Link",
    required: false,
    rules: []
  }
];
