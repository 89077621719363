import React, { ReactNode, useEffect } from 'react';
import useOnlineStatus from '~/src/utilities/hooks/useOnlineStatus';
import { notification } from 'antd';

interface Props {
  children: ReactNode;
}

const NoInternetConnection: React.FC<Props> = ({ children }) => {
  const isOnline = useOnlineStatus();

  useEffect(() => {
    if (!isOnline) {
      notification.error({
        message: 'No Internet Connection',
        description: 'Something went wrong. Please check your internet connection and try again.',
        duration: 0,
      });
    }
  }, [isOnline]);

  return <>{children}</>;
};

export default NoInternetConnection;
