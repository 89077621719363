import classnames from "classnames";
import { ButtonProps } from "./types";
import { Button, ConfigProvider } from "antd";
import styles from "./style.module.scss";
import { _btnTheme } from "~/src/themes/ePalletTheme";
import { memo } from "react";

const CustomButton = ({
  children,
  disabled,
  text,
  theme,
  className,
  style,
  onClick,
  hasIcon,
  Icon,
  ...props
}: ButtonProps): JSX.Element => {
  const isDisabled = disabled;
  const handleClick = !isDisabled && onClick ? onClick : () => {};
  const btnIcon = hasIcon ? Icon ? <Icon /> : null : null;

  const containerClasses = classnames(
    className,
    styles.btn,
    styles[`btn-${theme}`],
    {
      [styles["btn-disabled"]]: isDisabled,
    }
  );

  return (
    <ConfigProvider theme={_btnTheme}>
      <Button
        type="primary"
        style={style}
        className={containerClasses}
        onClick={handleClick}
        icon={btnIcon}
        {...props}
        disabled={disabled}
      >
        <span> {children || text} </span>
      </Button>
    </ConfigProvider>
  );
};

export default memo(CustomButton);
