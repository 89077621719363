import styles from "./styles.module.scss";
import { Row, Col, Divider, notification } from "antd";
import CustomButton from "@components/shared/Button";
import Tag from "@components/shared/Tag";
import Icon from "@components/shared/Icon";
import OrderCard from "../OrderCard";
import axios from "axios";
import { getCookie } from "src/server/utils/commonUtils";
import { useEffect, useState, useContext } from "react";
import { AppContext } from "~/src/libs/context";
import LoadingWithOverlay from "~/src/components/shared/LoadingWithOverlay";
import { useRouter } from "next/router";
import { usePriceFormat } from "@src/utilities/hooks/usePriceFormat";
import { getText } from "~/src/libs/resources";
import Image from "next/image";
const OrderDetails = ({ setViewDetails, orderDetails }: any) => {
  const { setCart, setIsViewDetails, orderID, customerStatus, defaultPostZip, getCartCount } =
    useContext<any>(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [orderInfo, setOrderInfo] = useState<any>();
  const [manageOrderPermission, setManageOrderPermission] =
    useState<boolean>(true);
  const router = useRouter();
  useEffect(() => {
    if (customerStatus?.permissions?.includes("MANAGE_ORDERS")) {
      setManageOrderPermission(true);
    } else setManageOrderPermission(false);
  }, [customerStatus]);

  let quantity = 0;
  orderInfo?.pallets?.forEach((pallet: any) => {
    if (pallet?.pallet_items?.length) {
      quantity += pallet.quantity ;
    }
  });

  const fetchOrderData = async () => {
    setIsLoading(false);
    let response: any;
    try {
      response = await axios.get(
        `/api/ERP/orders/${orderDetails ? orderDetails?.orderID : orderID}`,
        {
          headers: { sessionId: getCookie("sessionid") }
        }
      );
      setOrderInfo(response?.data);
      setIsLoading(true);
    } catch (error) {
      notification.error({
        message: getText().General.Messages.SomethingWrongTryAgain,
        duration: 4
      });
    }
  };
  const getCartItems = async () => {
    try {
      let url = `/api/ERP/cart`;
      if (defaultPostZip) {
        url += `?post_zip=${defaultPostZip}`;
      }
      await axios
        .get(url, {
          headers: { sessionId: getCookie("sessionid") }
        })
        .then((res) => {
          setCart(res?.data);
        })
        .catch(() => {
          notification.error({
            message: getText().General.Messages.SomethingWrongTryAgain,
            duration: 4
          });
        });
    } catch (e) {
      notification.error({
        message: getText().General.Messages.SomethingWrongTryAgain,
        duration: 4
      });
    }
    setIsLoading(true);
  };
  const reorderHandler = async () => {
    setIsLoading(false);
    try {
      await axios.post(
        `/api/ERP/orders/${
          orderDetails ? orderDetails?.orderID : orderID
        }/reorder`,
        {
          headers: { sessionId: getCookie("sessionid") }
        }
      );
      getCartCount();
      await getCartItems();
      router.push("/cart");
      notification.success({
        message: `Order Items have been added to your cart`,
        duration: 4
      });
    } catch (error) {
      notification.error({
        message: getText().General.Messages.SomethingWrongTryAgain,
        duration: 4
      });
      setIsLoading(true);
    }
  };
  useEffect(() => {
    fetchOrderData();
    router.push({
      pathname: `/account/myOrders`,
      query: { orderID: orderID }
    });
  }, []);

  return (
    <div className={`${styles.orderDetailsWrapper} py-20 text-oxfordBlue`}>
      <button
        className={`${styles.noBorder} bg-transparent text-oxfordBlue p-0 pb-8`}
        onClick={() => {
          setViewDetails(false);
          setIsViewDetails(false);
          router.push("/account/myOrders");
        }}
      >
        <Icon name="arrow" dir="bottom" />
        <span className="font-bold-h6">
          {getText()?.Account?.backToTrackOrders}
        </span>
      </button>
      <Row justify="space-between" gutter={[0, 24]}>
        <Col>
          <Row className={`${styles.trackingID} font-bold-h2 pb-4`}>
            <label className="pe-4">
              {getText()?.Account?.orderId} {orderInfo?.order_number}
            </label>
            <Icon name="downArrow" color="#02254a" width={20} height={12} />
          </Row>

          {isLoading ? (
            <Row gutter={[0, 24]}>
              <Col className={`${styles.placedBy} ${styles.column}`}>
                <span className="font-bold-xs pb-2">
                  {getText()?.Account?.placedBy}
                </span>
                <span className="font-regular-sm">
                  {orderInfo?.shipping_address?.contact_name}
                </span>
              </Col>
              <Divider type="vertical" className={styles.divider} />
              <Col className={`${styles.orderDate} ${styles.column}`}>
                <span className="font-bold-xs pb-2">
                  {getText()?.Account?.OrderDate}
                </span>
                <span className="font-regular-sm">
                  {orderInfo?.date_placed?.slice(0, 10)}
                </span>
              </Col>
              <Divider type="vertical" className={styles.divider} />
              <Col className={`${styles.amount} ${styles.column}`}>
                <span className="font-bold-xs pb-2">
                  {getText()?.Account?.amount}
                </span>
                <span className="font-regular-sm">
                  $
                  {usePriceFormat(
                    Number(orderInfo?.grand_total_delivered_price || 0)
                  )}
                </span>
              </Col>
              <Divider type="vertical" className={styles.divider} />
              <Col className={`${styles.paymentMethod} ${styles.column}`}>
                <span className="font-bold-xs pb-2">
                  {getText()?.Account?.paymentMethod}
                </span>
                <span className="font-regular-sm">
                  {orderInfo?.credit_card?.payment_method}
                </span>
              </Col>
              <Divider type="vertical" className={styles.divider} />
              <Col className={`${styles.quantity} ${styles.column}`}>
                <span className="font-bold-xs pb-2">
                  {getText()?.MixedPallet?.palletQuantity}
                </span>
                <span className="font-regular-sm">{quantity}</span>
              </Col>
              <Divider type="vertical" className={styles.divider} />
              <Col className={`${styles.deliveryAddress} ${styles.column}`}>
                <span className="font-bold-xs pb-2">
                  {getText()?.Account?.deliveryAddress}
                </span>
                <span className="font-regular-sm">
                  {orderInfo?.shipping_address?.address +
                    " " +
                    orderInfo?.shipping_address?.city +
                    "," +
                    orderInfo?.shipping_address?.state +
                    " " +
                    orderInfo?.shipping_address?.post_zip}
                </span>
              </Col>
            </Row>
          ) : null}
        </Col>
        {isLoading
          ? manageOrderPermission && (
              <Col>
                <CustomButton
                  theme="primary"
                  onClick={reorderHandler}
                  className={`${styles.reorderBtn} px-20 py-10`}
                >
                  {getText()?.Account?.reorderButton}
                </CustomButton>
                <a
                  href={`/api/ERP/orders/${orderInfo?.order_number}/pdf`}
                  rel="noreferrer"
                  target="_blank"
                  className="ms-15"
                >
                  <CustomButton theme="link">
                    {getText()?.Account?.pdf}
                  </CustomButton>
                </a>
              </Col>
            )
          : null}
      </Row>

      {isLoading ? null : (
        <div>
          <LoadingWithOverlay />
        </div>
      )}
      <Divider
        type="horizontal"
        className={styles.divider}
        style={{ margin: "0", marginTop: "16px" }}
      />
      {orderInfo?.pallets?.map((item: any, index: number) => (
        <>
          {item?.pallet_type === "MIXED_PALLET" ? (
            <>
              <Row className={`${styles.mixedPallet} mt-16`} gutter={[24, 24]}>
                <Col>
                  <div>
                    <Tag
                      className={`${styles.cartTag} font-semibold-xxs text-white bg-oxfordBlue my-2 ms-2`}
                      theme="light"
                      style={{ zIndex: 105 }}
                      text="Mixed Pallet"
                      hasIcon={true}
                      iconName="cartPallet"
                      iconHeight={12}
                      iconWidth={12}
                    />
                  </div>
                  <Image
                    unoptimized
                    width={184}
                    height={131}
                    className={styles.image}
                    src="https://cdn.bfldr.com/SK3DIHJL/at/6mhvvc45zskm99fgx8hhn7/mixedPallet.svg?auto=webp&format=png"
                    alt="cart item"
                  />
                </Col>
                <Col className="mx-8 py-16">
                  <Row
                    align={"middle"}
                    gutter={[16, 16]}
                    wrap={true}
                    className={styles.productLink}
                    justify="space-between"
                  >
                    <Col className={`${styles.productName} font-bold-h4`}>
                      {item?.name}
                    </Col>
                    {orderInfo?.customer_po && (
                      <Col className={`${styles.productOrder} font-bold-base`}>
                        {getText()?.Account?.purchaseOrder}:
                        <span className="ps-2">{orderInfo?.customer_po}</span>
                      </Col>
                    )}
                  </Row>
                  {item?.pallet_items?.map((ele: any, index: number) => (
                    <OrderCard
                      key={index}
                      item={ele}
                      orderInfo={orderInfo}
                      type={item?.pallet_type}
                      quantity= {item?.quantity}
                    />
                  ))}
                </Col>
              </Row>
            </>
          ) : (
            <OrderCard
              key={index}
              item={item?.pallet_items?.[0]}
              orderInfo={orderInfo}
              type={item?.pallet_type}
              quantity= {item?.quantity}
            />
          )}
        </>
      ))}
      {/* TODO: waiting for client confirmation on track orders */}
      {/* {[{}, {}].map((order, id) => (
        <>
          <Row
            justify="space-between"
            className={`${styles.orderCardWrapper} my-12`}
          >
            <Col>
              <div>
                <label>Tracking ID</label>
                <p className="mb-0 pt-2 font-bolder-h5">
                  E-657676{" "}
                  <Tag className="ps-4 font-semibold-xs bg-oxfordBlue text-white">
                    Delivered
                  </Tag>
                </p>
              </div>
            </Col>
            <Col>
              <CustomButton theme="link" className="font-semibold-sm">
                Track Order
              </CustomButton>{" "}
            </Col>
          </Row>
          {data?.map((item, index) => (
            <OrderCard key={index} />
          ))}
        </>
      ))} */}
    </div>
  );
};

export default OrderDetails;
