import { Row, Col } from "antd";
import ProductItems from "./Components/ProductItems";
import CompareActions from "./Components/CompareActions";
import AddProduct from "./Components/AddProduct";
import { AppContext } from "~/src/libs/context";
import styles from "./styles.module.scss";
import { useContext, useEffect, useState } from "react";
import Accordion from "@components/shared/Accordion";
import Icon from "@components/shared/Icon";
import { fetchProductData } from "~/src/libs/api/product";

const CompareProduct = () => {
  const {
    compareProductDetails = [],
    setCompareProductDetails,
    showProductCompare,
    compareProductsSku
  } = useContext<any>(AppContext);
  const [isCompareActive, setIsCompareActive] = useState<boolean>(false);
  useEffect(() => {
    try {
      fetchProductData({ skus: compareProductsSku }).then((res) => {
        setCompareProductDetails(res.products);
      });
      setIsCompareActive(showProductCompare);
    } catch (error) {}
  }, [compareProductsSku]);

  return (
    <>
      {isCompareActive ? (
        <div className={`bg-white ${styles.stickyCompareContainer}`}>
          <Row
            justify="space-between"
            className={`${styles.compareElements}  container  py-13 `}
          >
            <Col md={{ span: 15 }} lg={{ span: 24 }} xl={{ span: 18 }}>
              <Row justify="start" gutter={18}>
                {compareProductDetails?.map((product: any) => (
                  <Col xl={{ span: 8 }} lg={{ span: 8 }} key={product.sku}>
                    <ProductItems product={product} />
                  </Col>
                ))}

                {compareProductDetails?.length < 3 ? (
                  <Col span={7}>
                    <AddProduct />
                  </Col>
                ) : null}
              </Row>
            </Col>
            <Col
              md={{ span: 9 }}
              lg={{ span: 24 }}
              xl={{ span: 5 }}
              className={styles.actionsContainer}
            >
              <CompareActions />
            </Col>
          </Row>
          <Row className={`${styles.mobileCompareElements}  container  py-13 `}>
            <Col span={24}>
              <Accordion
                className={`${styles.mobileCompareAccordion}`}
                header={
                  <Row justify="space-between" align="middle">
                    <Col>
                      <span className="font-bold-base lh-1 oxfordBlue">
                        Compare product
                      </span>
                    </Col>
                    <Col className={styles.mobileCompareHeaderWrapper}>
                      <Row
                        className={styles.mobileCompareHeader}
                        gutter={8}
                        justify="space-between"
                      >
                        {compareProductDetails?.map((product: any) => (
                          <Col key={product?.sku}>
                            <ProductItems product={product} />
                          </Col>
                        ))}

                        {compareProductDetails?.length < 3 ? (
                          <Col>
                            <AddProduct />
                          </Col>
                        ) : null}
                      </Row>
                    </Col>
                  </Row>
                }
                content={
                  <Row gutter={18}>
                    {compareProductDetails?.map((product: any) => (
                      <Col span={24} className="mt-6" key={product?.sku}>
                        <ProductItems product={product} viewType="mobileView" />
                      </Col>
                    ))}
                    {compareProductDetails?.length < 3 ? (
                      <Col className="mt-6" span={24}>
                        <AddProduct viewType="mobileView" />
                      </Col>
                    ) : null}
                  </Row>
                }
                closedIcon={
                  <Icon
                    name={"down"}
                    width={24}
                    height={24}
                    dir={""}
                    color="#02254a"
                  />
                }
                openedIcon={
                  <Icon
                    name={"down"}
                    width={24}
                    height={24}
                    dir={"top"}
                    color="#02254a"
                  />
                }
              />
            </Col>
            <Col span={24} className={styles.actionsContainer}>
              <CompareActions />
            </Col>
          </Row>
        </div>
      ) : null}
    </>
  );
};

export default CompareProduct;
