export class LoadScript {
  handlePlaceSelect: (
    updateQuery: any,
    autoComplete: any,
    setFieldValue: any,
    name: any,
    setIsAddressSelected: any
  ) => void;
  updateQuery: () => void;
  setFieldValue: () => void;
  setIsAddressSelected: () => void;
  autoCompleteRef: React.RefObject<HTMLInputElement>;
  name: string;
  types: string[];
  autoComplete: any;
  constructor(data: any) {
    this.handlePlaceSelect = data.handlePlaceSelect;
    this.updateQuery = data.updateQuery;
    this.autoCompleteRef = data.autoCompleteRef;
    this.setFieldValue = data.setFieldValue;
    this.name = data.name;
    this.types = data.types;
    this.setIsAddressSelected = data.setIsAddressSelected;
  }
  load = () => {
    this.autoComplete = new (window as any).google.maps.places.Autocomplete(
      this?.autoCompleteRef?.current as HTMLInputElement,
      { types: this?.types, componentRestrictions: { country: "us" } }
    );
    this.autoComplete.setFields([
      "address_components",
      "formatted_address",
      "geometry"
    ]);
    this.autoComplete.addListener("place_changed", () => {
      this.handlePlaceSelect(
        this.updateQuery,
        this.autoComplete,
        this.setFieldValue,
        this.name,
        this.setIsAddressSelected
      );
    });
  };
}
