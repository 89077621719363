import Button from "@components/shared/Button";
import styles from "./styles.module.scss";
import Field from "@components/shared/Fields";
import classnames from "classnames";
import { Col, Row } from "antd";
import { useRouter } from "next/router";
import { getCookie } from "src/server/utils/commonUtils";
import { useEffect, useState, useContext } from "react";
import { getText } from "@src/libs/resources";
import { AppContext } from "@src/libs/context";
import DataLoader from "~/src/components/shared/DataLoader";
import useClient from "~/src/server/utils/client";
import AccountInformation from "../../AccountInformation";

const AccountOverview = () => {
  const router = useRouter();
  const initialValues = {
    company_name: "",
    email: "",
    contact_name: "",
    phone_number: "",
  };
  const [accountOverviewData, setAccountOverviewData] =
    useState<any>(initialValues);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { customerStatus, current, setCurrent, setContent } = useContext<any>(AppContext);
  const client = useClient({});
  const getCustomerData = async () => {
    let data;
    try {
      setIsLoading(true);
      data = await client.basic.get("/api/ERP/customer/profile", {
        headers: { sessionid: getCookie("sessionid") },
      });
      setAccountOverviewData(data?.data);
    } catch (error: any) {
      if (error?.response?.status === 403) console.log(error);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getCustomerData();
  }, []);

  return (
    <div className={styles.accountOverview}>
      <div className={classnames(styles.titleSection, " p-12")}>
        <span className="text-oxfordBlue font-bold-h5">
          {getText()?.Account?.accountOverviewTitle}
        </span>
        {customerStatus?.profile_completed && current !== "haggleRewards" && (
          <Button
            theme={"link"}
            className={classnames(styles.updateButton, "font-bold-sm p-0")}
            onClick={() => {
              setContent(<AccountInformation update={true}/>);
              setCurrent("accountInfo");
            }}
          >
            {getText()?.Account?.updateButton}
          </Button>
        )}
      </div>
      <form>
        {!isLoading ? (
          current === "haggleRewards" ? (
            <Row>
              <Col className={`gutter-row mb-12`} span={24}>
                <Field
                  isReadOnly={true}
                  label="Anniversary"
                  placeholder="Anniversary"
                  value="06/10/2023"
                  className={classnames(styles.formInput, " mx-12")}
                />
              </Col>
              <Col className={`gutter-row mb-12`} span={24}>
                <Field
                  isReadOnly={true}
                  label="Tier"
                  placeholder="Tier"
                  value="jonathan_devis@email.com"
                  className={classnames(styles.formInput, " mx-12")}
                />
              </Col>
              <Col className={`gutter-row mb-12`} span={24}>
                <Field
                  isReadOnly={true}
                  label="Available rewards"
                  placeholder="Available rewards"
                  value="Jonathan devis"
                  className={classnames(styles.formInput, " mx-12")}
                />
              </Col>
              <Col className={`gutter-row mb-12`} span={24}>
                <Field
                  isReadOnly={true}
                  label="Pending rewards"
                  placeholder="Pending rewards"
                  value="1234-5678-7876"
                  className={classnames(styles.formInput, " mx-12")}
                />
              </Col>
              <Col className={`gutter-row mb-12`} span={24}>
                <Field
                  isReadOnly={true}
                  label="Pending rewards"
                  placeholder="Pending rewards"
                  value="1234-5678-7876"
                  className={classnames(styles.formInput, " mx-12")}
                />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col className={`gutter-row mb-12`} span={24}>
                <Field
                  isReadOnly={true}
                  label="Company Name"
                  placeholder="Company Name"
                  value={accountOverviewData?.company_name}
                  className={classnames(styles.formInput, " mx-12")}
                />
              </Col>
              <Col className={`gutter-row mb-12`} span={24}>
                <Field
                  isReadOnly={true}
                  label="Email"
                  placeholder="Email"
                  value={accountOverviewData?.email}
                  className={classnames(styles.formInput, " mx-12")}
                />
              </Col>
              <Col className={`gutter-row mb-12`} span={24}>
                <Field
                  isReadOnly={true}
                  label="Contact Name"
                  placeholder="Contact Name"
                  value={accountOverviewData?.contact_name}
                  className={classnames(styles.formInput, " mx-12")}
                />
              </Col>
              <Col className={`gutter-row mb-12`} span={24}>
                <Field
                  isReadOnly={true}
                  label="Phone"
                  placeholder="Phone number"
                  value={accountOverviewData?.phone_number}
                  className={classnames(styles.formInput, " mx-12")}
                />
              </Col>
            </Row>
          )
        ) : (
          <Row justify="center" className="py-20">
            <DataLoader />
          </Row>
        )}
      </form>
    </div>
  );
};
export default AccountOverview;
