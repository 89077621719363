import { useEffect, useContext } from "react";
import { AppContext } from "../libs/context";
import { useRouter } from "next/router";

function requireLogin(Component, path) {
  return (props) => {
    const router = useRouter();
    const { guestUser } = useContext(AppContext);

    useEffect(() => {
      if (guestUser) {
        router?.replace(
          path ||
            `/?epallet_login=true&source_path=${router?.asPath?.replace(
              "&",
              "__"
            )}`
        );
      }
    }, [guestUser, router]);

    return !guestUser ? <Component {...props} /> : null;
  };
}

export default requireLogin;
