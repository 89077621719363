export const customEncodeURIComponent = (str: string): string => {
  let encodedStr = encodeURIComponent(str);
  encodedStr = encodedStr
    .replace(/\//g, '~s~')
    .replace(/#/g, '~h~')
    .replace(/&/g, '~a~')
    .replace(/\$/g, '~d~')
    .replace(/@/g, '~t~')
    .replace(/\*/g, '~x~')
    .replace(/\(/g, '~o~')
    .replace(/\)/g, '~c~')
    .replace(/″/g, '~inch~');

  return encodedStr;
};

export const customDecodeURIComponent = (str: string): string => {
  let decodedStr = str
    .replace(/~s~/g, '/')
    .replace(/~h~/g, '#')
    .replace(/~a~/g, '&')
    .replace(/~d~/g, '$')
    .replace(/~t~/g, '@')
    .replace(/~x~/g, '*')
    .replace(/~o~/g, '(')
    .replace(/~c~/g, ')')
    .replace(/~inch~/g, '″');
    
  try {
    decodedStr = decodeURIComponent(decodedStr);
  } catch (e) {
    console.error("Error during decodeURIComponent", e);
  }

  return decodedStr;
};
