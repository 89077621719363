import React, { useContext } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ListItemsProps } from './types';
import { AppContext } from '~/src/libs/context';
import AccountInformation from '~/src/components/organsim/AccountPage/AccountInformation';
import ManageUsers from '~/src/components/organsim/AccountPage/ManageUsers';
import AllOrders from '~/src/components/organsim/AccountPage/MyOrders';
import Dashboard from '~/src/components/organsim/AccountPage/Dashboard';
import ShoppingList from '~/src/components/organsim/AccountPage/ShoppingList';

export const FooterListItem = ({ list }: ListItemsProps) => {
  const { setContent, setCurrent, customerStatus } = useContext<any>(AppContext);
  const router = useRouter();

  const handleLinkClick = (itemLink: string) => {
    const base = itemLink?.split('/')[1];
    const route = itemLink?.split('/')[2];

    if (base === 'account') {
      switch (route) {
        case 'accountInfo':
          setContent(<AccountInformation />);
          break;
        case 'manageUser':
          setContent(<ManageUsers />);
          break;
        case 'myOrders':
          setContent(<AllOrders />);
          break;
        case 'shoppingList':
          handleShoppingList();
          break;
        default:
          setContent(<Dashboard />);
          router.push('/account/dashboard');
      }
      setCurrent(route);
    }
  };

  const handleShoppingList = () => {
    if (!customerStatus.is_vendor && !customerStatus.profile_completed && customerStatus.is_authenticated) {
      router.push('/');
    } else {
      router.push('/account/shoppingList');
      setContent(<ShoppingList />);
    }
  };

  return (
    <ul>
      {list?.map((item, index) => (
        <li key={index} className="font-regular-sm lh-xl pe-8">
          <Link href={item?.linkUrl}>
            <a onClick={() => handleLinkClick(item?.linkUrl)}>{item?.linkText}</a>
          </Link>
        </li>
      ))}
    </ul>
  );
};
