import { useContext, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useRouter } from "next/router";
import { Row, Col, Dropdown, notification, Skeleton } from "antd";
import CustomButton from "@components/shared/Button";
import Icon from "@components/shared/Icon";
import axios from "axios";
import { getCookie } from "src/server/utils/commonUtils";
import Pagination from "@components/shared/pagination";
import DataLoader from "~/src/components/shared/DataLoader";
import ProductCard from "@src/components/shared/ProductCard";
import { getText } from "~/src/libs/resources";
import { AppContext } from "~/src/libs/context";
import SEO from "@components/shared/SEO";

const ViewedItems = () => {
  const router = useRouter();
  const [hitsNum, setHitsNum] = useState(8);
  const [pageNum, setPageNum] = useState(1);
  const [itemValue, setItemsVal] = useState(8);
  const [numOfHits, setNumOfHits] = useState();
  const [productData, setProductData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { defaultPostZip, customerStatus } = useContext<any>(AppContext);
  const itemsPerPage = [
    {
      label: "4",
      key: "4",
    },
    {
      label: "8",
      key: "8",
    },
    {
      label: "16",
      key: "16",
    },
    {
      label: "24",
      key: "24",
    },
  ];

  const fetchRecentlyViewed = async () => {
    setIsLoading(true);
    let response;
    try {
      let url = `/api/ERP/customer/viewedProduct?page=${pageNum}&page_size=${itemValue}`;
      if (defaultPostZip && (!customerStatus?.profile_completed || !customerStatus?.has_addresses)) {
        url += `&post_zip=${defaultPostZip}`;
      }
      response = await axios.get(url, {
        headers: { sessionId: getCookie("sessionid") },
      });
      setProductData(response?.data?.results);
      setNumOfHits(response?.data?.count);
      setHitsNum(response?.data?.page_size);
    } catch (error) {
      notification.error({
        message: getText().General.Messages.SomethingWrongTryAgain,
        duration: 4,
      });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    router.push(`/account/viewedItems`);
  }, []);
  useEffect(() => {
    fetchRecentlyViewed();
  }, [pageNum, itemValue, defaultPostZip]);
  const itemsPerPageHandler = (e: any) => {
    const currentIndex = (pageNum - 1) * Number(itemValue);
    setItemsVal(e?.key);
    setPageNum(currentIndex < e?.key ? 1 : Math.ceil(currentIndex / e?.key));
  };

  return (
    <>
      <SEO
        title={getText().Account?.SEO?.viewedItems?.title}
        description={getText().Account?.SEO?.viewedItems?.description}
      />
      <div className={`${styles.viewedItemsWrapper} py-20 text-oxfordBlue`}>
        <Row
          className={`${styles.items__header} pb-12`}
          gutter={[0, 16]}
          justify="space-between"
        >
          <Col className="font-bold-h2">{getText()?.Account?.viewedItems}</Col>
          {isLoading ? (
            <Skeleton.Input active={true} />
          ) : (
            <Col>
              <Row align="middle" gutter={[0, 16]}>
                <p className="mb-0 pe-8 ps-4">
                  {getText()?.Account?.itemsPerPage}
                </p>
                <Dropdown
                  menu={{
                    items: itemsPerPage,
                    onClick: itemsPerPageHandler,
                  }}
                >
                  <CustomButton
                    theme={"secondary"}
                    className={`${styles.btn} px-2`}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className={styles.sortBox}>{itemValue}</div>
                      <Icon name="down" width={14} height={14} />
                    </div>
                  </CustomButton>
                </Dropdown>
              </Row>
            </Col>
          )}
        </Row>

        {productData?.length > 0 ? (
          <div>
            {isLoading ? (
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  height: "500px",
                }}
                className="text-oxfordBlue font-black-h1 py-40 my-40"
              >
                <DataLoader />
              </div>
            ) : (
              <>
                <Row
                  justify="start"
                  className={`${styles.cardsWrapper} m-0`}
                >
                  {productData?.map((product: any, index: number) => (
                    <Col
                      span={24}
                      xl={{ span: 12 }}
                      lg={{ span: 24 }}
                      sm={{ span: 12 }}
                      xs={{ span: 24 }}
                      key={product?.id}
                      className={styles.productCardWrapper}
                    >
                      <ProductCard
                        id={index}
                        key={product?.slug}
                        featured={false}
                        product={product}
                      />
                    </Col>
                  ))}
                </Row>
                <Row justify="center" className="pt-20">
                  <Pagination
                    total={numOfHits}
                    pageSize={hitsNum}
                    current={pageNum}
                    setCurrent={setPageNum}
                  />
                </Row>
              </>
            )}
          </div>
        ) : (
          <div
            style={{ display: "grid", placeItems: "center", height: "500px" }}
            className="text-oxfordBlue font-black-h1 py-40 my-40"
          >
            {isLoading ? <DataLoader /> : "There are no viewed items yet"}
          </div>
        )}
      </div>
    </>
  );
};

export default ViewedItems;
