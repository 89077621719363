import React, { useContext, useEffect } from "react";
import { AppContext } from "~/src/libs/context";
import { DEFAULT_ZIP_CODE } from "@src/constants";
import useZipCode from "~/src/utilities/hooks/useZipCode";

const DefaultZipCode = () => {
  const { guestUser, customerStatus, setDefaultPostZip, googlePlacesScript } =
    useContext<any>(AppContext);
  const { changeZipCode } = useZipCode();
  useEffect(() => {
    if (
      (!guestUser && customerStatus?.is_vendor) ||
      (!guestUser &&
        customerStatus?.profile_completed &&
        ((customerStatus?.permissions?.includes("VIEW_SHIPPING_ADDRESSES") &&
          !customerStatus?.has_addresses) ||
          !customerStatus?.permissions?.includes("VIEW_SHIPPING_ADDRESSES")))
    )
      if (googlePlacesScript && navigator?.geolocation) {
        let isZipcodeFound = false;
        navigator?.geolocation?.getCurrentPosition(
          function (position) {
            const lat = position?.coords?.latitude;
            const long = position?.coords?.longitude;
            const point = new google.maps.LatLng(lat, long);
            new google.maps.Geocoder()?.geocode(
              { latLng: point } as any,
              function (res) {
                if (res) {
                  for (let component of res[0]?.address_components) {
                    let componentType = component?.types[0];
                    if (componentType === "postal_code") {
                      changeZipCode(component?.short_name, true);
                      isZipcodeFound = true;
                      break;
                    }
                    isZipcodeFound = false;
                  }
                  if (!isZipcodeFound) setDefaultPostZip(DEFAULT_ZIP_CODE);
                } else setDefaultPostZip(DEFAULT_ZIP_CODE);
              }
            );
          },
          function () {
            setDefaultPostZip(DEFAULT_ZIP_CODE);
          }
        );
      } else setDefaultPostZip(DEFAULT_ZIP_CODE);
  }, [googlePlacesScript, customerStatus]);

  return <div></div>;
};

export default DefaultZipCode;
