export const ePalletTheme = {
  token: {
    colorPrimary: "#076DDC",
    fontFamily: "Inter, sans-serif",
    colorSuccess: "#7edc07",
    colorWarning: "#fbbc05",
    colorError: "#f26767",
    fontSize: 16,
    colorTextDisabled: "#898989",
    colorSuccessBorder: "#7EDC07",
    colorErrorBorder: "#F26767",
    colorLink: "#02254A",
    colorTextHeading: "#000000",
    colorLinkHover: "white",
    colorText: "#02254a",
    colorBorder: "#404b6033",
    colorTextSecondary: "#e3c8b5",
  },
};

export const _btnTheme = {
  token: {
    lineHeight: 1.125,
    fontSize: 16,
    colorPrimaryBg: "#076ddc",
    colorPrimaryHover: "#fff",
    colorBgContainerDisabled: "#e7e7e7",
    colorTextDisabled: "#898989",
    borderRadius: 0,
    linkDecoration: "underline",
    linkHoverDecoration: "none",
  },
};
export const _checkBoxTheme = {
  token: {
    borderRadius: 0,
    colorBorder: "#02254A",
  },
};

export const _accordionTheme = {
  token: {
    fontWeightStrong: 700,
    fontSize: 20,
    colorTextHeading: "#02254a",
    borderRadius: 0,
    colorFillAlter: "#fff",
  },
};
