import React, { memo, useContext } from "react";
import styles from "../style.module.scss";
import { getText } from "~/src/libs/resources";
import Icon from "@components/shared/Icon";
import { Image, Skeleton } from "antd";
import DataLoader from "../../DataLoader";
import Link from "next/link";
import isOutOfStock from "~/src/utilities/hooks/isOutOfStock";
import RequestForm from "../../requestForm";
import { usePriceFormat } from "@src/utilities/hooks/usePriceFormat";
import CustomButtons from "@components/shared/Button";
import SizeComponent from "./SizeComponent";
import { useProductCardLogic } from "../hooks/useProductCard";
import { useDispatch } from "react-redux";
import CasesPerPallet from "./CasesPerPallet";
import { AppContext } from "~/src/libs/context";

const ProductCard = ({
  isVendor,
  addToCartStatus,
  addItemToPallet,
  buildMixedPallet,
  addItemToCart,
  compareProductHandler,
  viewDetailsHandler,
  product,
  router,
  id,
  titleOfList,
  validGuestUser,
  validCustomerStatus,
  inCart,
  disabled,
  vendorItemTi
}: any) => {
  const dispatch = useDispatch();
  const {
    selectProductHandler,
    setIsModalOpen,
    showModal,
    setShowVerifyModal,
    setViewDetails,
    isModalOpen,
    placeholderImage,
    isCustomerDataCompleted,
    setSku,
  } = useProductCardLogic({
    validGuestUser,
    validCustomerStatus,
    id,
    product,
    titleOfList,
    isVendor,
  });

  const { completeProfileLink } = useContext<any>(AppContext);
  const isLimitedInventory =
    !product?.delivered_price &&
    !validGuestUser &&
    isCustomerDataCompleted &&
    product?.for_mixed_pallet &&
    !product?.is_available &&
    !product?.is_prototype;
  return (
    <>
      {isVendor ? (
        <>
          <div className={`${styles.cardInfoWithImg} ${styles.VenderProduct}`}>
            <div className={styles.imageWrapper}>
              <Image
                draggable="false"
                src={product?.plp_image_url || product?.thumbnail}
                alt="card image"
                fallback={placeholderImage}
                preview={false}
                onClick={() => {
                  dispatch(setSku(product?.id));
                  dispatch(setViewDetails(true));
                  selectProductHandler("select_item");
                }}
              />
            </div>
            <div className={`${styles.cardInfo} ${styles.vendorCardInfo}`}>
              <div className={styles.category}>
                <Link
                  href={`/product-list/brands/${
                    product?.brand_name || product?.brand?.name
                  }`}
                >
                  <a>{product?.brand_name || product?.brand?.name}</a>
                </Link>
              </div>
              <div
                onClick={() => {
                  dispatch(setSku(product?.id));
                  dispatch(setViewDetails(true));
                  selectProductHandler("select_item");
                }}
                className={styles.title}
              >
                {product?.name}
              </div>
            </div>
          </div>
          <div>
            <>
              <div className={styles.priceWrapper}>
                <div className={styles.itemWrapper}>
                  <span>{getText()?.Cart?.Card?.priceCase}</span>
                  <span className={styles.value}>
                    {validGuestUser || !isCustomerDataCompleted ? (
                      <Icon
                        name="lock"
                        color="#898989"
                        width={16}
                        height={16}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                  <span className={styles.value}>
                    {!validGuestUser &&
                    isCustomerDataCompleted &&
                    product?.delivered_case_price === undefined ? (
                      <DataLoader className="text-center" />
                    ) : (
                      !validGuestUser &&
                      isCustomerDataCompleted &&
                      `${
                        product?.delivered_case_price !== undefined
                          ? `$${usePriceFormat(product?.delivered_case_price)}`
                          : ""
                      }`
                    )}
                  </span>
                </div>
                <span className={styles.divider} />
                <CasesPerPallet
                  isVendor={isVendor}
                  casesPerPallet={vendorItemTi}
                />
                <span className={styles.divider} />
                <div className={styles.itemWrapper}>
                  <span className={styles.packSize}>
                    {getText()?.PDP?.productDetails?.packSize}
                  </span>

                  <span className={styles.value}>{product?.pack_size}</span>
                </div>
              </div>
              <SizeComponent
                isCustomerDataCompleted={isCustomerDataCompleted}
                isVendor={isVendor}
                validGuestUser={validGuestUser}
                item={product}
                time={product?.lead_time_days}
              />
            </>
          </div>
        </>
      ) : (
        <>
          <div className={`${styles.cardInfoWithImg} ${styles.VenderProduct}`}>
              <Link href={`/product/${product?.slug}`} passHref>
                <a onClick={() => selectProductHandler("select_item")}>
                <div className={styles.imageWrapper}>
                  <Image
                    draggable="false"
                    src={product?.plp_image_url || product?.thumbnail}
                    alt="card image"
                    fallback={placeholderImage}
                    preview={false}
                  />
                    </div>
                </a>
              </Link>

            <div className={`${styles.cardInfo}`}>
              <div className={styles.category}>
                <Link
                  href={`/product-list/brands/${
                    product?.brand_name || product?.brand?.name
                  }`}
                  passHref
                >
                  <a>{product?.brand_name || product?.brand?.name}</a>
                </Link>
              </div>
              <div className={styles.title}>
                <Link href={`/product/${product?.slug}`} passHref>
                  <a onClick={() => selectProductHandler("select_item")}>
                    {product?.name}
                  </a>
                </Link>
              </div>
            </div>
          </div>

          {product?.delivered_price === undefined ||
          !isOutOfStock(product?.is_available && !product?.is_prototype) ||
          validGuestUser ||
          !isCustomerDataCompleted ? (
            <>
              <div className={styles.priceWrapper}>
                <div className={styles.itemWrapper}>
                  <span className={styles.deliveredPrice}>
                    {getText()?.Cart?.deliveredPrice}
                  </span>
                  {(validGuestUser || !isCustomerDataCompleted) && (
                    <span className={styles.value}>
                      <Icon
                        name="lock"
                        color="#898989"
                        width={16}
                        height={16}
                      />
                    </span>
                  )}
                  {!validGuestUser && isCustomerDataCompleted && (
                    <span className={styles.value}>
                      {product?.delivered_price === undefined ? (
                        <Skeleton.Button active={true} size="default" />
                      ) : (
                        `${
                          product?.delivered_price !== undefined
                            ? `$${usePriceFormat(product?.delivered_price)}`
                            : ""
                        }`
                      )}
                    </span>
                  )}
                </div>
                <span className={styles.divider} />
                <CasesPerPallet
                  isVendor={isVendor}
                  casesPerPallet={product?.case_per_pallet}
                />
                <span className={styles.divider} />
                <div className={styles.itemWrapper}>
                  <span className={styles.packSize}>
                    {getText()?.PDP?.productDetails?.packSize}
                  </span>
                  <span className={styles.value}> {product?.pack_size}</span>
                </div>
                <span
                  className={`${styles.divider} ${styles.itemWrapperForMobile}`}
                />
                <div
                  className={`${styles.itemWrapper} ${styles.itemWrapperForMobile}`}
                >
                  <SizeComponent
                    isVendor={isVendor}
                    validGuestUser={validGuestUser}
                    isCustomerDataCompleted={isCustomerDataCompleted}
                    item={product}
                    time={product?.lead_time_days}
                  />
                </div>
              </div>

              <SizeComponent
                isVendor={isVendor}
                validGuestUser={validGuestUser}
                isCustomerDataCompleted={isCustomerDataCompleted}
                item={product}
                time={product?.lead_time_days}
              />
            </>
          ) : (
            <span
              className={`${styles.outOfStock} d-block text-darkGray py-10 font-medium-h6`}
            >
              {product?.for_mixed_pallet
                ? product?.is_prototype
                  ? getText()?.PDP?.prototypeProduct
                  : getText()?.PDP?.palletOutOfStock
                : product?.is_prototype
                ? getText()?.PDP?.prototypeProduct
                : getText()?.PDP?.outOfStock}
            </span>
          )}
        </>
      )}
      <RequestForm setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />
      {isVendor ? (
        <div className={styles.actionsContainer}>
          {validGuestUser &&
            (isVendor ? (
              <p className={`${styles.guestLabel} mb-0`}>
                {getText().General.Messages.signInOrSignUp}
              </p>
            ) : (
              <p className={`${styles.guestLabel} mb-0`}>
                {getText()?.PDP?.messages?.signInOrSignUpToSeeDeliveredPrices}
              </p>
            ))}
          <CustomButtons
            theme="secondary"
            className={`${styles.actionBtn} w-100 py-7 h-auto`}
            onClick={() => {
              dispatch(setSku(product?.id));
              dispatch(setViewDetails(true));
              selectProductHandler("select_item");
            }}
          >
            {getText().Account?.viewDetails}
          </CustomButtons>
          {validGuestUser ? (
            <>
              <CustomButtons
                theme="primary"
                hasIcon={true}
                onClick={() => router?.push("/?epallet_signup=true")}
                className={`${styles.actionBtn} w-100 font-bold-base ms-0 h-auto py-7`}
              >
                {getText().SignUp.signUp}
              </CustomButtons>
            </>
          ) : isCustomerDataCompleted ? (
            <>
              <CustomButtons
                theme="primary"
                hasIcon={true}
                className={`${styles.actionBtn} w-100 font-bold-base ms-0 h-auto py-7`}
                icon={<Icon name="my-order" color="white" />}
                onClick={addItemToPallet}
                disabled={disabled}
              >
                <span className="ps-4">{getText()?.PDP?.addToPallet}</span>
              </CustomButtons>
            </>
          ) : validCustomerStatus?.profile_completed === false &&
            !validCustomerStatus?.is_vendor ? (
            <Link href={completeProfileLink}>
              <a>
                <CustomButtons className={styles.customButton} theme="primary">
                  {getText().General.Messages.CompleteProfile}
                </CustomButtons>
              </a>
            </Link>
          ) : (
            validCustomerStatus?.email_verified === false &&
            !validCustomerStatus?.is_vendor && (
              <CustomButtons
                className={styles.customButton}
                theme="primary"
                onClick={() => setShowVerifyModal(true)}
              >
                {getText()?.General?.Messages?.VerifyEmail}
              </CustomButtons>
            )
          )}
        </div>
      ) : (
        <div className={styles.actionsContainer}>
          {validGuestUser &&
            (isVendor ? (
              <p className={`${styles.guestLabel} mb-0`}>
                {getText().General.Messages.signInOrSignUp}
              </p>
            ) : (
              <p className={`${styles.guestLabel} mb-0`}>
                {getText()?.PDP?.messages?.signInOrSignUpToSeeDeliveredPrices}
              </p>
            ))}
          <div className={styles.btnStyle}>
            <CustomButtons
              theme="secondary"
              onClick={() => {
                viewDetailsHandler();
                selectProductHandler("select_item");
              }}
            >
              {getText().Account?.viewDetails}
            </CustomButtons>

            {validGuestUser && (
              <CustomButtons
                onClick={() => {
                  router.push("/?epallet_signup=true");
                }}
                theme="primary"
                className={styles.btnStyle}
              >
                {getText().General.SignUp}
              </CustomButtons>
            )}
            {!validGuestUser &&
              isCustomerDataCompleted &&
              !isOutOfStock(product?.is_available) &&
              !product?.is_prototype && (
                <>
                  {inCart ? (
                    <CustomButtons
                      theme="primary"
                      className={styles.btnStyle}
                      hasIcon={true}
                      icon={
                        <Icon
                          name="check"
                          width={20}
                          height={20}
                          color="white"
                        />
                      }
                      onClick={() => {
                        router.push("/cart");
                      }}
                    >
                      <span className="ms-5">{getText()?.PDP?.inCart}</span>
                    </CustomButtons>
                  ) : (
                    <CustomButtons
                      theme="primary"
                      className={styles.btnStyle}
                      hasIcon={true}
                      icon={
                        <Icon
                          name="cart"
                          width={20}
                          height={20}
                          color="white"
                        />
                      }
                      onClick={() => addItemToCart(product)}
                      loading={addToCartStatus}
                    >
                      <span className="ms-5">{getText()?.PDP?.addToCart}</span>
                    </CustomButtons>
                  )}
                </>
              )}
            {!validGuestUser &&
              isCustomerDataCompleted &&
              ((!product?.for_mixed_pallet &&
                !product?.is_available &&
                product?.delivered_price !== undefined &&
                product?.available_case_qty == null) ||
                product?.is_prototype) && (
                <CustomButtons
                  className={`font-bold-base lh-1`}
                  text={getText().General.RequestProduct}
                  theme="primary"
                  onClick={showModal}
                />
              )}
            {isLimitedInventory && (
              <div className={styles.compareWrapper}>
                <CustomButtons
                  className={styles.compareBtn}
                  onClick={(e: any) =>
                    compareProductHandler(e, product?.id, validGuestUser)
                  }
                  theme="link"
                  tabIndex={-1}
                >
                  {getText()?.PDP?.compare}
                </CustomButtons>
              </div>
            )}
          </div>
          {validCustomerStatus?.profile_completed === false &&
            !validCustomerStatus?.is_vendor && (
              <Link href={completeProfileLink}>
                <a style={{ textAlign: "center" }}>
                  <CustomButtons
                    className={styles.customButton}
                    theme="primary"
                  >
                    {getText().General.Messages.CompleteProfile}
                  </CustomButtons>
                </a>
              </Link>
            )}
          {validCustomerStatus?.profile_completed === true &&
            validCustomerStatus?.email_verified === false &&
            !validCustomerStatus?.is_vendor && (
              <CustomButtons
                className={styles.customButton}
                theme="primary"
                onClick={() => setShowVerifyModal(true)}
              >
                {getText()?.General?.Messages?.VerifyEmail}
              </CustomButtons>
            )}

          {!validGuestUser &&
            isCustomerDataCompleted &&
            product?.for_mixed_pallet &&
            !isOutOfStock(product?.is_available) &&
            !product?.is_prototype && (
              <CustomButtons
                theme="secondary"
                className={styles.addToPallet}
                hasIcon={true}
                icon={<Icon name="my-order" width={20} height={20} />}
                onClick={buildMixedPallet}
              >
                {getText()?.PDP?.buildMixedPallet}
              </CustomButtons>
            )}

          {!isLimitedInventory &&
            !validGuestUser &&
            isCustomerDataCompleted && (
              <CustomButtons
                className={styles.compareBtn}
                onClick={(e: any) =>
                  compareProductHandler(e, product?.id, validGuestUser)
                }
                theme="link"
                tabIndex={-1}
              >
                {getText()?.PDP?.compare}
              </CustomButtons>
            )}
          {isLimitedInventory && (
            <CustomButtons
              theme="secondary"
              className={styles.addToPallet}
              hasIcon={true}
              icon={<Icon name="my-order" width={20} height={20} />}
              onClick={buildMixedPallet}
            >
              {getText()?.PDP?.buildMixedPallet}
            </CustomButtons>
          )}
        </div>
      )}
    </>
  );
};

export default memo(ProductCard);
