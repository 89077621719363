export const API_URL = {
  PALLET_ADD_ITEM: () => "/api/ERP/customer/mixedPallet",
  PALLET_CHANGE_NAME: (id: string) =>
    `/api/ERP/customer/mixedPallet/${id}/changeName`,
  PALLET_GET_BY_ID: (id: string) => `/api/ERP/customer/mixedPallet/${id}`,
  PALLET_MIXED_PRODUCTS: () => `/api/ERP/customer/mixedPalletProducts`,
  PALLET_SET_PRODUCT_QUANTITY: (id: string) =>
    `/api/ERP/customer/mixedPallet/${id}/setProductQuantity`,
  SET_PRODUCT_QUANTITY: () => "/api/ERP/cart/setProductQuantity",
  GET_CART: () => "/api/ERP/cart",
};
