import React, { useState } from "react";
import { Drawer, Space, Row, Col, InputNumber } from "antd";
import Button from "@components/shared/Button";
import Icon from "@components/shared/Icon";
import { Collapse } from "antd";
import styles from "./styles.module.scss";
import classnames from "classnames";
import { useRouter } from "next/router";
import { FILTERS_ORDERS } from "@src/constants";
import { getText } from "~/src/libs/resources";
const { Panel } = Collapse;
const FiltersMenu = ({
  onClose,
  openMenu,
  selectedFilters,
  setSelectedFilters,
  getAllOrders,
  setOrderStatus,
  setCurrentPage,
  setOrderMonth,
  setDateSort,
  searchValue,maxPrice, setMaxPrice,minPrice, setMinPrice
}: any) => {
  const router = useRouter();
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  const allTagRemoved = () => {
    getAllOrders();
    const { date, orderStatus, ...query } = router?.query;
    router.push(
      {
        query: query
      },
      undefined,
      { shallow: false }
    );
  };
  const handleApplyFilterClicked = () => {
    if (minPrice > maxPrice) {
      setShowErrorMessage(true);
      return;
    } else {
      setCurrentPage(1);
      let status: any;
      let month: any;
      let sortDate: any;
      if (minPrice || maxPrice) {
        selectedFilters.push(
          { header: "Min Amount", item: minPrice < 0 ? 0 : minPrice},
          { header: "Max Amount", item: maxPrice < 0 ? 0 :maxPrice }
        );
      }
      if (selectedFilters?.length === 0) {
        allTagRemoved();
      } else {
        selectedFilters?.map((item: any) => {
          if (item?.header === "Order status") {
            setOrderStatus(item?.item);
            status = item?.item;
          }
          if (item?.header === "Month") {
            setOrderMonth(item?.item);
            month = item?.item;
          }
          if (item?.header === "Sort date") {
            if (item?.item === "New to old") {
              setDateSort("-");
              sortDate = "-";
            } else if (item?.item === "Old to new") {
              setDateSort("+");
              sortDate = "+";
            }
          }
          if (item?.header === "Min Amount") {
            item?.item < 0 ? setMinPrice(0) : setMinPrice(item?.item);
          }
          if (item?.header === "Max Amount") {
            item?.item < 0 ? setMaxPrice(0) : setMaxPrice(item?.item);
          }
        });
        selectedFilters.forEach((item: any) => {
          if (item?.header === "Order status") {
          } else if (item?.header === "Month") {
          }
        });
        let query = router?.query;
        if (!status && !month) {
          const { date, orderStatus, ...queryFilters } = router?.query;
          query = queryFilters;
        } else if (!status) {
          const { orderStatus, ...queryFilters } = router?.query;
          query = queryFilters;
        } else if (!month) {
          const { date, ...queryFilters } = router?.query;
          query = queryFilters;
        }
        router.push(
          {
            query:
              status && (month || month === 0)
                ? { ...query, orderStatus: status, date: month }
                : (month || month === 0) && !status
                ? { ...query, date: month }
                : { ...query, orderStatus: status }
          },
          undefined,
          { shallow: false }
        );
        getAllOrders(
          status,
          month,
          searchValue,
          1,
          sortDate,
          minPrice < 0 ? 0 : minPrice,
          maxPrice < 0 ? 0 : maxPrice
        );
      }

      onClose();
    }
  };
  const selectedTagsHandler = (
    event: React.MouseEvent<HTMLButtonElement>,
    header: string,
    item: string
  ) => {
    const data = selectedFilters?.find(
      (i: any) => i?.item !== item && i?.header === header
    );
    const sameTag = selectedFilters?.find(
      (i: any) => i?.item === item && i?.header === header
    );
    const removeTag = selectedFilters?.filter((i: any) => i !== sameTag);
    const removedItem = selectedFilters?.filter((i: any) => i !== data);
    removedItem?.push({ header: header, item: item });
    sameTag
      ? setSelectedFilters(removeTag)
      : data
      ? setSelectedFilters(removedItem)
      : setSelectedFilters([
          ...selectedFilters,
          { header: header, item: item }
        ]);
  };
  const setInputValue = (key: any, e: any) => {
    setShowErrorMessage(false);
    if (key === "minPrice") {
      setMinPrice(e.target.value);
    } else {
      setMaxPrice(e.target.value);
    }
  };
  return (
    <>
      <Drawer
        className={`${styles.filtersMenu} bg-white pt-30 pb-20 px-4 text-oxfordBlue`}
        title="Filters"
        placement="right"
        closable={false}
        onClose={onClose}
        open={openMenu}
        width={550}
        contentWrapperStyle={{ maxWidth: "100%" }}
        footer={
          <Button
            theme="primary"
            className={classnames(
              styles.filterBttn,
              "font-semibold-base py-10 w-100 my-0"
            )}
            disabled={showErrorMessage ? true : false}
            onClick={handleApplyFilterClicked}
          >
            {getText()?.Account?.applyFilterButton}
          </Button>
        }
        extra={
          <Space>
            <Button
              theme="primary"
              className={classnames(styles.closeBttn, "bg-white")}
              icon={<Icon name="mobileCross" width={30} height={30} />}
              onClick={onClose}
            />
          </Space>
        }
      >
        <Collapse
          bordered={false}
          className={classnames(
            styles.drawerBody,
            "text-error font-bold-base lh-1 "
          )}
          expandIconPosition="end"
          expandIcon={({ isActive }) => (
            <Icon
              name="down"
              width={16}
              height={16}
              dir={isActive ? "top" : ""}
              color="#02254a"
            />
          )}
        >
          {FILTERS_ORDERS?.map((item: any, index: number) => (
            <Panel
              key={index}
              header={`${item?.header?.label} (${item?.items?.length})`}
              className="py-8"
              style={{ borderBottomColor: "#076DDC" }}
            >
              {item?.header?.label === "Amount" ? (
                <Row className={styles.pricesWrapper}>
                  {showErrorMessage && (
                    <span className="font-semibold-sm text-danger p-0 mb-6">
                      {getText()?.Account?.maxPriceValidateMessage}
                    </span>
                  )}
                  {item?.items?.map((val: any, index: number) => (
                    <Col span={9} key={index}>
                      <span className="font-semibold-xs">{val?.label}</span>

                      <InputNumber
                        name={val?.key}
                        addonAfter="$"
                        placeholder={val?.label}
                        className={`${
                          val?.label === "Max Amount" && showErrorMessage
                            ? styles.dangerBorder
                            : ""
                        } mt-4`}
                        value={val?.key === "minPrice" ? minPrice : maxPrice}
                        onChange={() => setInputValue(val?.key, event)}
                      />
                    </Col>
                  ))}
                </Row>
              ) : (
                <Row>
                  {item?.items?.map((val: any, index: number) => (
                    <div key={index}>
                      <Button
                        name={val?.label}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                          selectedTagsHandler(
                            event,
                            item?.header?.key,
                            val?.key
                          )
                        }
                        theme={
                          selectedFilters?.find(
                            (i: any) =>
                              i?.item == val?.key &&
                              i?.header === item?.header?.key
                          )
                            ? "primary"
                            : "primary-inverse"
                        }
                        className={classnames(
                          styles.iconBttnWrapper,
                          "text-oxfordBlue font-bold-base lh-1 me-4 mb-6 ps-3"
                        )}
                        icon={
                          val?.icon && (
                            <Icon name={val?.icon} width={15} height={15} />
                          )
                        }
                      >
                        <span className="font-bold-xs text-oxfordBlue ms-2">
                          {val?.label}
                        </span>
                      </Button>
                    </div>
                  ))}
                </Row>
              )}
            </Panel>
          ))}
        </Collapse>
      </Drawer>
    </>
  );
};
export default FiltersMenu;
