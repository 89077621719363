import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import SearchIcon from "@assets/icons/search.svg";
import Field from '~/src/components/shared/Fields';
import styles from "./styles.module.scss";

interface SearchFormProps {
  onSearch: (query: string) => void;
  setCheckedList:any;
  setCheckAll:(query: boolean) => void;
}

const SearchForm: React.FC<SearchFormProps> = ({ onSearch, setCheckedList, setCheckAll }) => {
  const [searchQuery, setSearchQuery] = useState<string>('');

  const handleSearch = () => {
    onSearch(searchQuery);
  };

  useEffect(()=>{
        handleSearch()
        if(searchQuery){
          setCheckedList([]);
          setCheckAll(false);
        }
  },[searchQuery])

  return (
    <Form layout="inline" className={styles.formWrapper}>
      <Form.Item>
        <Field
          placeholder="Search this list"
          value={searchQuery}
          onChange={(e:any) => setSearchQuery(e.target.value)}
          enterKeyHint="search"
          Icon={SearchIcon}
          hasIcon={true}
          prefix={true}
          className={styles.searchFormInput}
        />
      </Form.Item>
    </Form>
  );
};

export default SearchForm;
