import styles from "./styles.module.scss";
import Pagination from "@components/shared/pagination";
import { Table, Row, Col } from "antd";
import CustomButton from "@components/shared/Button";
import DataLoader from "~/src/components/shared/DataLoader";
import { useContext } from "react";
import { AppContext } from "~/src/libs/context";
import { getText } from "~/src/libs/resources";
const OrdersTable = ({
  setViewDetails,
  dataSource,
  setOrderDetails,
  setCurrentPage,
  currentPage,
  pageSize,
  orderNum,
  myOrdersLoaded
}: any) => {
  const fetchOrderData = (record: []) => {
    setViewDetails(false);
    setOrderDetails(record);
  };
  const { setOrderID } = useContext<any>(AppContext);
  return (
    <>
      {myOrdersLoaded ? (
        <div className={`${styles.myOrdersWrapper} pt-10`}>
          <Table
            pagination={false}
            dataSource={dataSource}
            className={styles.myOrdersTable}
            scroll={{ x: 400 }}
          >
            <Table.Column
              title="Order ID"
              dataIndex="orderID"
              key="orderID"
              className= "font-bold-sm"
            />
            <Table.Column
              title="Placed by"
              dataIndex="placedBy"
              key="placedBy"
              className="font-regular-sm"
            />
            <Table.Column
              title="Order Date"
              dataIndex="orderDate"
              key="orderDate"
              className={`${styles.orderDate} font-regular-sm`}
            />
            <Table.Column
              title="Quantity"
              dataIndex="quantity"
              key="quantity"
              className={`${styles.quantity} font-regular-sm`}
            />
            <Table.Column
              title="Amount"
              dataIndex="amount"
              key="amount"
              className="font-regular-sm"
            />

            <Table.Column
              title="Order Status"
              key="status"
              dataIndex="status"
              className="font-bold-xs"
              render={(_: any, record: any) => (
                <Row justify="space-between" gutter={[12, 12]} wrap={false}>
                  <Col>
                    <p
                      style={{ textAlign: "center" }}
                      className={`${styles.completed} font-semibold-xs`}
                    >
                      {record?.status}
                    </p>
                  </Col>
                  <Col>
                    <CustomButton
                      theme="primary"
                      onClick={() => {
                        fetchOrderData(record);
                        setOrderID(record?.orderID);
                      }}
                      className={`${styles.viewDetails} font-semibold-xs bg-oxfordBlue`}
                    >
                      {getText()?.Account?.viewDetails}
                    </CustomButton>
                  </Col>
                </Row>
              )}
            />
          </Table>
          <div>
            <Pagination
              total={orderNum}
              pageSize={pageSize}
              current={currentPage}
              setCurrent={setCurrentPage}
            />
          </div>
        </div>
      ) : (
        <div
          style={{ display: "grid", placeItems: "center", height: "500px" }}
          className="text-oxfordBlue font-black-h1 py-40 my-40"
        >
          <DataLoader />
        </div>
      )}
    </>
  );
};

export default OrdersTable;
