import React, { useState, useEffect, useRef, useCallback } from "react";
import { useRouter } from "next/router";
import { LoadScript } from "./LoadScript";
import styles from "./styles.module.scss";
interface AutoCompleteInputProps {
  placeHolder: string;
  name: string;
  className: string;
  setIsAddressSelected?: (event: boolean) => void;
  onChange?: (address: string) => void;
  defaultValue?: string;
  setFieldValueCallback?: (event: any) => void;
  extraAction: (values: any) => void;
  types: string[];
  isBilling?: boolean;
  forceNoError?: boolean;
  disabled?: any;
  validInput?: boolean;
  onFocus?: () => void;
  smallFontSize?: boolean;
}

const handleScriptLoad = (
  updateQuery: any,
  autoCompleteRef: any,
  setFieldValue: any,
  name: any,
  types: any,
  setIsAddressSelected: any
) => {
  const LoadTheGoogleScript = new LoadScript({
    updateQuery,
    autoCompleteRef,
    setFieldValue,
    name,
    types,
    setIsAddressSelected,
    handlePlaceSelect
  });
  LoadTheGoogleScript?.load();
};

const handlePlaceSelect = async (
  updateQuery: any,
  autoComplete: any,
  extraAction: any,
  name: any,
  setIsAddressSelected: any
) => {
  const addressObject = autoComplete?.getPlace();
  let address1 = "";
  let zipCode = "";
  let country = "";
  let state = "";
  let city = "";

  if (addressObject && addressObject?.address_components && typeof addressObject?.address_components[Symbol?.iterator] === 'function') {
    for (const component of addressObject?.address_components) {
      const componentType = component?.types[0];

      switch (componentType) {
        case "street_number": {
          address1 = `${component?.long_name} ${address1}`;
          break;
        }

        case "route": {
          address1 += component?.short_name;
          break;
        }

        case "postal_code": {
          zipCode = `${component?.long_name}${zipCode}`;
          break;
        }

        case "postal_code_suffix": {
          zipCode = zipCode || component?.long_name;
          break;
        }
        case "locality":
          city = component?.long_name;
          break;
        case "sublocality_level_1":
          city = component?.long_name;
          break;
        case "neighborhood":
          city = component.long_name;
          break;
        case "administrative_area_level_1": {
          state = component?.short_name;
          break;
        }
        case "country":
          country = component?.short_name;
          break;
      }
    }

    extraAction({
      lat: addressObject?.geometry?.location?.lat(),
      lng: addressObject?.geometry?.location?.lng(),
      address1,
      zipCode,
      country,
      state,
      city,
      formatted_address: addressObject?.formatted_address
    });
    const query = addressObject?.formatted_address;
    updateQuery(query || address1);
    setIsAddressSelected && setIsAddressSelected(true);
  }
};

const SearchLocationInput = ({
  placeHolder,
  name,
  className,
  setIsAddressSelected,
  defaultValue = "",
  extraAction,
  setFieldValueCallback,
  types = ["(cities)"],
  isBilling,
  disabled = false,
  onChange,
  validInput,
  onFocus,
  smallFontSize = false
}: AutoCompleteInputProps) => {
  const router = useRouter();
  const [query, setQuery] = useState(defaultValue);
  const autoCompleteRef = useRef(null);
  const isLoaded = true;

  const handleQuery = useCallback((value: any) => {
    setQuery(value);
    onChange?.(value);
  }, []);

  useEffect(() => {
    setQuery(defaultValue);
  }, [defaultValue]);

  const Router = router?.query?.stage;
  useEffect(() => {
    if (isLoaded)
      handleScriptLoad(
        handleQuery,
        autoCompleteRef,
        extraAction,
        name,
        types,
        setIsAddressSelected
      );
  }, [isLoaded, Router]);
  let additionalClasss = smallFontSize ? "font-size-small" : "";

  const ifAddressStyle = className?.includes("addressStyles");

  return (
    <input
      onChange={(event: any) => handleQuery(event?.target?.value)}
      placeholder={placeHolder}
      value={query || defaultValue}
      disabled={disabled}
      ref={autoCompleteRef}
      className={
        className?.includes("border-radius-zero")
          ? validInput
            ? `${styles.field} ${styles["border-radius-zero"]} ${
                styles[additionalClasss]
              } ${ifAddressStyle ? styles.addressStyles : ""} `
            : `${styles.field} ${styles["border-radius-zero"]} ${
                styles["invalid-input"]
              } ${styles[additionalClasss]} ${
                ifAddressStyle ? styles.addressStyles : ""
              }  `
          : validInput
          ? `${styles.field} ${styles[additionalClasss]} ${
              ifAddressStyle ? styles.addressStyles : ""
            } `
          : `${styles.field} ${styles["invalid-input"]} ${
              styles[additionalClasss]
            } ${ifAddressStyle ? styles.addressStyles : ""} `
      }
      type="search"
      id={isBilling ? "billing" : "shipping"}
      onFocus={onFocus}
      onBlur={(e: any) => setFieldValueCallback?.(e)}
    />
  );
};

export default SearchLocationInput;
