const gaScrolling = () => {
    const rangesPrinted:any = [{'flag':false}, {'flag':false}, {'flag':false}, {'flag':false}, {'flag':false}, {'flag':false}];
    window.onscroll = function () {
      const totalHeight = document.body.scrollHeight - window.innerHeight;
      const scrollPercent = (window.scrollY / totalHeight) * 100;
      const scrollPercentRounded = Math.round(scrollPercent);
      if (
        scrollPercentRounded >= 10 &&
        scrollPercentRounded < 25 &&
        !rangesPrinted[0]?.flag
      ) {
        if (!rangesPrinted[0]?.flag) {
          window?.dataLayer?.push({
            event: "scroll",
            percent_scrolled: 10,
          });
        }
        rangesPrinted[0].flag = true
      }
      else if (
        scrollPercentRounded >= 25 &&
        scrollPercentRounded < 50 &&
        !rangesPrinted[1]?.flag
      ) {
        if (!rangesPrinted[1]?.flag) {
          window?.dataLayer?.push({
            event: "scroll",
            percent_scrolled: 25,
          });
        }
        rangesPrinted[1].flag = true;
      }
      else if (
        scrollPercentRounded >= 50 &&
        scrollPercentRounded < 75 &&
        !rangesPrinted[2]?.flag
      ) {
        if (!rangesPrinted[2]?.flag) {
          window?.dataLayer?.push({
            event: "scroll",
            percent_scrolled: 50,
          });
        }
        rangesPrinted[2].flag = true;
      }
      else if (
        scrollPercentRounded >= 75 &&
        scrollPercentRounded < 90 &&
        !rangesPrinted[3]?.flag
      ) {
        if (!rangesPrinted[3]?.flag) {
          window?.dataLayer?.push({
            event: "scroll",
            percent_scrolled: 75,
          });
        }
        rangesPrinted[3].flag = true;
      }
      else if (
        scrollPercentRounded >= 90 &&
        scrollPercentRounded < 100 &&
        !rangesPrinted[4]?.flag
      ) {
        if (!rangesPrinted[4]?.flag) {
          window?.dataLayer?.push({
            event: "scroll",
            percent_scrolled: 90,
          });
        }
        rangesPrinted[4].flag = true;
      }
      else if (scrollPercentRounded === 100 && !rangesPrinted[5]?.flag) {
        if (!rangesPrinted[5]?.flag) {
          window?.dataLayer?.push({
            event: "scroll",
            percent_scrolled: 100,
          });
        }
        rangesPrinted[5].flag = true;
      }
    };
}

export default gaScrolling;