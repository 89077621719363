import CustomButton from "../../Button";
import styles from "./styles.module.scss";
import Icon from "@components/shared/Icon";
export const PrevArrow = ({ currentSlide, slideCount, ...props }: any) => {
  return (
    <CustomButton
      {...props}
      className={` 
        slick-prev slick-arrow ${styles.arrowBtn} `}
      aria-hidden="false"
      type="button"
      aria-label="PrevArrow"
      disabled={currentSlide === 0}
    >
      <div className={styles.arrowContainer}>
        <Icon name="arrow" dir="top" aria-label="Previous Arrow" />
      </div>
    </CustomButton>
  );
};

export const NextArrow = ({ currentSlide, slideCount, className, ...props }: any) => {
  return (
    <CustomButton
      {...props}
      className={` 
        slick-next slick-arrow  ${styles.arrowBtn} `}
      aria-hidden="false"
      aria-label="PrevArrow"
      type="button"
      disabled={className.includes('slick-disabled')}
    >
      <div className={styles.arrowContainer}>
        <Icon name="arrow" aria-label="Next Arrow" />
      </div>
    </CustomButton>
  );
};
