import React, { Fragment, memo } from "react";
import Head from "next/head";
import { SEOprops } from "./types";
const SEO = ({ title, description, metadata, children }: SEOprops) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      {metadata?.map((item, index) =>
        item?.property ? (
          <meta key={index} property={item.property} content={item.content} />
        ) : item.name ? (
          <meta key={index} name={item.name} content={item.content} />
        ) : (
          <Fragment key={index}></Fragment>
        )
      )}
      {children}
    </Head>
  );
};
export default memo(SEO);
