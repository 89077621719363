import styles from "./styles.module.scss";
import { Row, Col, Tabs, notification, Divider } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import Button from "@components/shared/Button";
import { useRouter } from "next/router";
import OrdersTable from "./OrdersTable";
import CustomButton from "@components/shared/Button";
import Field from "@components/shared/Fields";
import SearchIcon from "@assets/icons/search.svg";
import Icon from "@components/shared/Icon";
import classnames from "classnames";
import Overlay from "@components/shared/Overlay";
import OrderDetails from "./OrderDetails";
import { getCookie } from "src/server/utils/commonUtils";
import FiltersMenu from "./FiltersMenu";
import { filterItem } from "@types";
import { AppContext } from "~/src/libs/context";
import useClient from "~/src/server/utils/client";
import { usePriceFormat } from "@src/utilities/hooks/usePriceFormat";
import { getText } from "~/src/libs/resources";
import { useWindowResize } from "~/src/utilities/hooks/useWindowResize";
import DataLoader from "~/src/components/shared/DataLoader";
import Pagination from "@components/shared/pagination";
import SEO from "@components/shared/SEO";

interface MyOrdersProps {
  OrderNumber?: string;
}
const AllOrders = ({OrderNumber}:MyOrdersProps) => {
  const router = useRouter();
  const { isViewDetails, setIsViewDetails, setOrderID } =
    useContext<any>(AppContext);
  const dataFetchedRef = useRef(false);
  const [showFilters, setShowFilters] = useState(false);
  const [ViewDetails, setViewDetails] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<filterItem[]>([]);
  const [allOrders, setAllOrders] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [orderDetails, setOrderDetails] = useState();
  const [orderNum, setOrderNum] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [orderStatus, setOrderStatus] = useState(
    router?.query?.orderStatus || ""
  );
  const [orderMonth, setOrderMonth] = useState(router?.query?.date || "");
  const [monthChoices, setMonthChoices] = useState([]);
  const [myOrdersLoaded, setMyOrdersLoaded] = useState(false);
  const [listOfFilters, setFiltersList] = useState<any>();
  const [searchValue, setSearchValue] = useState(
    router?.query?.search?.toString()
  );
  const [minPrice, setMinPrice] = useState<number>(0);
  const [maxPrice, setMaxPrice] = useState<number>(0);
  const [dateSort, setDateSort] = useState<string>("");
  const isMobile = useWindowResize() <= 768;
  const client = useClient({});
  const { setIsSessionExpired } = useContext<any>(AppContext);

  const getAllOrders = async (
    status?: any,
    month?: any,
    searchText?: any,
    pageNumber?: any,
    sortByDate?: any,
    minPrice?: any,
    maxPrice?: any
  ) => {
    setIsLoading(true);
    setMyOrdersLoaded(false);
    setOrderMonth(month);
    setOrderStatus(status);
    setSearchValue(searchText);
    setDateSort(sortByDate);
    setMinPrice(minPrice);
    setMaxPrice(maxPrice);
    pageNumber && setCurrentPage(pageNumber);
    let response;
    try {
      const url = `/api/ERP/orders/${status ? status : "all"}?${
        month || month === 0 ? `date=${month || month === 0 ? month : ""}` : ""
      }${`${
        searchText || searchValue ? `&search=${searchText || searchValue}` : ""
      }`}&page=${pageNumber ? pageNumber : currentPage}&ordering=${
        sortByDate == "+" ? "date_placed" : sortByDate || "-"
      }&tab=all&page_size=30${`${
        Number(maxPrice) ? `&amount=${minPrice},${maxPrice}` : ""
      }`}`;

      response = await client.basic.get(url, {
        headers: { sessionId: getCookie("sessionid") },
      });
      setMonthChoices(response?.data?.filters?.[0]?.choices);
      setAllOrders(response?.data?.results);
      setPageSize(response?.data?.page_size || 0);
      setOrderNum(response?.data?.count);
      const tableData: any = [];
      response?.data?.results?.map((item: any, index: any) => {
        let quantity = 0;
        let pallet_quantity;
        item?.pallets?.forEach((pallet: any) => {
          if (pallet?.pallet_items?.length) {
            pallet_quantity = pallet.quantity * pallet.pallet_items.length;
            quantity += pallet_quantity;
          }
        });

        tableData?.push({
          key: index,
          orderID: item?.order_number,
          placedBy: item?.shipping_address?.contact_name,
          orderDate: item?.date_placed?.slice(0, 10),
          quantity: quantity,
          amount: `$${usePriceFormat(
            Number(item?.grand_total_delivered_price || 0)
          )}`,
          status: item?.status,
          payment_method: item?.credit_card?.payment_method,
        });
      });

      setDataSource(tableData);
      setDataSource(tableData);
      setMyOrdersLoaded(true);
      setIsLoading(false);
    } catch (error: any) {
      if (error?.response?.status === 401) {
        setIsSessionExpired(true);
      } else
        notification.error({
          message:
            error?.response?.data?.detail ||
            getText().General.Messages.SomethingWrongTryAgain,
          duration: 4,
        });
      setIsLoading(false);
    }
  };
  const changeSearchHandler = (value: any) => {
    setSearchValue(value);
  };
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    if (router?.query?.orderID || OrderNumber) {
      setOrderID(router?.query?.orderID || OrderNumber);
      router.push({
        pathname: `/account/myOrders`,
        query: { orderID: router?.query?.orderID || OrderNumber },
      });
      setViewDetails(true);
    } else if (router?.query && !router?.query?.orderID) {
      const filtersArr: any = [];
      if (router?.query?.date) {
        filtersArr.push({
          header: "Month",
          item: router?.query?.date?.toString(),
        });
      }
      if (router?.query?.orderStatus) {
        filtersArr.push({
          header: "Order status",
          item: router?.query?.orderStatus?.toString(),
        });
      }
      setSelectedFilters(filtersArr);
      const { slug, ...queryFilters } = router.query;
      router.push({
        pathname: `/account/myOrders`,
        query: queryFilters,
      });
    } else {
      router.push("/account/myOrders");
    }
  }, []);

  useEffect(() => {
    getAllOrders(
      orderStatus,
      orderMonth,
      searchValue,
      currentPage,
      dateSort,
      minPrice,
      maxPrice
    );
  }, [currentPage]);

  const searchHandler = () => {
    getAllOrders(
      orderStatus,
      orderMonth,
      searchValue,
      1,
      dateSort,
      minPrice,
      maxPrice
    );
    const query = router?.query;
    const { search, ...withOutSearch } = router?.query;
    searchValue && searchValue?.length > 0
      ? router.push(
          {
            query: { ...query, search: searchValue },
          },
          undefined,
          { shallow: false }
        )
      : router.push(
          {
            query: { ...withOutSearch },
          },
          undefined,
          { shallow: false }
        );
  };
  useEffect(() => {
    if (searchValue) {
      searchHandler();
    }
  }, [searchValue]);
  return (
    <>
      <SEO
        title={getText().Account?.SEO?.myOrders?.title}
        description={getText().Account?.SEO?.myOrders?.description}
      />
      {ViewDetails || isViewDetails ? (
        <div className={`${styles.orderDetails}`}>
          <OrderDetails
            orderDetails={orderDetails}
            setViewDetails={setViewDetails}
          />
        </div>
      ) : (
        <>
          {showFilters && (
            <>
              <Overlay
                closeMenu={() => setShowFilters(false)}
                bgOverlay={"bgOverlay"}
              />
              <FiltersMenu
                orderMonth={orderMonth}
                orderStatus={orderStatus}
                setOrderMonth={setOrderMonth}
                listOfFilters={listOfFilters}
                setOrderStatus={setOrderStatus}
                getAllOrders={getAllOrders}
                setDateSort={setDateSort}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                onClose={() => setShowFilters(false)}
                openMenu={showFilters}
                setCurrentPage={setCurrentPage}
                searchValue={searchValue}
                minPrice={minPrice}
                setMinPrice={setMinPrice}
                maxPrice={maxPrice}
                setMaxPrice={setMaxPrice}
              />
            </>
          )}
          <div className={`${styles.myOrdersWrapper} pt-20`}>
            <Row className={`${styles.header} mb-18`} justify="space-between">
              <Col className="font-bold-h2 text-oxfordBlue">
                {getText()?.Account?.myOrdersTitle}
              </Col>
              <Col className={`${styles.actionWrapper}`}>
                <Field
                  type="search"
                  placeholder="Search"
                  hasIcon={true}
                  Icon={SearchIcon}
                  className={styles.searchInput}
                  onIconClick={searchHandler}
                  value={searchValue}
                  onChange={(e: any) =>
                    changeSearchHandler(e.currentTarget.value)
                  }
                  allowClear={{
                    clearIcon: (
                      <Icon name="mobileCross" classesName="py-4 d-flex" />
                    ),
                  }}
                  onPressEnter={searchHandler}
                  prefix={true}
                />
                <CustomButton
                  theme="primary"
                  className={classnames(
                    styles.filterWrapper,
                    "text-oxfordBlue bg-white $oxford-blue font-bold-base"
                  )}
                  icon={<Icon name="filters" width={18} height={18} />}
                  onClick={() => setShowFilters(true)}
                >
                  {getText()?.Account?.filtersButton}
                </CustomButton>
              </Col>
            </Row>
            {isMobile ? (
              <div className={`${styles.ordersMobileView} mb-8`}>
                <div className={classnames(styles.titleSection, " p-12")}>
                  <span className="text-oxfordBlue font-bold-h5">
                    All Orders
                  </span>
                </div>
                {!isLoading ? (
                  dataSource?.length > 0 ? (
                    <>
                      {dataSource?.map((item: any, index: number) => (
                        <>
                          <Row
                            key={index}
                            className={styles.myOrdersRow}
                            gutter={[70, 16]}
                          >
                            <Col
                              className={`${styles.trimText} font-bold-sm`}
                              span={12}
                            >
                              <p className="mb-2 font-semibold-xxs">
                                {getText()?.Account?.orderId}
                              </p>
                              <span className="text-oxfordBlue">
                                {item?.orderID}
                              </span>
                            </Col>
                            <Col
                              className={`${styles.trimText} font-medium-sm text-oxfordBlue`}
                              span={12}
                            >
                              <p className="mb-2 font-semibold-xxs">
                                {getText()?.Account?.placedBy}
                              </p>
                              <span className="text-oxfordBlue">
                                {item?.placedBy}
                              </span>
                            </Col>
                            <Col
                              className={`${styles.trimText} font-medium-sm text-oxfordBlue`}
                              span={12}
                            >
                              <p className="mb-2 font-semibold-xxs">
                                {getText()?.Account?.OrderDate}
                              </p>
                              <span className="text-oxfordBlue">
                                {item?.orderDate}
                              </span>
                            </Col>
                            <Col
                              className={`${styles.trimText} font-semibold-sm text-oxfordBlue`}
                              span={12}
                            >
                              <p className="mb-2 font-semibold-xxs">
                                {getText()?.Account?.status}
                              </p>
                              <span className="text-oxfordBlue">
                                {item?.status}
                              </span>
                            </Col>
                            <Col span={12}>
                              <Button
                                onClick={() => {
                                  setIsViewDetails(true);
                                  setOrderID(item?.orderID);
                                  router.push({
                                    pathname: `/account/myOrders`,
                                    query: { orderID: item?.orderID },
                                  });
                                }}
                                className="font-semibold-xs px-17"
                                theme={"primary"}
                              >
                                {getText()?.Account?.viewButton}
                              </Button>
                            </Col>
                          </Row>
                          <Divider type="horizontal" className="my-8" />
                        </>
                      ))}
                      <div>
                        <Pagination
                          total={orderNum}
                          pageSize={pageSize}
                          current={currentPage}
                          setCurrent={setCurrentPage}
                        />
                      </div>
                    </>
                  ) : (
                    <div className={`text-center text-neutralGray py-20 ${styles.noDataWrapper}`}>
                       <Icon name="noData" width={64} height={40}/>
                      <span className="mt-4">{getText()?.Account?.noData}</span>
                    </div>
                  )
                ) : (
                  <Row justify="center" className="py-20">
                    <DataLoader />
                  </Row>
                )}
              </div>
            ) : (
              <Row className={`${styles.orderSection}`}>
                <Tabs
                  className="w-100"
                  defaultActiveKey="1"
                  items={[
                    {
                      label: `All Orders`,
                      key: "1",
                      children: (
                        <OrdersTable
                          orderStatus={orderStatus}
                          pageSize={pageSize}
                          orderNum={orderNum}
                          getAllOrders={getAllOrders}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          setOrderDetails={setOrderDetails}
                          dataSource={dataSource}
                          setViewDetails={() => setViewDetails(true)}
                          myOrdersLoaded={myOrdersLoaded}
                        />
                      ),
                    },
                  ]}
                />
              </Row>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default AllOrders;
