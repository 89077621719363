import { memo } from "react";
import styles from "../style.module.scss";
import { getText } from "~/src/libs/resources";
import Icon from "@components/shared/Icon";
import { Skeleton } from "antd";
import { usePriceFormat } from "@src/utilities/hooks/usePriceFormat";

const SizeComponent = ({
  isVendor,
  validGuestUser,
  isCustomerDataCompleted,
  item,
  time,
}: any) => {
  return (
    <div
      className={`${styles.sizeWrapper} ${
        isVendor ? styles.vendorSizeWrapper : ""
      }`}
    >
      <div className={styles.itemWrapper}>
        <span className={styles.pricePerUnit}>
          {getText()?.PDP?.productDetails?.pricePerUnit}
        </span>
        <span className={styles.value}>
          {validGuestUser || !isCustomerDataCompleted ? (
            <Icon
              name="lock"
              color="#898989"
              width={16}
              height={16}
              classesName={styles.lockIcon}
            />
          ) : (
            ""
          )}
        </span>
        <span className={styles.value}>
          {!validGuestUser && isCustomerDataCompleted && (
            <>
              {isVendor ? (
                item?.per_unit_delivered_price === undefined ? (
                  <Skeleton.Button active={true} size="default" />
                ) : (
                  `$${usePriceFormat(item?.per_unit_delivered_price)}`
                )
              ) : item?.perUnitDeliveredPrice === undefined &&
                item?.per_unit_delivered_price === undefined ? (
                <Skeleton.Button active={true} size="default" />
              ) : (
                <>
                  $
                  {item?.perUnitDeliveredPrice
                    ? usePriceFormat(item?.perUnitDeliveredPrice)
                    : usePriceFormat(item?.per_unit_delivered_price)}
                </>
              )}
            </>
          )}
        </span>
      </div>
      <span className={styles.divider} />
      <div className={styles.itemWrapper}>
        <span>{getText()?.PDP?.productDetails?.leadTime}</span>
        <span className={styles.value}>
          {validGuestUser || !isCustomerDataCompleted ? (
            <Icon
              name="lock"
              color="#898989"
              width={16}
              height={16}
              classesName={styles.lockIcon}
            />
          ) : (
            ""
          )}
        </span>
        <span className={styles.value}>
          {!validGuestUser && isCustomerDataCompleted && time === undefined ? (
            <Skeleton.Button active={true} size="default" />
          ) : (
            !validGuestUser &&
            isCustomerDataCompleted &&
            time &&
            `${time} ${getText()?.General?.daysAndDelivery}`
          )}
        </span>
      </div>
    </div>
  );
};

export default memo(SizeComponent);
