import React, {useContext, useState } from "react";
import styles from "./styles.module.scss";
import Icon from "@components/shared/Icon";
import Button from "@components/shared/Button";
import classnames from "classnames";
import RequestForm from "../requestForm";
import { getText } from "~/src/libs/resources";
import { AppContext } from "~/src/libs/context";

const PromoQuestionBanner = ({
  promoClassname,
  isStorePage,
  isBrandPage
}: any) => {
  const { guestUser } =
  useContext<any>(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };
  return (
    <div
      className={
        isStorePage
          ? styles.StorePromoQ
          : promoClassname === "searchPagePromoQ"
          ? styles.searchPromoQ
          : promoClassname === "promoQ"
          ? styles.promoQ
          : "container mt-10"
      }
    >
    
        {guestUser ? (
            <div className={classnames(styles.itemWrapperGuestUser, "py-20")}>
            <div className={classnames(styles.wrapperTitleLogo)}>
            <div>
              <Icon name="find_product" width={54} height={54} />
            </div>
            <h3 className={classnames(styles.title)}>
              {isBrandPage
                ? getText().Brand.CannotFindBrand
                : getText().PLP.CannotFindProduct}
            </h3>
            </div>
            <Button
              theme="primary"
              className={classnames(styles.btnWrapper, "font-bold-base bg-primary")}
              onClick={showModal}
            >
              {getText()?.General?.RequestIt}
            </Button>
            <RequestForm
              setIsModalOpen={setIsModalOpen}
              isModalOpen={isModalOpen}
            />
          </div>
        ) : (
          <div className={classnames(styles.itemWrapper, "py-20")}>
          <div className={classnames(styles.wrapperTitleLogo)}>
          <div>
            <Icon name="find_product" width={54} height={54} />
          </div>
          <h3 className={classnames(styles.title)}>
            {isBrandPage
              ? getText().Brand.CannotFindBrand
              : getText().PLP.CannotFindProduct}
          </h3>
          </div>
          <Button
            theme="primary"
            className={classnames(styles.btnWrapper, "font-bold-base bg-primary")}
            onClick={showModal}
          >
            {getText()?.General?.RequestIt}
          </Button>
          <RequestForm
            setIsModalOpen={setIsModalOpen}
            isModalOpen={isModalOpen}
          />
        </div>
        )}
        
       
    </div>
  );
};

export default PromoQuestionBanner;
