export const promoHandler = (item: any, setItemHasPromo: any) => {
  const currentData = new Date().toISOString();

  try {
    const promo = JSON?.parse(item?.Promo)?.[0] || {};
    const { start_datetime: startData, end_datetime: endDate } = promo;
    const hasEndDate = !endDate;
    const hasStartDate = !startData;

    if (
      item?.Promo !== "[]" &&
      ((currentData > startData && currentData < endDate) ||
        (currentData > startData && hasEndDate) ||
        (currentData < endDate && hasStartDate) ||
        (hasEndDate && hasStartDate))
    ) {
      setItemHasPromo(true);
    } else {
      setItemHasPromo(false);
    }
  } catch (error) {
  }
};
