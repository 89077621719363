import { Col, Row, Form, Input, notification } from "antd";
import styles from "../styles.module.scss";
import CustomButton from "@components/shared/Button";
import epalletLogo from "@assets/images/epalletLogo.png";
import Image from "next/image";
import { AppContext } from "~/src/libs/context";
import { useState, useContext } from "react";
import axios from "axios";
import { useRouter } from "next/router";
import RestorePasswordProps from "./types";
import Icon from "../../../shared/Icon";
import { getText } from "@src/libs/resources";
import { setCookie } from "cookies-next";
import Link from "next/link";

const RestorePassword: React.FC<RestorePasswordProps> = ({
  uid,
  token,
  type,
}) => {
  const [errorMessage, setErrorMessage] = useState<any>({});
  const { guestUser, setGuestUser } = useContext<any>(AppContext);
  const [btnLoading, setBtnLoading] = useState(false);
  const router = useRouter();
  const onFinish = async (values: any) => {
    setBtnLoading(true);
    const restorePassword = {
      password: values.password,
    };
    try {
      const url =
        type === "RESTORE_PASS"
          ? "/api/ERP/restorePassword"
          : type === "ADDITIONAL_USER"
          ? "/api/epallet-signup/additional-user-registration"
          : "";
      setErrorMessage("");
      const response = await axios.post(url, {
        ...restorePassword,
        uid,
        token,
      });
     
      if (type === "ADDITIONAL_USER") {
        const isSessionValid = process?.env?.NEXT_PUBLIC_APP_ENV === "development"? 'staging_valid_session' : 'valid_session'
        const ePalletDomain = process?.env?.NEXT_PUBLIC_PARENT_DOMAIN
        setCookie(isSessionValid, "valid", {
          httpOnly: false,
          secure: true,
          domain: ePalletDomain
        });
        
        setCookie("beta_cookie", true, {
          path: "/",
          sameSite: "strict",
          secure: true,
          domain: ePalletDomain,
        });

        window.location.href = "/";
      } else {
        window.location.href = "/?epallet_login=true";
      }
      notification.success({
        message: `A new password has been successfully created!`,
        duration: 4,
      });
    } catch (error: any) {
      setErrorMessage(error?.response?.data);
      notification.error({
        message:
          error?.response?.data?.detail ||
          `${getText().General.Messages.SomethingWrongTryAgain}`,
        duration: 4,
      });
    }

    setBtnLoading(false);
  };

  const onFinishFailed = (errorInfo: any) => {};
  return (
    <>
      <Row
        className={`bg-white text-oxfordBlue container-fluid p-0 ${styles["outer-container"]}`}
      >
        <Col
          lg={{ span: 0 }}
          md={{ span: 2 }}
          sm={{ span: 2 }}
          xs={{ span: 2 }}
        />
        <Col
          lg={{ span: 0 }}
          md={{ span: 22 }}
          sm={{ span: 22 }}
          xs={{ span: 22 }}
          className={`mt-8`}
        >
          <Link href="/">
            <a>
              <Image
                src={epalletLogo}
                width="126"
                height="46"
                alt="ePallet-logo"
              />
            </a>
          </Link>
        </Col>
        <Col
          lg={{ span: 10 }}
          md={{ span: 0 }}
          sm={{ span: 0 }}
          xs={{ span: 0 }}
          className={`${styles.left_container} px-0`}
        >
          <Row className={styles.imageContainer}>
            <Col span={10} className={`pt-26`}>
              <Link href="/">
                <a>
                  <Image
                    src={epalletLogo}
                    width="184"
                    height="67"
                    alt="ePallet-logo"
                    className={styles["cursor-pointer"]}
                  />
                </a>
              </Link>
              <div
                className={`${styles.yourSupply} font-bolder-xxl mt-32 lh-sm`}
              >
                {getText()?.General?.chain}
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={2}></Col>
        <Col
          lg={{ span: 10 }}
          md={{ span: 20 }}
          sm={{ span: 20 }}
          xs={{ span: 20 }}
          className={`py-24 my-24`}
        >
          <Form
            onFinish={onFinish}
            initialValues={{ remember: true }}
            onFinishFailed={onFinishFailed}
          >
            <Row gutter={16}>
              <Col className={`gutter-row mb-30 mt-30`} span={24}>
                <span
                  className={`${styles["login-title"]} font-bolder-lg lh-sm`}
                >
                  {getText()?.RestorePassword?.createNewPassword}
                </span>
              </Col>
              <Col className={`gutter-row`} span={24}>
                <Form.Item
                  name="password"
                  {...(errorMessage?.password
                    ? {
                        validateStatus: "error",
                      }
                    : {})}
                  help={
                    errorMessage?.password ? (
                      <ul className="mt-6 ps-0">
                        {errorMessage?.password?.map((item: string, index:number) => (
                          <li className="mb-6 font-medium-xs" key={index}>{item}</li>
                        ))}
                      </ul>
                    ) : null
                  }
                  rules={[
                    ({}) => ({
                      validator(_, value) {
                        setErrorMessage({});
                        if (
                          /[A-Z]/.test(value) &&
                          /[0-9]/.test(value) &&
                          value.length >= 8
                        ) {
                          return Promise.resolve();
                        } else if (!value) {
                          return Promise.reject(
                            new Error("This field should not be empty")
                          );
                        } else if (value) {
                          return Promise.reject(
                            new Error(
                              "Password should contain at least 8 characters and a capital letter and number"
                            )
                          );
                        }
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    className={`${styles.border_radius} p-5`}
                    placeholder="New Password"
                    iconRender={(visible) =>
                      visible ? (
                        <Icon
                          name="eye"
                          color="#BCBCBC"
                          width={24}
                          height={24}
                        />
                      ) : (
                        <Icon
                          name="eye"
                          color="#02254a"
                          width={24}
                          height={24}
                        />
                      )
                    }
                  />
                </Form.Item>
              </Col>

              <Col className={`gutter-row`} span={24}>
                <Form.Item
                  name="VerifyPassword"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "This field should not be empty",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    className={`${styles.border_radius} p-5`}
                    placeholder="Verify Password"
                    iconRender={(visible) =>
                      visible ? (
                        <Icon
                          name="eye"
                          color="#BCBCBC"
                          width={24}
                          height={24}
                        />
                      ) : (
                        <Icon
                          name="eye"
                          color="#02254a"
                          width={24}
                          height={24}
                        />
                      )
                    }
                  />
                </Form.Item>
              </Col>
              <Col className={`gutter-row mt-10`} span={24}>
                <CustomButton
                  className={`${styles.height_50} w-100 `}
                  htmlType="submit"
                  theme="primary"
                  disabled={false}
                  loading={btnLoading}
                >
                  <span className="font-bold-base lh-sm ">
                    {getText()?.RestorePassword?.create}
                  </span>
                </CustomButton>
              </Col>
              <Col className={`${styles["text-align"]} mt-34 `} span={24}>
                <span className="font-regular-base lh-md me-3">
                  {getText()?.RestorePassword?.backTo}
                </span>

                <CustomButton
                  theme="link"
                  onClick={() => {
                    router.push("/?epallet_login=true");
                  }}
                >
                  <span className="font-bold-base lh-md ">
                    {getText()?.RestorePassword?.signIn}
                  </span>
                </CustomButton>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};
export default RestorePassword;
