import { Checkbox, notification, Image, Row } from "antd";
import Tag from "@components/shared/Tag";
import styles from "@components/shared/ProductCard/style.module.scss";
import classNames from "classnames";
import Link from "next/link";
import { usePriceFormat } from "@src/utilities/hooks/usePriceFormat";
import { getText } from "~/src/libs/resources";
import { useContext, useState } from "react";
import Tags from "@components/shared/Tag";
import { AppContext } from "~/src/libs/context";
import CustomButtons from "@components/shared/Button";
import Icon from "~/src/components/shared/Icon";
import axios from "axios";
import { getCookie } from "~/src/server/utils/commonUtils";
import { useRouter } from "next/router";
import isOutOfStock from "~/src/utilities/hooks/isOutOfStock";
import RequestForm from "~/src/components/shared/requestForm";

const Card = ({ item, uid, isCustomerDataCompleted }: any) => {
  const {
    guestUser,
    defaultPostZip,
    customerStatus,
    setShowVerifyModal,
    completeProfileLink,
  } = useContext<any>(AppContext);
  const [addToCartStatus, setAddToCartStatus] = useState(false);
  const router = useRouter();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const buildMixedPallet = async () => {
    setAddToCartStatus(true);
    let _url = `/api/ERP/customer/mixedPallet`;
    if (defaultPostZip) {
      _url += `?post_zip=${defaultPostZip}`;
    }
    await axios
      .post(_url, {
        data: { product: item?.id, case_quantity: null },
        headers: { sessionId: getCookie("sessionid") },
      })
      .then(async (res) => {
        router.query.pallet = res?.data?.id;
        notification.success({
          message: getText().PDP.messages.productAddedToPallet,
          duration: 8,
        });
        await axios
          .post(`/api/ERP/customer/mixedPallet/${res?.data?.id}/changeName`, {
            data: {
              name: `${res?.data?.vendor} Mixed Pallet`,
            },
            headers: { sessionId: getCookie("sessionid") },
          })
          .then(() => {
            setAddToCartStatus(false);
            router.push(
              `/mixed-pallet-vendor?ordering=delivered_case_price&pallet=${res?.data?.id}&vendor=${res?.data?.products?.[0]?.product?.vendor_id}`
            );
          })
          .catch((e) => {
            setAddToCartStatus(false);
          });
      })
      .catch((e) => {
        console.error(e);
        setAddToCartStatus(false);
      });
  };
  return (
    <>
      <div
        className={classNames(
          styles.productCard,
          styles.productSectionClass,
          styles.shoppingListProductCard
        )}
      >
        <div className={`${styles.cardHeader}`} style={{ height: "auto" }}>
          <div style={{ display: "flex" }}>
            {item?.has_promo ? (
              <Tag
                theme="light"
                className={`font-bold-xs text-oxfordBlue`}
                text="PROMO"
                hasIcon={false}
                size={"md"}
              />
            ) : null}

            {item?.for_mixed_pallet && !guestUser && (
              <Tags
                theme="outline"
                text={"Mixed Pallet Available"}
                hasIcon={true}
                iconName="my-order"
                iconHeight={13}
                iconWidth={13}
                size="md"
                className={`${styles.mixedPalletTag}`}
              />
            )}
          </div>
          <Row align="middle">
            {item?.has_haggle_rewards ? (
              <Link href="/haggle">
                <a aria-label="Haggle landing page link" className="pe-5">
                  <Image
                    height={28}
                    preview={false}
                    src="https://cdn.bfldr.com/SK3DIHJL/at/x6r3whnzsvwcxf9xxkxv8q7k/haggleLogo.svg"
                  />
                </a>
              </Link>
            ) : null}
            <Checkbox
              value={uid}
              aria-label="Select a product to add it to cart"
            />
          </Row>
        </div>
        <div className={`${styles.cardInfoWithImg} pt-4`}>
          <Link href={`/product/${item?.slug}`}>
            <a>
              <div
                className={`${styles.imageWrapper} ${styles.sharedImageWrapper}`}
              >
                <Image
                  draggable="false"
                  src={item?.plp_image_url}
                  alt="card image"
                  preview={false}
                />
              </div>
            </a>
          </Link>

          <div className={styles.cardInfo}>
            <div className={styles.category}>
              <Link href={`/product-list/brands/${item?.brand_name}`}>
                <a>{item?.brand_name}</a>
              </Link>
            </div>
            <div className={styles.title}>
              <Link href={`/product/${item?.slug}`}>
                <a>{item?.name}</a>
              </Link>
            </div>
          </div>
        </div>
        {(!isOutOfStock(item?.is_available) || !isCustomerDataCompleted) &&
        !item?.is_prototype ? (
          <>
            <div className={styles.priceWrapper}>
              <div
                className={`${styles.itemWrapper} ${styles.itemWrapperForMobile}`}
              >
                <span>{getText()?.PDP?.productDetails?.packSize}</span>
                <span className={styles.value}>{item?.pack_size}</span>
              </div>
              <span
                className={`${styles.divider} ${styles.itemWrapperForMobile}`}
              />
              <div
                className={`${styles.itemWrapper} ${styles.itemWrapperForMobile}`}
              >
                <span>{getText()?.Account?.cases}</span>
                <span className={styles.value}>{item?.case_per_pallet}</span>
              </div>
              <span
                className={`${styles.divider} ${styles.itemWrapperForMobile}`}
              />
              {item?.lead_time_days && (
                <div className={styles.itemWrapper}>
                  <span>{getText()?.PDP?.productDetails?.leadTime}</span>
                  <span className={styles.value}>
                    {item?.lead_time_days} {getText()?.Account?.days}
                  </span>
                </div>
              )}

              {item?.delivered_price ? (
                <>
                  <span className={styles.divider} />
                  <div className={styles.itemWrapper}>
                    <span>{getText()?.PDP?.priceDetails?.deliveredPrice}</span>
                    <span className={styles.value}>
                      ${usePriceFormat(item?.delivered_price)}
                    </span>
                  </div>
                </>
              ) : null}
            </div>
            <div className={styles.sizeWrapper}>
              <div className={styles.itemWrapper}>
                <span>{getText()?.PDP?.productDetails?.packSize}</span>
                <span className={styles.value}>{item?.pack_size}</span>
              </div>
              <span className={styles.divider} />
              <div className={styles.itemWrapper}>
                <span>{getText().PDP.productDetails.casesPerPallet}</span>
                <span className={styles.value}>{item?.case_per_pallet}</span>
              </div>
            </div>
          </>
        ) : (
          <span
            className={`${styles.outOfStock} d-block text-darkGray py-10 font-medium-h6`}
          >
            {item?.is_prototype
              ? getText()?.PDP?.prototypeProduct
              : getText().PDP.outOfStock}
          </span>
        )}

        {!guestUser && item?.for_mixed_pallet && !item?.is_prototype && (
          <>
            {isCustomerDataCompleted && !isOutOfStock(item?.is_available) && (
              <>
                <div className={styles.actionsContainer}>
                  <CustomButtons
                    theme="secondary"
                    className={styles.addToPallet}
                    hasIcon={true}
                    icon={<Icon name="my-order" width={20} height={20} />}
                    onClick={buildMixedPallet}
                    loading={addToCartStatus}
                    disabled={addToCartStatus}
                  >
                    {getText()?.PDP?.buildMixedPallet}
                  </CustomButtons>
                </div>
              </>
            )}
          </>
        )}
        {!guestUser && isCustomerDataCompleted ? (
          isOutOfStock(item?.is_available) && (
            <div className={styles.actionsContainer}>
              <CustomButtons
                className={`font-bold-base lh-1`}
                text={getText().General.RequestProduct}
                theme="primary"
                onClick={showModal}
              />
            </div>
          )
        ) : (
          <div className={styles.actionsContainer}>
            <Link
              href={`${
                customerStatus?.profile_completed === false
                  ? completeProfileLink
                  : ""
              }`}
            >
              <a
                onClick={() => {
                  customerStatus?.email_verified === false
                    ? setShowVerifyModal(true)
                    : null;
                }}
                style={{ textAlign: "center" }}
              >
                <CustomButtons
                  className={`${styles.customButton} mt-2`}
                  theme="primary"
                >
                  {customerStatus?.profile_completed === false
                    ? getText()?.General?.Messages?.CompleteProfile
                    : customerStatus?.email_verified === false &&
                      getText()?.General?.Messages?.VerifyEmail}
                </CustomButtons>
              </a>
            </Link>
          </div>
        )}
        {isModalOpen && (
          <RequestForm
            setIsModalOpen={setIsModalOpen}
            isModalOpen={isModalOpen}
          />
        )}
      </div>
    </>
  );
};

export default Card;
