import React from "react";
import { IconProps } from "./types";
import styles from "./styles.module.scss";
import classnames from "classnames";

const Icon = ({
  name,
  color = "#02254A",
  width = 24,
  height = 24,
  dir,
  onClick,
  classesName
}: IconProps) => {
  let className = "";
  switch (dir) {
    case "left":
      className = styles.left;
      break;
    case "right":
      className = styles.right;
      break;
    case "top":
      className = styles.top;
      break;
    case "bottom":
      className = styles.bottom;
      break;
    default:
      className = styles.default;
  }
  return (
    <div
      className={classnames(className,classesName)}
      style={{ maxWidth: "fit-content" }}
      onClick={onClick}
    >
      <svg fill={color} width={width} height={height}>
        <use href={`/icons/dest/icons.svg#${name}`} />
      </svg>
    </div>
  );
};
export default Icon;
