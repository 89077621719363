import { getLocale } from "./getLocal";
import { isNil } from "lodash";
export const getPriceFormatted = (value: number, currency: string) => {
  if (isNil(value)) return null;
  try {
    const currencyFormatter = Intl.NumberFormat(getLocale(), {
      style: "currency",
      currency: currency,
      maximumFractionDigits: 2,
    });
    const parts = Number.isFinite(value)
      ? currencyFormatter?.formatToParts(value)
      : [];
    const formattedNumber = parts.length
      ? parts
          .filter((part) => part.type !== "currency")
          .reduce((result, part) => result + part.value, "")
      : "N/A";
    return `${formattedNumber}`;
  } catch (e) {
    return "Invalid currency code";
  }
};
