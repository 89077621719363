import axios, { AxiosError, AxiosInstance } from "axios";
import { getCookie } from "./commonUtils";
import { useContext } from "react";
import { AppContext } from "@src/libs/context";

const useClient = ({ setIsSessionExpired }: any) => {
  const { setIsSessionExpired: setSessionExpired } =
    useContext<any>(AppContext);

  const generateBasicHeaders = () => ({
    sessionId: getCookie("sessionid"),
  });

  const ServiceTypeHeaders = {
    basic: generateBasicHeaders(),
  } as Record<string, any>;

  // Added to handle multi clients/services with different base urls
  const ServiceTypeURL = {} as Record<string, string>;

  // Axios Instance
  const getAxiosClient = (type: string): AxiosInstance => {
    return axios.create({
      baseURL: ServiceTypeURL[type],
      responseType: "json",
      headers: ServiceTypeHeaders[type],
    });
  };

  // Added to handle multi clients/services with different base urls
  const client = {
    basic: getAxiosClient("basic"),
  } as Record<string, AxiosInstance>;

  const handleErrorResponse = async (error: AxiosError) => {
    if (error?.response?.status === 401) {
      console.log("Unauthenticated access");
      setSessionExpired ? setSessionExpired(true) : setIsSessionExpired(true);
      return error.response;
    }
    if (
      error?.response?.status === 403
    ) {
      console.log("Unauthorized access 403");
      throw new Error(error?.message);
    }
    if ((error?.response?.status ?? 0) >= 500) {
      console.log("Server error");
      return error.response;
    }
    return Promise.reject(error);
  };

  Object.keys(client).forEach((domain) => {
    client[domain].interceptors.response.use(
      (response) => response,
      (error) => handleErrorResponse(error)
    );
  });
  return client;
};

export default useClient;
