import { combineReducers } from "redux";
import mixedPallet from "../modules/MixedPallet/reducer";
import products from "../modules/Products/reducer";

import { configureStore } from "@reduxjs/toolkit";

export const reducer = combineReducers({
  mixedPallet,
  products,

});

export const store = configureStore({
  reducer,
});
