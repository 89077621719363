import { Select } from "antd";
import Icon from "../Icon";
import { SelectProps } from "./types";
import classnames from "classnames";
import styles from "./style.module.scss";
const SelectComponent = ({
  options,
  placeholder,
  className,
  placement,
  dropdownMatchSelectWidth,
  popupClassName,
  mode,
  showArrow,
  value,
  dropdownRender,
  onChange,
  defaultValue,
  clearIcon,
  allowClear,
  onDeselect,
  getPopupContainer,
  showSearch,
  filterOption,
}: SelectProps) => {
  const containerClasses = classnames(
    className == "manageAccountSelect" || className == "knowledgeHubSelect"
      ? styles[className || ""]
      : className
  );
  return (
    <Select
      options={options}
      placeholder={placeholder}
      className={containerClasses}
      placement={placement}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      popupClassName={popupClassName}
      mode={mode}
      showArrow={showArrow}
      suffixIcon={<Icon name="down" width={24} height={24}></Icon>}
      value={value}
      dropdownRender={dropdownRender}
      onChange={onChange}
      defaultValue={defaultValue}
      clearIcon={clearIcon}
      allowClear={allowClear}
      onDeselect={onDeselect}
      getPopupContainer={getPopupContainer}
      showSearch={showSearch}
      filterOption={filterOption}
    ></Select>
  );
};

export default SelectComponent;
