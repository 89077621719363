import React, { useContext, useEffect, useState } from "react";
import { Row, Form, Col, Modal } from "antd";
import Field from "@components/shared/Fields";
import CustomCheckbox from "@components/shared/Checkbox";
import Button from "@components/shared/Button";
import styles from "./styles.module.scss";
import { AppContext } from "@src/libs/context";
import SearchLocationInput from "src/components/shared/AutocompleteAddress";
import Script from "next/script";
import Select from "@components/shared/Select";
import { useWindowResize } from "@src/utilities/hooks/useWindowResize";
import {
  shippingHoursOption,
  getTruckSizesRestrictionsOptions,
} from "src/server/utils/commonUtils";
import { RuleObject } from "antd/es/form";
import { getText } from "~/src/libs/resources";
import LimitedAccessModal from "./LimitedAccessModal";
import Icon from "../Icon";
import { getMetroLimitedAccessOptions } from "./limitedAccessUtil";
import {
  normalizePhoneNumber,
  phoneValidator,
} from "~/src/libs/utils/phoneValidator";
interface AddressFormProps {
  updateAddress: React.Dispatch<React.SetStateAction<object>>;
  initialAddress: any;
  editAddress: boolean;
  errorMessage: any;
  setErrorMessage: React.Dispatch<React.SetStateAction<any>>;
}

const AddAddressForm = ({
  updateAddress,
  initialAddress,
  editAddress,
  errorMessage,
  setErrorMessage,
}: AddressFormProps) => {
  const [form] = Form.useForm();
  const [defaultValue, setDefaultValue] = useState(initialAddress?.address);
  const [showDeliveryModals, setShowDeliveryModals] = useState(false);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [disabledSave, setDisabledSave] = useState<boolean>(false);
  const [showHeightWeightFields, setShowHeightWeightFields] =
    useState<boolean>(false);
  const [isLimitedModalOpen, setIsLimitedModalOpen] = useState<boolean>(false);
  const [checkboxState, setCheckboxState] = useState({
    no_delivery_requirements: false,
    limited_access: false,
    appointment_required: false,
    inside_delivery_required: false,
    lift_gate_required: false,
    residential_delivery: false,
    disableOthers: false,
  });
  const handleDeliveryCheckboxChange = (e?: any) => {
    setShowDeliveryModals(true);
  };
  const handleCheckboxChange = (value: any) => {
    if (value === "limited_access") {
      setLimitedAccessSelected(!isLimitedAccessSelected);
      setIsLimitedModalOpen(!isLimitedAccessSelected);
    }

    if (value === "no_delivery_requirements") {
      handleDeliveryCheckboxChange();
      return;
    }
    setCheckboxState((prevState: any) => ({
      ...prevState,
      [value]: !prevState[value],
      disableOthers: false,
    }));
  };

  const handleModalOk = () => {
    setShowDeliveryModals(false);
    setCheckboxState((prevState) => ({
      ...prevState,
      no_delivery_requirements: true,
      limited_access: false,
      appointment_required: false,
      inside_delivery_required: false,
      lift_gate_required: false,
      residential_delivery: false,
      disableOthers: true,
    }));
    form.validateFields(["checkboxGroup"]);
  };

  const handleModalCancel = () => {
    setShowDeliveryModals(false);
    setCheckboxState((prevState) => ({
      ...prevState,
      no_delivery_requirements: false,
      disableOthers: false,
    }));
    form.validateFields(["checkboxGroup"]);
  };

  const isMobile = useWindowResize() <= 768;
  const {
    googlePlacesScript,
    setGooglePlacesScript,
    cart,
    commonStates,
    commonCountries,
    couponsName,
  } = useContext<any>(AppContext);
  const [isAddressFieldValid, setIsAddressFieldValid] = useState<boolean>(true);
  const [isLimitedAccessSelected, setLimitedAccessSelected] =
    useState<boolean>();
  const [isDefaultAddress, setIsDefaultAddress] = useState<boolean>(
    editAddress ? initialAddress?.is_default : false
  );
  const [limitedAccessValues, setLimitedAccessValues] = useState<any>();
  const [isOtherSelected, setIsOtherSelected] = useState<boolean>(false);

  const onFinish = async (values: any) => {
    setSaveLoader(true);
    const heightWeight = {
      restrictions_height: showHeightWeightFields
        ? form?.getFieldValue("restrictions_height")
        : "",
      restrictions_weight: showHeightWeightFields
        ? form?.getFieldValue("restrictions_weight")
        : "",
    };
    const metroLimitedAccessOther = {
      metro_limited_access_other: isOtherSelected
        ? form?.getFieldValue("metro_limited_access_other")
        : "",
    };
    updateAddress({
      ...values,
      ...checkboxState,
      is_default: isDefaultAddress,
      ...heightWeight,
      ...limitedAccessValues,
      ...metroLimitedAccessOther,
    });
    shippingInfoHandler();
  };
  const handleSelectLocation = (value: any) => {
    form.setFieldValue("state", value?.state);
    form.setFieldValue("city", value?.city);
    form.setFieldValue("country", value?.country);
    form.setFieldValue("post_zip", value?.zipCode);
    setDefaultValue(value?.formatted_address);
    form.setFieldValue("address", value?.formatted_address);
    form.validateFields(["state", "city", "country", "post_zip", "address"]);
  };

  const handleSelectAddress = (value: any) => {
    const items: any = document.getElementsByClassName("pac-container");
    for (let i = 0; i < items.length; i++) {
      document.getElementById("pac-container-parent")?.append(items[i]);
    }
    form.setFieldValue("address", value);
    form.validateFields(["address"]);
  };
  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabledSave(hasErrors);
  };
  useEffect(() => {
    setSaveLoader(false);
  }, []);
  useEffect(() => {
    if (editAddress) {
      const isNoDeliveryChecked = initialAddress?.no_delivery_requirements;
      setCheckboxState({
        no_delivery_requirements: isNoDeliveryChecked,
        residential_delivery: isNoDeliveryChecked
          ? false
          : initialAddress?.residential_delivery,
        lift_gate_required: isNoDeliveryChecked
          ? false
          : initialAddress?.lift_gate_required,
        inside_delivery_required: isNoDeliveryChecked
          ? false
          : initialAddress?.inside_delivery_required,
        appointment_required: isNoDeliveryChecked
          ? false
          : initialAddress?.appointment_required,
        limited_access: isNoDeliveryChecked
          ? false
          : initialAddress?.limited_access,
        disableOthers: isNoDeliveryChecked ? true : false,
      });

      const normalizedPhoneNumber = normalizePhoneNumber(
        initialAddress?.phone_number
      );
      form.setFieldValue("phone_number", normalizedPhoneNumber);
      if (initialAddress?.truck_size_restrictions == "") {
        form.setFieldValue("truck_size_restrictions", undefined);
      }
      setShowHeightWeightFields(
        editAddress && initialAddress?.truck_size_restrictions !== "None"
      );
      if (initialAddress?.limited_access) setLimitedAccessSelected(true);
      if (initialAddress?.metro_limited_access === "Other")
        setIsOtherSelected(true);
    }
  }, [editAddress]);

  const phoneValidation = (
    _: RuleObject,
    value: any,
    max: number,
    required?: boolean
  ) => {
    if (!value && required) {
      return Promise.reject("This field should not be empty.");
    }
    if (!value && !required) {
      return Promise.resolve();
    }
    if (isNaN(value) || value.toString().includes(".")) {
      return Promise.reject("Should be a number.");
    }
    if (value.length > max) {
      return Promise.reject(`No more than ${max} characters.`);
    }
    return Promise.resolve();
  };

  const locationValidation = (_: RuleObject, value: any) => {
    if (!value) {
      return Promise.reject("This field should not be empty.");
    }
    if (value.length > 200) {
      return Promise.reject(
        "Ensure this field has no more than 200 characters."
      );
    }
    return Promise.resolve();
  };

  const addressValidation = (
    _: RuleObject,
    fieldName: string,
    value: string
  ) => {
    if (errorMessage?.data) {
      const keys = Object.keys(errorMessage?.data);
      if (keys?.includes(fieldName)) {
        const message = errorMessage?.data[fieldName];
        setErrorMessage(null);
        return Promise.reject(message || `Invalid ${fieldName}`);
      }
    } else if (!value) {
      return Promise.reject("This field should not be empty.");
    } else {
      return Promise.resolve();
    }
  };
  const addressFieldValidation = (
    _: RuleObject,
    value: string,
    fieldName: string
  ) => {
    if (fieldName === "address" && !value) {
      setIsAddressFieldValid(false);
      return Promise.reject("This field should not be empty.");
    } else if (fieldName === "address" && value) {
      setIsAddressFieldValid(true);
      return Promise.resolve();
    }
  };
  useEffect(() => {
    if (errorMessage?.data) {
      form.validateFields(["state", "city", "country", "post_zip", "address"]);
      setSaveLoader(false);
    }
  }, [errorMessage]);

  const onFocus = () => {
    if (googlePlacesScript) {
      const items: any = document.getElementsByClassName("pac-container");
      for (let i = 0; i < items.length; i++) {
        document.getElementById("pac-container-parent")?.append(items[i]);
      }
    }
  };

  const shippingInfoHandler = () => {
    const cartItems: any = [];
    cart?.pallets?.forEach(function (item: any, index: number) {
      if (item?.pallet_type === "PALLET") {
        const itemInfo = {
          item_id: item?.pallet_items?.[0]?.product?.id?.toString(),
          item_name: item?.pallet_items?.[0]?.product?.name,
          coupon: couponsName,
          currency: "USD",
          discount: item?.total_delivered_discount,
          index: index,
          item_brand: item?.pallet_items?.[0]?.product?.brand?.name,
          item_category: item?.pallet_items?.[0]?.product?.main_category_name,
          item_category2: item?.pallet_items?.[0]?.product?.sub_category_name,
          item_category3: "",
          item_category4: "",
          item_list_id: "",
          item_list_name: item?.pallet_items?.[0]?.product?.sub_category_name,
          item_variant: item?.pallet_items?.[0]?.product?.pack_size,
          price: Number(
            (item?.total_delivered_price / item?.quantity).toFixed(2)
          ),
          quantity: item?.quantity,
        };
        cartItems?.push(itemInfo);
      } else {
        const itemInfoMixed = {
          item_id: item?.id?.toString(),
          item_name: "Mixed Pallet",
          coupon: couponsName,
          currency: "USD",
          discount: item?.total_delivered_discount,
          index: index,
          item_brand: item?.sold_by_vendor,
          item_category: item?.pallet_items?.[0]?.product?.main_category_name,
          item_category2: item?.pallet_items?.[0]?.product?.sub_category_name,
          item_category3: "",
          item_category4: "",
          item_list_id: "",
          item_list_name: item?.pallet_items?.[0]?.product?.sub_category_name,
          item_variant: "mixed pallet",
          price: Number(
            (item?.total_delivered_price / item?.quantity).toFixed(2)
          ),
          quantity: item?.quantity,
          selected_product1: item?.pallet_items?.[0]?.product?.name || "",
          selected_product2: item?.pallet_items?.[1]?.product?.name || "",
          selected_product3: item?.pallet_items?.[2]?.product?.name || "",
          selected_product4: item?.pallet_items?.[3]?.product?.name || "",
          selected_product5: item?.pallet_items?.[4]?.product?.name || "",
          selected_product6: item?.pallet_items?.[5]?.product?.name || "",
          selected_product7: item?.pallet_items?.[6]?.product?.name || "",
          selected_product8: item?.pallet_items?.[7]?.product?.name || "",
          selected_product9: item?.pallet_items?.[8]?.product?.name || "",
          selected_product10: item?.pallet_items?.[9]?.product?.name || "",
          quantity1: item?.pallet_items?.[0]?.case_quantity || "",
          quantity2: item?.pallet_items?.[1]?.case_quantity || "",
          quantity3: item?.pallet_items?.[2]?.case_quantity || "",
          quantity4: item?.pallet_items?.[3]?.case_quantity || "",
          quantity5: item?.pallet_items?.[4]?.case_quantity || "",
          quantity6: item?.pallet_items?.[5]?.case_quantity || "",
          quantity7: item?.pallet_items?.[6]?.case_quantity || "",
          quantity8: item?.pallet_items?.[7]?.case_quantity || "",
          quantity9: item?.pallet_items?.[8]?.case_quantity || "",
          quantity10: item?.pallet_items?.[9]?.case_quantity || "",
        };
        cartItems?.push(itemInfoMixed);
      }
    });
    window?.dataLayer?.push({ ecommerce: null });
    window?.dataLayer?.push({
      event: "add_shipping_info",
      ecommerce: {
        currency: "USD",
        value: cart?.grand_total_delivered_price,
        coupon: couponsName,
        shipping_tier: "",
        items: cartItems,
      },
    });
  };
  const onRestrictionChange = (value: string) => {
    if (value === "None") {
      setShowHeightWeightFields(false);
      form?.setFieldValue("restrictions_height", "");
      form?.setFieldValue("restrictions_weight", "");
    } else setShowHeightWeightFields(true);
  };

  const onMetroLimitedSelectChange = (value: string) => {
    setIsOtherSelected(value === "Other");
  };

  const validateCheckboxGroup = async () => {
    const hasChecked = Object.values(checkboxState).some((val) => val);
    if (!hasChecked) {
      return Promise.reject(new Error("Please select at least one option."));
    }
    return Promise.resolve();
  };

  return (
    <div className={styles.formContainer}>
      <LimitedAccessModal
        isLimitedModalOpen={isLimitedModalOpen}
        setIsLimitedModalOpen={setIsLimitedModalOpen}
        setLimitedAccessValues={setLimitedAccessValues}
        initialAddress={editAddress ? initialAddress : null}
        isLimitedAccessSelected={isLimitedAccessSelected}
        limitedAccessValues={limitedAccessValues}
      />
      <Form
        className={styles.formWrapper}
        onFinish={onFinish}
        onFieldsChange={handleFormChange}
        form={form}
        autoComplete="off"
        initialValues={editAddress ? initialAddress : {}}
      >
        <Row justify="space-between" align="middle" className="mb-8">
          <h3 className={`mb-0 ${isMobile ? "font-bold-h5" : "font-bold-h4"}`}>
            {editAddress ? `Edit Shipping Address` : "Add New Address"}
          </h3>
        </Row>
        <Form.Item
          name="name"
          rules={[
            () => ({
              validator: (_, value) => locationValidation(_, value),
            }),
          ]}
        >
          <Field className={styles.field} placeholder="Location name" />
        </Form.Item>
        <Form.Item
          name="contact_name"
          rules={[
            { required: true, message: "This field should not be empty." },
          ]}
        >
          <Field className={styles.field} placeholder="Contact name" />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: "This field should not be empty." },
            { type: "email", message: "Please input a valid email." },
          ]}
        >
          <Field className={styles.field} placeholder="Contact Email" />
        </Form.Item>
        <Form.Item
          name="address"
          rules={[
            () => ({
              validator: (_, value) =>
                addressFieldValidation(_, value, "address"),
            }),
          ]}
        >
          <>
            {!googlePlacesScript && (
              <Script
                src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_PLEACES_KEY}&libraries=places`}
                onLoad={() => {
                  setGooglePlacesScript(true);
                }}
              />
            )}
            {googlePlacesScript && (
              <>
                <SearchLocationInput
                  className={"border-radius-zero addressStyles"}
                  disabled={false}
                  placeHolder="Address"
                  defaultValue={editAddress ? defaultValue : ""}
                  extraAction={handleSelectLocation}
                  name="currentLocation"
                  types={["address"]}
                  isBilling={false}
                  onChange={handleSelectAddress}
                  validInput={isAddressFieldValid}
                  onFocus={onFocus}
                  smallFontSize={true}
                />
                <div
                  id="pac-container-parent"
                  className={styles["pac-container-parent"]}
                ></div>
              </>
            )}
          </>
        </Form.Item>

        <Row gutter={16}>
          <Col xs={{ span: 12 }}>
            <Form.Item
              name="post_zip"
              rules={[
                () => ({
                  validator: (_, value) =>
                    addressValidation(_, "post_zip", value),
                }),
              ]}
            >
              <Field className={styles.field} placeholder="Zip code" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 12 }}>
            <Form.Item
              name="state"
              rules={[
                () => ({
                  validator: (_, value) => addressValidation(_, "state", value),
                }),
              ]}
            >
              <Select
                options={commonStates}
                className={`${styles.field} ${styles.customSelect}`}
                showSearch={true}
                filterOption={(input: string, option: any) =>
                  (option?.label ?? "")
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase())
                }
                placeholder="State"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={{ span: 12 }}>
            <Form.Item
              name="city"
              rules={[
                () => ({
                  validator: (_, value) => addressValidation(_, "city", value),
                }),
              ]}
            >
              <Field className={styles.field} placeholder="City" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 12 }}>
            <Form.Item
              name="country"
              rules={[
                () => ({
                  validator: (_, value) =>
                    addressValidation(_, "country", value),
                }),
              ]}
            >
              <Select
                options={commonCountries}
                className={`${styles.field} ${styles.customSelect}`}
                placeholder="Country"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={{ span: 12 }} xs={{ span: 14 }}>
            <Form.Item
              name="phone_number"
              rules={[
                () => ({
                  validator: (_, value) =>
                    phoneValidator(_, value, "phone_number"),
                }),
              ]}
              normalize={normalizePhoneNumber}
            >
              <Field
                className={styles.field}
                placeholder="Facility phone number"
              />
            </Form.Item>
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 10 }}>
            <Form.Item
              name="phone_ext"
              rules={[
                () => ({
                  validator: (_, value) => phoneValidation(_, value, 6, false),
                }),
              ]}
            >
              <Field className={styles.field} placeholder="Phone Ext" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="facility_contact_name"
          rules={[
            { required: true, message: "This field should not be empty." },
          ]}
        >
          <Field className={styles.field} placeholder="Facility contact name" />
        </Form.Item>
        <Row gutter={16}>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="facility_contact_email"
              rules={[
                { required: true, message: "This field should not be empty." },
                { type: "email", message: "Please input a valid email." },
              ]}
            >
              <Field className={styles.field} placeholder="Facility email" />
            </Form.Item>
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="truck_size_restrictions"
              rules={[
                { required: true, message: "This field should not be empty." },
              ]}
            >
              <Select
                placeholder="Truck size restrictions"
                options={getTruckSizesRestrictionsOptions()}
                className={`${styles.field} ${styles.customSelect}`}
                getPopupContainer={(trigger: any) => trigger?.parentElement}
                onChange={onRestrictionChange}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        {showHeightWeightFields && (
          <Row gutter={16}>
            <Col xs={{ span: 12 }}>
              <Form.Item
                name="restrictions_height"
                rules={[{ max: 200, message: "No more than 200 characters." }]}
              >
                <Field
                  className={styles.field}
                  placeholder="Facility height restrictions?"
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 12 }}>
              <Form.Item
                name="restrictions_weight"
                rules={[{ max: 200, message: "No more than 200 characters." }]}
              >
                <Field
                  className={styles.field}
                  placeholder="Facility weight restrictions?"
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={16}>
          <Col xs={{ span: 24 }}>
            <Form.Item name="metro_limited_access">
              <Select
                options={getMetroLimitedAccessOptions()}
                className={`${styles.field} ${styles.customSelect}`}
                placeholder="Please select Metro Area (Optional)"
                getPopupContainer={(trigger: any) => trigger?.parentElement}
                onChange={onMetroLimitedSelectChange}
              />
            </Form.Item>
          </Col>
          {isOtherSelected && (
            <Col xs={{ span: 24 }}>
              <Form.Item
                name="metro_limited_access_other"
                rules={[
                  { max: 250, message: "No more than 250 characters." },
                  {
                    required: true,
                    message: "This field should not be empty.",
                  },
                ]}
              >
                <Field className={styles.field} placeholder="Other" />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={[16, 12]} className="">
          <Col span={24} className="font-medium-base text-oxfordBlue">
            <Form.Item name="is_default">
              <CustomCheckbox
                onChange={() => setIsDefaultAddress(!isDefaultAddress)}
                label={getText()?.Account?.defaultAddress}
                value="is_default"
                checked={isDefaultAddress}
                disabled={editAddress ? initialAddress?.is_default : false}
              />
            </Form.Item>
          </Col>
        </Row>
        <h4
          className={`mb-8 ${
            isMobile ? "font-semibold-h5" : "font-semibold-base lh-sm"
          }`}
        >
          {getText()?.Account?.AddressForm?.shippingHours}
        </h4>
        <Row gutter={16}>
          <Col xs={{ span: 12 }}>
            <Form.Item
              name="for_delivery_from"
              rules={[
                { required: true, message: "This field should not be empty." },
              ]}
            >
              <Select
                options={shippingHoursOption}
                className={`${styles.field} ${styles.customSelect}`}
                placeholder="From"
                getPopupContainer={(trigger: any) => trigger?.parentElement}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 12 }}>
            <Form.Item
              name="for_delivery_to"
              rules={[
                { required: true, message: "This field should not be empty." },
              ]}
            >
              <Select
                options={shippingHoursOption}
                className={`${styles.field} ${styles.customSelect}`}
                placeholder="To"
                getPopupContainer={(trigger: any) => trigger?.parentElement}
              />
            </Form.Item>
          </Col>
        </Row>
        <Modal
          open={showDeliveryModals}
          onCancel={handleModalCancel}
          onOk={handleModalOk}
          okText="Complete now"
          cancelText="Cancel"
          centered
          closable={false}
          maskClosable={false}
        >
          <div className="text-center">
            <p className="font-semibold-base">
              {getText()?.Account?.AddressForm?.deliveryModalTitle}
            </p>
            <p>{getText()?.Account?.AddressForm?.deliveryModalContent}</p>
          </div>
        </Modal>
        <Form.Item
          name="checkboxGroup"
          rules={[
            {
              validator: validateCheckboxGroup,
            },
          ]}
        >
          <Row className={styles?.checkboxGroup}>
            <Col span={24}>
              <CustomCheckbox
                label={getText()?.Account?.AddressForm?.noDeliveryRequirements}
                value="no_delivery_requirements"
                hasInfoIcon={true}
                checked={checkboxState.no_delivery_requirements}
                onChange={() =>
                  handleCheckboxChange("no_delivery_requirements")
                }
              />
              <CustomCheckbox
                label={
                  getText()?.Account?.AddressForm?.residentialDeliveryRequired
                }
                value="residential_delivery"
                hasInfoIcon={true}
                checked={checkboxState.residential_delivery}
                onChange={() => handleCheckboxChange("residential_delivery")}
                disabled={checkboxState?.disableOthers}
              />
              <CustomCheckbox
                label={getText()?.Account?.AddressForm?.liftGateRequirements}
                value="lift_gate_required"
                hasInfoIcon={true}
                checked={checkboxState.lift_gate_required}
                onChange={() => handleCheckboxChange("lift_gate_required")}
                disabled={checkboxState?.disableOthers}
              />
              <CustomCheckbox
                label={getText()?.Account?.AddressForm?.insideDeliveryRequired}
                value="inside_delivery_required"
                hasInfoIcon={true}
                checked={checkboxState.inside_delivery_required}
                onChange={() =>
                  handleCheckboxChange("inside_delivery_required")
                }
                disabled={checkboxState?.disableOthers}
              />
              <CustomCheckbox
                label={
                  getText()?.Account?.AddressForm?.deliveryAppointmentRequired
                }
                value="appointment_required"
                hasInfoIcon={true}
                checked={checkboxState.appointment_required}
                onChange={() => handleCheckboxChange("appointment_required")}
                disabled={checkboxState?.disableOthers}
              />
              <CustomCheckbox
                label={getText()?.Account?.AddressForm?.limitedAccess}
                value="limited_access"
                hasInfoIcon={true}
                checked={checkboxState.limited_access}
                onChange={() => handleCheckboxChange("limited_access")}
                disabled={checkboxState?.disableOthers}
              />
            </Col>
            {(isLimitedAccessSelected && !checkboxState?.disableOthers) &&(
              <Col span={24}>
                <Button
                  hasIcon={true}
                  className={styles?.showMoreIcon}
                  theme="link"
                  onClick={() => setIsLimitedModalOpen(true)}
                  icon={<Icon classesName={styles?.icon} name="arrow" />}
                  text={getText()?.Account?.AddressForm?.selectLimitedAccess}
                />
              </Col>
            )}
          </Row>
        </Form.Item>
        <Button
          className={styles.updateBtn}
          theme="secondary"
          onClick={() => {
            form?.submit();
          }}
          disabled={disabledSave}
          loading={saveLoader}
        >
          {getText()?.Account?.AddressForm?.save}
        </Button>
      </Form>
    </div>
  );
};

export default AddAddressForm;
