import styles from "./styles.module.scss";
import { Col, Divider, Row } from "antd";
import Link from "next/link";
import { usePriceFormat } from "@src/utilities/hooks/usePriceFormat";
import { getText } from "~/src/libs/resources";
import Image from "next/image";
const OrderCard = ({ item, orderInfo, type, quantity }: any) => {
  return (
    <Row className={`${styles.card} p-8`} gutter={[12, 12]}>
      <Col>
        <Image
          unoptimized
          width={100}
          height={100}
          src={item?.product?.thumbnail}
          alt={item?.product?.name}
        />
      </Col>
      <Col style={{ flexGrow: 1 }} className="pt-6">
        <Link href={`/product/${item?.product?.slug}`}>
          <a className={styles.productLink}>
            <p
              className={`${styles.title} ${styles.productName} font-semibold-sm mb-0 pb-2`}
            >
              {item?.product?.brand?.name}
            </p>

            <p className={`${styles.productName} font-bold-h4 mb-4`}>
              {item?.product?.name}
            </p>
          </a>
        </Link>
        <div className="font-semibold-xs">
          <span>
            {getText()?.Account?.perCase}
            ${usePriceFormat(
              usePriceFormat(parseFloat(item?.delivered_case_price))
            )}
          </span>
          <Divider
            type="vertical"
            style={{
              margin: "0 16px",
              top: 0,
              background: "#898989",
              opacity: 0.2,
            }}
          />
          <span>
            <span className="pe-1">{type === "MIXED_PALLET" ? getText()?.MixedPallet?.caseQuantity : getText()?.Account?.quantity}:</span>
            {type === "MIXED_PALLET" ? item?.total_case_quantity : quantity}
          </span>
        </div>
        {orderInfo?.customer_po && (
          <Row className="font-bold-base mb-8 mt-4">
            {getText()?.Account?.purchaseOrder}:
            <span className="ps-2">{orderInfo?.customer_po}</span>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default OrderCard;
