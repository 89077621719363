import React, { memo, useState } from "react";
import { Modal, Form, Row, Col } from "antd";
import { FormProps } from "./types";
import classnames from "classnames";
import CustomInput from "@components/shared/Fields";
import { FormFields } from "./FormFields";
import styles from "./styles.module.scss";
import Button from "@components/shared/Button";
import axios from "axios";
import Icon from "@components/shared/Icon";
import { getText } from "~/src/libs/resources";
import { notification } from "antd";

const RequestForm = ({ isModalOpen, setIsModalOpen }: FormProps) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "auto";
  };

  const onFinish = async (values: any) => {
    const data = {
      phone_number: values?.phoneNumber,
      contact_name: values?.name,
      email: values?.email,
      phone_ext: values?.PhoneExt,
      product_name: values?.productName,
      reference_product_link: values?.productLink,
    };

    try {
      await axios.post("/api/ERP/requestProduct", { data });
      setShowSuccessModal(true);
      setTimeout(() => {
        setShowSuccessModal(false);
      }, 3000);
      setIsModalOpen(false);
      document.body.style.overflow = "auto";
    } catch (e: any) {
      if (e.response && e.response.status === 429) {
        notification.error({
          message: 'Too Many Requests',
          description: 'You have made too many requests. Please try again later.',
          duration: 4,
        });
      } else {
        notification.error({
          message: 'Error',
          description: 'An error occurred while submitting your request.',
          duration: 4,
        });
      }
      console.error("Request product:", e);
    }
  };

  return (
    <>
      <Modal
        title="Request It"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        className={classnames(styles.modal, "bg-white pb-0")}
        destroyOnClose={true}
      >
        <Form
          name="Request It"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          preserve={false}
          autoComplete="off"
          className={classnames("py-8 px-10")}
        >
          {FormFields?.map((field, index) => (
            <div key={field?.name}>
              {field?.name !== "phoneNumber" ? (
                <Form.Item
                  key={index}
                  name={field?.name}
                  className={styles.formItem}
                  rules={
                    field?.name == "email"
                      ? [
                          {
                            required: true,
                            type: "email",
                            message: "Invalid email",
                          },
                        ]
                      : field?.rules
                  }
                  hasFeedback
                >
                  <CustomInput
                    placeholder={`${field?.placeHolder}${
                      field?.required ? "*" : ""
                    }`}
                    className={classnames(styles.customInput, "my-3 py-4")}
                  />
                </Form.Item>
              ) : (
                <Row>
                  <Col span={16} key={index}>
                    <Form.Item
                      key={index}
                      name={field?.name}
                      className={classnames(styles.numberForm, "me-4")}
                      rules={field?.rules}
                      hasFeedback
                    >
                      <CustomInput
                        placeholder={`${field?.placeHolder}${
                          field?.required ? "*" : ""
                        }`}
                        className={classnames(styles.customInput, "my-3 py-4")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      key={`${index}ext`}
                      name="PhoneExt"
                      className="ms-4"
                    >
                      <CustomInput
                        placeholder="Phone Ext"
                        className={classnames(styles.customInput, "my-3 py-4")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </div>
          ))}

          <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
            <Button
              theme="primary"
              htmlType="submit"
              className={classnames(
                styles.requestBttn,
                "py-12 font-bold-base mt-4"
              )}
            >
              {getText()?.PLP?.RequestForm?.sendRequest}
            </Button>
          </Form.Item>
          <div className={classnames(styles.description, "font-regular-sm")}>
            <p>{getText()?.PLP?.RequestForm?.ePalletDefinition}</p>
          </div>
        </Form>
      </Modal>
      <Modal open={showSuccessModal} footer={null} closable={false}>
        <div className={styles.successModal}>
          <Icon name="check" color="darkBlue" width={150} height={100} />
          <div className="font-medium-h6 text-oxfordBlue mt-2">
            {getText()?.PLP?.RequestForm?.requestSent}
          </div>
        </div>
      </Modal>
    </>
  );
};
export default memo(RequestForm);
