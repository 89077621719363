import React, { useState } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";

const MixedPalletContext = React.createContext({});
const MixedPalletProvider = ({ children }) => {
  const router = useRouter();
  const [palletItems, setPalletItems] = useState([]);
  const [isFilteredLoaded, setIsFilteredLoaded] = useState(true)
  const [loading, setLoading] = useState(false);
  const [vendorName, setVendorName] = useState("");
  const [selectedPalletFilters, setSelectedPalletFilters] = useState([]);
  const [vendorId, setVendorId] = useState(0);
  const [vendorProductsCount, setVendorProductsCount] = useState(0);
  const [vendorProducts, setVendorProducts] = useState([]);
  const [palletName, setPalletName] = useState("");
  const [palletLoadedProgress, setPalletLoadedProgress] = useState(0);
  const [palletId, setPalletId] = useState(router.query["pallet"]);
  const [palletPrice, setPalletPrice] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [productInfo, setProductInfo] = useState({});
  const [attributeGroups, setAttributeGroups] = useState({});
  const [viewDetails, setViewDetails] = useState(false);
  const [pricesData, setPricesData] = useState(null);
  const [sku, setSku] = useState("");
  const [sortByVal, setSortByVal] = useState(router.query["ordering"]);
  const [searchVal, setSearchVal] = useState(router.query["search"]);
  const [palletFilters, setPalletFilters] = useState([]);
  const [appliedPalletFilters, setAppliedPalletFilters] = useState([]);
  const [next, setNext] = useState(null);
  const [accessRequested, setAccessRequested] = useState(false);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <MixedPalletContext.Provider
      value={{
        productInfo,
        setProductInfo,
        attributeGroups,
        setAttributeGroups,
        pricesData,
        setPricesData,
        sku,
        setSku,
        viewDetails,
        setViewDetails,
        palletItems,
        setPalletItems,
        loading,
        setLoading,
        vendorName,
        setVendorName,
        vendorId,
        setVendorId,
        vendorProducts,
        setVendorProducts,
        palletName,
        setPalletName,
        palletLoadedProgress,
        setPalletLoadedProgress,
        palletId,
        setPalletId,
        palletPrice,
        setPalletPrice,
        isLoaded,
        setIsLoaded,
        sortByVal,
        setSortByVal,
        searchVal,
        setSearchVal,
        palletFilters,
        setPalletFilters,
        appliedPalletFilters,
        setAppliedPalletFilters,
        next,
        setNext,
        accessRequested,
        setAccessRequested,
        offset,
        setOffset,
        vendorProductsCount, setVendorProductsCount, currentPage, setCurrentPage,
        selectedPalletFilters, setSelectedPalletFilters, isFilteredLoaded, setIsFilteredLoaded
      }}
    >
      {children}
    </MixedPalletContext.Provider>
  );
};

MixedPalletProvider.propTypes = {
  children: PropTypes.array
};

export { MixedPalletContext, MixedPalletProvider };
