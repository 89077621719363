export const FormFields = [
  {
    name: "email",
    placeHolder: "Your Email ",
    required: true,
    rules: [
      {
        required: true,
        message: "Your email is required"
      },
      { whitespace: true },
      {
        pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
        message: "Invalid email"
      }
    ]
  },
  {
    name: "name",
    placeHolder: "Your Name ",
    required: true,
    rules: [
      {
        required: true,
        message: "Your name is required"
      },
      { whitespace: true },
      { min: 3, message: " Must be at least 3 characters" }
    ]
  },

  {
    name: "comments",
    placeHolder: "Comments [optional]",
    required: false,
    rules: [
      {
        required: false
      },
      { whitespace: true },
      { min: 3, message: " Must be at least 3 characters" }
    ]
  }
];

export const RecipientEmail = {
  name: "recipient-email",
  placeHolder: "Recipient Email ",
  required: true,
  rules: [
    {
      required: true,
      type: "email",
      message: "Recipient email is required"
    },
    { whitespace: true },
    {
      pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
      message: "Invalid email"
    }
  ]
};

export const RecipientName = {
  name: "recipient-name",
  placeHolder: "Recipient Name ",
  required: true,
  rules: [
    {
      required: true,
      message: "Recipient name is required"
    },
    { whitespace: true },
    { min: 3, message: " Must be at least 3 characters" }
  ]
};
