import { logoutHandler } from "src/actions/logoutHandler";
import { Modal } from "antd";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "~/src/libs/context";
import { getText } from "@src/libs/resources";
import { useRouter } from "next/router";
export const ExpirationModal = () => {
  const { isSessionExpired, setIsSessionExpired } = useContext<any>(AppContext);
  const [modalVisible, setModalVisible] = useState(false);
  const router = useRouter();
  useEffect(() => {
    setModalVisible(isSessionExpired);
  }, [isSessionExpired]);

  useEffect(() => {
    const prevPath = localStorage.getItem("currentPath") || router.asPath;
    const decodedPrevPath = decodeURIComponent(prevPath);
    localStorage.setItem("redirectionUrl", decodedPrevPath);
  }, []);

  if (isSessionExpired && !modalVisible) {
    const { confirm } = Modal;
    confirm({
      title: getText()?.ExpirationModal?.title,
      okText: getText()?.ExpirationModal?.okText,
      cancelText: getText()?.ExpirationModal?.cancelText,
      centered: true,
      onOk() {
        logoutHandler("/?epallet_login=true");
        setModalVisible(false);
        setIsSessionExpired(false);
      },
      onCancel() {
        logoutHandler("/");
        setModalVisible(false);
        setIsSessionExpired(false);
      },
    });
  }
  return null;
};
