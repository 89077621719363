import React, { useState } from "react";
import { Table } from "antd";
import styles from "./styles.module.scss";
import HaggleModal from "../HaggleModal/HaggleModal";
import Button from "../Button";

interface HaggleTableProps {
  tableTitle: string;
  tableData: any;
  disclaimerMessage: string;
  modal?: boolean;
}

const HaggleTable = ({ tableTitle, tableData, disclaimerMessage, modal }: HaggleTableProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const columns: any = [
    {
      title: "HAGGLE",
      dataIndex: "title",
      key: "title",
      fixed: "left",
      minWidth: "142px",
      render: (text: any, record: any) => (
        <div className="py-4">
          <h2 className="font-bold-h5 text-darkGray mb-1">{record.title}</h2>
          <p className="text-darkGray mb-0">{record.description}</p>
        </div>
      ),
    },
    {
      title: "All",
      dataIndex: "all",
      key: "all",
    },
    {
      title: "Tier 1",
      dataIndex: "tier1",
      key: "tier1",
    },
    {
      title: "Tier 2",
      dataIndex: "tier2",
      key: "tier2",
    },
    {
      title: "Tier 3",
      dataIndex: "tier3",
      key: "tier3",
    },
  ];

  const dataSource = tableData.map((data: any, index: number) => ({
    key: index,
    ...data,
  }));

  return (
    <div className={`container ${styles.haggleTableWrapper}`}>
      <HaggleModal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />
      {tableTitle && (
          <h1
            className={`font-bold-h1 text-center mb-33 mt-40 pt-3 ${styles.tableTitle}`}
          >
            {tableTitle}
          </h1>
      )}
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        className={styles.haggleTable}
        rowClassName={styles.tableRow}
        scroll={{ x: true }}
      />

      {disclaimerMessage &&<p className={`pt-20 mb-24 ps-8 font-medium-xs ${styles.disclaimerMessage}`}>{disclaimerMessage}</p>}
      {!modal && (
        <div className={`w-100 text-center ${styles.buttonWrapper}`}>
          <Button
            className="mt-2 font-bold-base p-8 h-auto px-21"
            text="Schedule Demo"
            theme="primary"
            onClick={handleOpenModal}
          />
        </div>
      )}
    </div>
  );
};

export default HaggleTable;
