import { useState, useEffect, useContext, useRef } from "react";
import SearchInput from "@components/shared/Fields";
import styles from "../styles.module.scss";
import SearchIcon from "@assets/icons/search.svg";
import Icon from "@components/shared/Icon";
import CustomButton from "@components/shared/Button";
import Search from "@assets/icons/search.svg";
import { useRouter } from "next/router";
import { AppContext } from "../../../../../../libs/context";
import axios from "axios";
import { getCookie } from "~/src/server/utils/commonUtils";
import { useWindowResize } from "@src/utilities/hooks/useWindowResize.js";
import _ from "lodash";
import useSearch from "~/src/libs/utils/useSearch";
import { customEncodeURIComponent } from "~/src/libs/utils/searchRouter";
const SearchBox: React.FC<any> = ({
  showMenu,
  closeMenu,
  getItemId,
  view,
  setData,
  data,
  isSearchPage,
  isComparePage,
  searchRef,
  isTrendingHandler,
}) => {
  const {
    setSearchedData,
    setTyping,
    isTyping,
    setOpenMenuSearch,
    setOpenMiniCart,
    openMenuSearch,
    inputValue,
    setInputValue,
    setOpenStoreSearch
  } = useContext<any>(AppContext);
  const router = useRouter();
  const [hasIcon, setHasIcon] = useState(true);
  const [localQuery, setLocalQuery] = useState(inputValue ?? "");
  const [compareValue, setCompareValue] = useState("");
  const windowWidth = useWindowResize();
  const cancelTokenSourceRef: any = useRef(null);
  const isHeaderSearch = true;
  
  const { getSearchItems } = useSearch({
    setData,
    localQuery,
    cancelTokenSourceRef,
    isHeaderSearch,
  });

  const fetchData = async () => {
    if (cancelTokenSourceRef.current) {
      console.log("Cancelling previous request");
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSourceRef.current = axios.CancelToken.source();
    getSearchItems();
  };

  const saveSearchedItem = async () => {
    try {
      await axios.post(`/api/ERP/search/saveSearchedItem`, {
        headers: {
          sessionId: getCookie("sessionid"),
        },
        data: { search: localQuery },
      });
    } catch (error: any) {}
  };

  const handelInputChange = (e: any) => {
    setTyping(e.currentTarget.value?.length);
    setLocalQuery(e.currentTarget.value);
    if(isComparePage){
      setCompareValue(e.currentTarget.value)
      }
    else{
      if (e.currentTarget.value.trim() !== "") {
        setInputValue(e.currentTarget.value);
      } else {
        setInputValue("");
      }
    }
    if (e.currentTarget.value) {
      setHasIcon(false);
    } else {
      setHasIcon(true);
    }
  };
  const handelMobileInputChange = (e: any) => {
    setTyping(e?.currentTarget?.value?.length);
    setLocalQuery(e.currentTarget.value);
    if(isComparePage){
    setCompareValue(e.currentTarget.value)
    }else{
      setInputValue(e.currentTarget.value);
    }
    if (e.currentTarget.value) {
      setHasIcon(false);
    } else {
      setHasIcon(true);
    }
  };
  const handleEnterKey = (e: any) => {
    window?.dataLayer?.push({
      event: "search",
      search_term: localQuery,
    });
    saveSearchedItem();
    setSearchedData(data);
    isTrendingHandler?.(true);
    e.target.blur();
    if (e.currentTarget.value.trim() !== "") {
      router.push(`/search/${customEncodeURIComponent(localQuery as string)}`);
    } else {
      setLocalQuery("");
    }
    closeMenu();
    setHasIcon(true);
  };
  const showMobileMenu = () => {
    setOpenMenuSearch(true);
    setOpenMiniCart(false);
    showMenu();
  };
  const closeMobileMenu = () => {
    setOpenMenuSearch(false);
    closeMenu();
  };
  const mobileFocus = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to new request."
      );
    }
    setOpenStoreSearch(false)
    if (!localQuery) {
      fetchData();
    }
    setOpenMenuSearch(true);
  };
  const showMenuDesktop = async () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to new request."
      );
    }
    if (!localQuery && view !== "mobile" && windowWidth >= 992) {
      fetchData();
    }
    setOpenStoreSearch(false)
    showMenu();
    if (windowWidth <= 993) {
      setOpenMenuSearch(true);
    } else {
      setOpenMenuSearch(false);
    }
  };
  const onSearch = (e: any) => {
    window?.dataLayer?.push({
      event: "search",
      search_term: localQuery,
    });
    saveSearchedItem();
    setSearchedData(data);
    router.push(`/search/${localQuery}`);
    closeMobileMenu();
  };

  useEffect(() => {
    const debouncedFetch = _.debounce(fetchData, 600);

    if (localQuery) {
      debouncedFetch();
    }

    return () => {
      debouncedFetch.cancel();
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel(
          "Operation canceled due to component unmount or query change."
        );
      }
    };
  }, [localQuery]);

  useEffect(() => {
    isTrendingHandler?.(!localQuery);
  }, [localQuery]);

  useEffect(() => {
    if (isSearchPage) {
      showMobileMenu();
    }
  }, []);
  const [isClosed, setClosed] = useState(false);
  const closeMenuHandler = async () => {
    await setOpenMenuSearch(false);
    setClosed(true);
    closeMenu();
  };
  useEffect(() => {
    if (isClosed) {
      searchRef?.current?.blur();
      setOpenMenuSearch(false);
      setClosed(false);
    }
  }, [isClosed]);
  return (
    <>
      {view === "mobile" ? (
        <SearchInput
          type="search"
          onChange={(e: any) => handelMobileInputChange(e)}
          placeholder="Search products or brands"
          hasIcon={hasIcon}
          Icon={SearchIcon}
          allowClear={
            inputValue
              ? {
                  clearIcon: (
                    <Icon
                      classesName="py-4 d-flex"
                      name="mobileCross"
                      onClick={closeMobileMenu}
                    />
                  ),
                }
              : null
          }
          className={`bg-white ${styles.mobileSearchField} ${
            isTyping === 0 ? "mb-10" : "mb-8"
          } ${isComparePage ? styles.compareSearchField : ""}`}
          onFocus={mobileFocus}
          autoFocus={true}
          onIconClick={(e: any) => onSearch(e)}
          value={isComparePage? compareValue: inputValue}
          onPressEnter={(e: any) => onSearch(e)}
          Ref={searchRef ? searchRef : null}
          enterKeyHint="search"
          prefix={true}
        />
      ) : (
        <SearchInput
          type="search"
          onChange={(e: any) => handelInputChange(e)}
          onFocus={showMenuDesktop}
          placeholder="Search products or brands"
          hasIcon={true}
          Icon={SearchIcon}
          allowClear={
            inputValue
              ? { clearIcon: <Icon name="cross" onClick={closeMenuHandler} /> }
              : null
          }
          className={`bg-white ${styles.searchField} ${
            isComparePage ? styles.compareSearchField : ""
          }`}
          onPressEnter={(e: any) => handleEnterKey(e)}
          value={isComparePage? compareValue: inputValue}
          Ref={searchRef ? searchRef : null}
          prefix={true}
        />
      )}

      {isComparePage ? null : (
        <>
          {!openMenuSearch && !isComparePage ? (
            <CustomButton
              hasIcon={true}
              Icon={Search}
              onClick={showMobileMenu}
              className={`${styles.searchButton}  p-0`}
              aria-label="search"
            />
          ) : (
            <CustomButton
              onClick={closeMobileMenu}
              className={`${styles.closeButton} bg-oxfordBlue p-0`}
              aria-label="search"
            >
              <Icon name="cross" width={18} height={18} color="#ffffff" />
            </CustomButton>
          )}
        </>
      )}
    </>
  );
};

export default SearchBox;