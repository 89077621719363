import axios from "axios";

import { EPALLETERP } from "@src/server/utils/configBackend";

/**
 * It returns an object with the common headers, and if a token is passed in, it adds an
 * authorization header with the token.
 * @param [token=null] - The token that is returned from the login API.
 */

export const axiosEpalletERP = axios.create({
  baseURL: EPALLETERP.BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Accept-Encoding": "identity"
  },
  timeout: 60000
  // withCredentials: true,
});

export default axiosEpalletERP;
