import Link from "next/link";
import { Menu } from "antd";
import HeaderStoreLocator from "../../HeaderStoreLocator";
import Button from "@components/shared/Button";
import Icon from "@components/shared/Icon";
import classnames from "classnames";
import { useState } from "react";
import styles from "./styles.module.scss";
import { MobileMenuProps, CategoryType, CategoryItemChildren } from "./type";
import router from "next/router";
import { getText } from "~/src/libs/resources";
import characters from "~/src/data/characters";

const MobileMenu = ({
  guestLabel,
  guestUrl,
  loggedInLabel,
  loggedInUrl,
  headerLinks,
  businessData,
  brandData,
  categoryData,
  open,
  setOpen,
  onClose,
  MoveHaggleLinks,
}: MobileMenuProps | any) => {
  const [Level, setLevel] = useState(0);
  const [openKeys, setOpenKeys] = useState([""]);
  const rootSubmenuKeys = ["cat1", "cat2", "cat3", "cat0"];

  const toLevel1 = () => {
    setLevel(1);
  };
  const toLevel2 = () => {
    if (Level == 1) {
      setLevel(2);
    } else {
      setLevel(1);
    }
  };
  const toLevel0 = () => {
    setLevel(0);
  };
  // fuction for set menu key
  const onOpenChange = (newKey: any) => {
    const latestOpenKey = newKey.find(
      (key: any) => openKeys.indexOf(key) === -1
    );
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(newKey);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  // fetch and format list for  category and business in mobile view
  const showSubMenuData = (
    mobileData: CategoryType | any,
    catTitle: string
  ) => {
    const contacts = mobileData?.map((items: any, index: number) => {
      const menuItems = items?.children?.filter((item:any) => item.isActive)
      if (
        !(
          items?.attributes?.filter(
            (attribute: any) => attribute.name === "isHidden"
          )?.[0]?.value === "true"
        )
      ) {
        return (
          <Menu.SubMenu
            key={index}
            title={
              <span className={styles.headerStyle}>
                <span className={styles.back}>
                  {Level == 2 && (
                    <Icon name="down" width={24} height={24} dir="left" />
                  )}
                  <span>{Level == 1 ? items?.name : catTitle}</span>
                </span>
                {Level == 1 && (
                  <Icon name="down" width={20} height={20} dir="right" />
                )}

                {Level == 2 && (
                  <span
                    className={classnames(
                      styles.level2Title,
                      "container pt-20 pb-6"
                    )}
                  >
                    {items?.name}
                  </span>
                )}
              </span>
            }
            onTitleClick={Level == 1 ? toLevel2 : toLevel1}
            style={Level == 1 ? { display: "block" } : { display: "none" }}
          >
            {menuItems?.map((item: CategoryItemChildren) => {
              return (
                <Menu.Item
                  key={item?.id}
                  className={styles.levelStyle}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <Link href={`/product-list${item?.path}`}>
                    <a onClick={() => navigationHandler(item)}>
                      {item?.name}
                      <Icon name="down" width={20} height={20} dir="right" />
                    </a>
                  </Link>
                </Menu.Item>
              );
            })}
          </Menu.SubMenu>
        );
      }
    });

    return contacts;
  };

  //fetch anf format lists for brand category in mobile view
  const getBrandByCharacter = (startingChar = "#") => {
    return brandData?.filter((brand: string) => {
      if (startingChar === "#") {
        return brand?.match(/^\d/);
      } else {
        return brand?.startsWith(startingChar);
      }
    });
  };
  const showBrandData = () => {
    const contacts = characters?.map((items: string, index: number) => {
      const charListLength = getBrandByCharacter(items)?.length;
      if (charListLength > 0)
        return (
          <Menu.SubMenu
            title={
              <span className={styles.headerStyle}>
                <span className={styles.back}>
                  {Level == 2 && (
                    <Icon name="down" width={24} height={24} dir="left" />
                  )}
                  <span>{Level == 1 ? items : "Shop by brand"}</span>
                </span>
                {Level == 1 && (
                  <Icon name="down" width={20} height={20} dir="right" />
                )}

                {Level == 2 && (
                  <span
                    className={classnames(
                      styles.level2Title,
                      "container pt-20 pb-6"
                    )}
                  >
                    {items}
                  </span>
                )}
              </span>
            }
            key={index}
            onTitleClick={toLevel2}
            style={Level == 1 ? { display: "block" } : { display: "none" }}
          >
            {getBrandByCharacter(items)?.map(
              (itemData: string, index: number) => {
                return (
                  <Menu.Item
                    key={index}
                    className={styles.levelStyle}
                    onClick={() => setOpen(false)}
                  >
                    <Link href={`/product-list/brands/${itemData}`}>
                      <a onClick={() => navigationHandlerBrand(itemData)}>
                        {itemData}
                        <Icon name="down" width={20} height={20} dir="right" />
                      </a>
                    </Link>
                  </Menu.Item>
                );
              }
            )}
            <Menu.Item
              className={styles.viewButton}
              onClick={() => setOpen(false)}
            >
              <Link href={`/brands`}>
                <a>
                  <Button theme={"primary"} className="font-bold-xs">
                    {getText()?.Header?.viewAll}
                  </Button>
                </a>
              </Link>
            </Menu.Item>
          </Menu.SubMenu>
        );
    });
    return contacts;
  };

  const openMixedPallet = () => {
    router.push("/mixed-pallet");
    setOpen(false);
  };
  const navigationHandlerBrand = (item: string) => {
    window?.dataLayer?.push({
      event: "navigation_click",
      item_click: item,
      nav_category: "Shop by brand",
      nav_category2: "brands",
    });
  };
  const navigationHandler = (item: any) => {
    const arrayOfCategory = item?.path?.split("/");
    const category =
      arrayOfCategory?.length > 3
        ? `${item?.path?.split("/")[1]}/${item?.path?.split("/")[2]}`
        : item?.path?.split("/")[1];
    window?.dataLayer?.push({
      event: "navigation_click",
      item_click: item?.name,
      nav_category:
        category === "Food" || category === "Non-Food"
          ? "Shop by category"
          : "Shop by Business",
      nav_category2: category,
    });
  };
  return (
    <div className={classnames(styles.menuContainer, "font-medium-base")}>
      <Menu
        mode="inline"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        className={styles.mainMenu}
      >
        <Menu.SubMenu
          title={
            <span>
              <Icon name="down" width={24} height={24} dir="left" />
              Main Menu
            </span>
          }
          key="cat0"
          onTitleClick={toLevel0}
          style={Level == 1 ? { display: "block" } : { display: "none" }}
          className={classnames(styles.mainMenuButton, "font-semibold-h5")}
        ></Menu.SubMenu>

        <Menu.SubMenu
          className={Level == 2 ? styles.hideTitle : ""}
          title={
            <span>
              Shop by category
              <Icon name="down" width={20} height={20} dir="right" />
            </span>
          }
          key="cat1"
          onTitleClick={toLevel1}
          style={Level == 0 ? { display: "block " } : { display: "" }}
        >
          {showSubMenuData(categoryData, "Shop by category")}
        </Menu.SubMenu>
        <Menu.SubMenu
          className={Level == 2 ? styles.hideTitle : ""}
          title={
            <span>
              Shop by business
              <Icon name="down" width={20} height={20} dir="right" />
            </span>
          }
          key="cat2"
          onTitleClick={toLevel1}
          style={Level == 0 ? { display: "block " } : {}}
        >
          {showSubMenuData(businessData, "Shop by business")}
        </Menu.SubMenu>

        <Menu.Item
          className={styles.oneChildItem}
          style={{ paddingLeft: "16px" }}
          key="brands"
        >
          <Link href="/brands">
            <a onClick={() => setOpen(false)}>{getText()?.Menu?.shopByBrand}</a>
          </Link>
        </Menu.Item>

        <Menu.Item
          className={styles.oneChildItem}
          style={{ paddingLeft: "16px" }}
          key="mixed-pallet"
        >
          <Link href="/mixed-pallet">
            <a onClick={() => setOpen(false)}>{getText()?.Menu?.buildMixedPallet}</a>
          </Link>
        </Menu.Item>
      </Menu>

      {Level == 0 && (
        <div className={styles.locatorContainer}>
          <HeaderStoreLocator
            Mode="vertical"
            guestLabel={guestLabel}
            guestUrl={guestUrl}
            loggedInLabel={loggedInLabel}
            loggedInUrl={loggedInUrl}
            headerLinks={headerLinks}
            onClose={onClose}
            MoveHaggleLinks={MoveHaggleLinks}
          />
        </div>
      )}
    </div>
  );
};

export default MobileMenu;
