import styles from "./styles.module.scss";
import DataLoader from "../DataLoader";

const LoadingWithOverlay = () => (
  <div className={styles.LoadingWithOverlay}>
   <DataLoader size="large" className= "container d-block my-40 text-center"/>
  </div>
);

export default LoadingWithOverlay;
