import Image from "next/image";
import { FeedbackProps } from "./types";
import styles from "./style.module.scss";
import UserImageIcon from "@assets/images/user.png";
import { useWindowResize } from "~/src/utilities/hooks/useWindowResize";
import Icon from "../Icon";
import { useState } from "react";
import { Modal } from "antd";
import Button from "../Button";
import { getText } from "~/src/libs/resources";
import truncateString from "~/src/libs/utils/truncateString";

const Product = ({
  title,
  description,
  userImage,
  userName,
  userPosition,
}: FeedbackProps & {
  className?: string;
}) => {
  const screenWidth = useWindowResize();
  const [openModal, setOpenModal] = useState(false);
  const [hasEllipsis, setHasEllipsis] = useState(false);

  return (
    <div className={styles.feedBackCard}>
      <div className={styles.qouteContainer}>
        {screenWidth > 1024 ? (
          <Icon name="vector" color="red" width={200} height={140} />
        ) : (
          <Icon name="vector" color="red" width={115} height={84} />
        )}
      </div>
      <div className={`${styles.feedBackContent} pb-20 pt-23 px-16`}>
        <h4 className={`my-0 ${styles.titleText}`}>{title}</h4>
        <div>
          {description && (
            <p className={`my-0 ${styles.descText}`}>{truncateString(description, 40, setHasEllipsis, hasEllipsis)}</p>
          )}
            <span className="font-regular-sm">
            {hasEllipsis && (<>...</>)}
            <Button theme="link" className="ms-2 h-auto font-regular-sm" onClick={() => setOpenModal(true)}>
              <a className={styles.readMore}>{getText()?.General?.readMore}</a>
            </Button>
            </span>
        </div>
        <div className={styles.userInfoWrapper}>
          <div className={styles.userImage}>
            <Image
              src={userImage || UserImageIcon}
              alt="user"
              layout="fill"
              objectFit="contain"
            />
          </div>
          <div className={styles.userInfoDetails}>
            <span className={styles.userName}>{userName}</span>
            <span className={styles.userPosition}>{userPosition}</span>
          </div>
        </div>
      </div>
      <Modal
        open={openModal}
        onCancel={() => setOpenModal(false)}
        centered
        footer={false}
        className={styles.feedBackModal}
      >
        <h4 className="font-bold-h6 lh-md mb-12 mt-37">{title}</h4>
        <p className="mb-40 font-regular-sm">{description}</p>
        <div className={styles.userInfoWrapper}>
          <div className={styles.userImage}>
            <Image
              src={userImage || UserImageIcon}
              alt="user"
              layout="fill"
              objectFit="contain"
            />
          </div>
          <div className={styles.userInfoDetails}>
            <span className="font-bold-base">{userName}</span>
            <span className="font-regular-xs">{userPosition}</span>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Product;
