import styles from "../styles.module.scss";
import { useContext, useEffect, useState } from "react";
import RemoveModal from "~/src/components/shared/RemoveModal";
import { Col, notification, Row } from "antd";
import ShoppingListCard from "../../ShoppingList/ShoppingListCard";
import { getCookie } from "~/src/server/utils/commonUtils";
import axios from "axios";
import DataLoader from "~/src/components/shared/DataLoader";
import { AppContext } from "@src/libs/context";
import { getText } from "~/src/libs/resources";
import CustomButton from "@components/shared/Button";
import router from "next/router";
import ShoppingList from "../../ShoppingList";

const ShoppingLists = ({ setItemViewData, setItemView }: any) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [shoppingList, setShoppingList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentItem, setCurrentItem] = useState<any>();
  const { defaultPostZip, customerStatus, setCurrent, setContent } =
    useContext<any>(AppContext);

  const removeItem = async () => {
    try {
      const newArray = shoppingList.filter((item: any) => {
        if (currentItem.uid !== item.uid) {
          return item;
        }
      });
      if (currentItem) {
        await axios
          .delete(`/api/ERP/customer/wishlist/${currentItem.uid}`, {
            headers: { sessionId: getCookie("sessionid") },
          })
          .then((res) => {
            if (res.status === 204) {
              setShoppingList(newArray);
              notification.success({
                message: `${currentItem.name} removed successfully`,
                duration: 2,
              });
              setModalOpen(false);
              getShoppingList();
            }
          })
          .catch((err) => {});
      }
    } catch (error) {}
  };
  const openModal = (item: any) => {
    setModalOpen(true);
    setCurrentItem(item);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (customerStatus?.has_addresses) {
      getShoppingList();
    }
  }, [customerStatus?.has_addresses]);

  useEffect(() => {
    if (defaultPostZip && (!customerStatus?.profile_completed || !customerStatus?.has_addresses)) {
      getShoppingList();
    }
  }, [defaultPostZip]);

  const getShoppingList = async () => {
    try {
      setIsLoading(true);
      let url = "/api/ERP/customer/wishlist";
      if (defaultPostZip && (!customerStatus?.profile_completed || !customerStatus?.has_addresses)) {
        url += `?post_zip=${defaultPostZip}`;
      }
      return await axios
        .get(url, {
          headers: { sessionId: getCookie("sessionid") },
        })
        .then((res) => {
          const sortedArray = res?.data?.sort((item1: any, item2: any) => {
            if (item1?.is_default) {
              return -1;
            } else if (item2?.is_default) {
              return 1;
            } else {
              return item2?.products?.length - item1?.products?.length;
            }
          });

          setShoppingList(sortedArray?.slice(0, 3));
          setIsLoading(false);
        })
        .catch((err) => {});
    } catch (error) {}
  };
  return (
    <div className={`${styles.shoppingLists} p-12`}>
      <div
        className={`${styles.dashboardWrapper__header} text-oxfordBlue mb-12`}
      >
        <h1 className="font-bold-h5 pe-6 mb-0 mt-0">
          {getText()?.Account?.shoppingListsTitle}
        </h1>
        <CustomButton
          className={`${styles.viewAllButton} font-bold-sm`}
          theme="link"
          onClick={()=>{ 
            if (!customerStatus?.is_vendor && !customerStatus?.profile_completed && customerStatus?.is_authenticated) {
              router.push("/");
            } else {
              router.push(`/account/shoppingList`);
              setContent(<ShoppingList />);
            }
          setCurrent("shoppingList");}}
        >
          {getText()?.Account?.viewAllButton}
        </CustomButton>
      </div>
      <>
        {isLoading ? (
          <Row justify="center" className="py-20">
            <DataLoader />
          </Row>
        ) : (
          <>
            {shoppingList?.length > 0 ? (
              <>
                <Row
                  className={styles.shoppingListItemWrapper}
                  gutter={[16, 16]}
                >
                  {shoppingList?.map((item: any, index: number) => (
                    <Col
                      key={item?.uid}
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                      xl={{ span: 8 }}
                    >
                      <ShoppingListCard
                        item={item}
                        index={item.uid}
                        openModal={openModal}
                        setItemViewData={setItemViewData}
                        setItemView={setItemView}
                        className="dahboardstyle"
                        shoppingList={shoppingList}
                        getShoppingList={getShoppingList}
                      />
                    </Col>
                  ))}
                </Row>
                {isModalOpen && (
                  <RemoveModal
                    closeModal={closeModal}
                    confirmRemove={() => removeItem()}
                    confirmationText={
                      "Are you sure you want to delete this shopping list?"
                    }
                  />
                )}
              </>
            ) : (
              <Row justify="center">
                <h5 className="font-medium-h6 ">
                  {getText()?.Account?.shoppingListNotAvailableMessage}
                </h5>
              </Row>
            )}
          </>
        )}
      </>
    </div>
  );
};

export default ShoppingLists;
