import { useState, useContext, useCallback } from "react";
import { AppContext } from "~/src/libs/context";
import { setSku, setViewDetails } from "~/src/libs/utils/mixedPallet";
import { useDispatch } from "react-redux";
import { setProductListInfo } from "~/src/libs/utils/products";
export const useProductCardLogic = ({
  validGuestUser,
  validCustomerStatus,
  id,
  product,
  titleOfList,
}: any) => {
  const placeholderImage =
    "https://cdn.bfldr.com/SK3DIHJL/at/rrxr67wrr7qn946t8f9vggbg/box-empty-white.png?auto=webp&format=png";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const { setShowVerifyModal } = useContext<any>(AppContext);
  const isCustomerDataCompleted =
    (!validGuestUser &&
      validCustomerStatus?.profile_completed === true &&
      validCustomerStatus?.email_verified === true) ||
    (!validGuestUser && validCustomerStatus?.is_vendor);
  const selectProductHandler = useCallback(
    (event: string) => {
      window?.dataLayer?.push({ ecommerce: null });
      dispatch(
        setProductListInfo({
          id: id,
          mainCategory: product?.main_category || product?.main_category_name,
          subCategory: product?.sub_category || product?.sub_category_name,
        })
      );
      window?.dataLayer?.push({
        event: event,
        ecommerce: {
          items: [
            {
              item_id: product?.id?.toString(),
              item_name: product?.name,
              coupon: null,
              currency: "USD",
              discount: 0,
              index: id || null,
              item_brand: product?.brand_name || product?.brand?.name,
              item_category:
                product?.main_category || product?.main_category_name,
              item_category2:
                product?.sub_category || product?.sub_category_name,
              item_list_id: "",
              item_list_name:
                titleOfList ||
                product?.sub_category ||
                product?.sub_category_name ||
                "",
              item_variant: product?.pack_size,
              price: product?.delivered_price,
              quantity: 1,
            },
          ],
        },
      });
    },
    [id, product]
  );
  return {
    selectProductHandler,
    setIsModalOpen,
    showModal,
    setShowVerifyModal,
    setProductListInfo,
    setViewDetails,
    isModalOpen,
    placeholderImage,
    isCustomerDataCompleted,
    setSku,
  };
};
