import { MIXED_PALLET_ACTIONS } from "~/src/modules/MixedPallet/actions";

export const setLoading = (loading: boolean) => ({
  type: MIXED_PALLET_ACTIONS.SET_LOADING,
  payload: loading,
});

export const setPalletName = (palletName: string) => ({
  type: MIXED_PALLET_ACTIONS.SET_PALLET_NAME,
  payload: palletName,
});

export const setPalletItems = (palletItems: []) => ({
  type: MIXED_PALLET_ACTIONS.SET_PALLET_ITEMS,
  payload: palletItems,
});

export const setVendorProducts = (vendorProducts: []) => ({
  type: MIXED_PALLET_ACTIONS.SET_VENDOR_PRODUCTS,
  payload: vendorProducts,
});

export const setPalletLoadedProgress = (palletLoadedProgress: number) => ({
  type: MIXED_PALLET_ACTIONS.SET_PALLET_LOADED_PROGRESS,
  payload: palletLoadedProgress,
});

export const setPalletFilters = (palletFilters: []) => ({
  type: MIXED_PALLET_ACTIONS.SET_PALLET_FILTERS,
  payload: palletFilters,
});

export const setAppliedPalletFilters = (appliedPalletFilters: []) => ({
  type: MIXED_PALLET_ACTIONS.APPLIED_PALLET_FILTERS,
  payload: appliedPalletFilters,
});

export const setIsLoaded = (isLoaded: boolean) => ({
  type: MIXED_PALLET_ACTIONS.SET_IS_LOADED,
  payload: isLoaded,
});

export const setSku = (sku: string) => ({
  type: MIXED_PALLET_ACTIONS.SET_SKU,
  payload: sku,
});

export const setViewDetails = (viewDetails: boolean) => ({
  type: MIXED_PALLET_ACTIONS.SET_VIEW_DETAILS,
  payload: viewDetails,
});

export const setVendorId = (vendorId: number) => ({
  type: MIXED_PALLET_ACTIONS.SET_VENDOR_ID,
  payload: vendorId,
});

export const setVendorName = (vendorName: string) => ({
  type: MIXED_PALLET_ACTIONS.SET_VENDOR_NAME,
  payload: vendorName,
});

export const setOffset = (offset: number) => ({
  type: MIXED_PALLET_ACTIONS.SET_OFFSET,
  payload: offset,
});

export const setPalletPrice = (palletPrice: number) => ({
  type: MIXED_PALLET_ACTIONS.SET_PALLET_PRICE,
  payload: palletPrice,
});

export const setVendorProductsCount = (vendorProductsCount: number) => ({
  type: MIXED_PALLET_ACTIONS.SET_VENDOR_PRODUCTS_COUNT,
  payload: vendorProductsCount,
});

export const initiateMixedPallet = (payload: any) => ({
  type: MIXED_PALLET_ACTIONS.INITIATE_MIXED_PALLET,
  payload,
});

export const setNext = (next: any) => ({
  type: MIXED_PALLET_ACTIONS.SET_NEXT,
  payload: next,
});
export const setProductInfo = (productInfo: any) => ({
  type: MIXED_PALLET_ACTIONS.SET_PRODUCT_INFO,
  payload: productInfo,
});

export const setAttributeGroups = (attributeGroups: any) => ({
  type: MIXED_PALLET_ACTIONS.SET_ATTRIBUTE_GROUPS,
  payload: attributeGroups,
});

export const setPricesData = (pricesData: any) => ({
  type: MIXED_PALLET_ACTIONS.SET_PRICES_DATA,
  payload: pricesData,
});
