import { Modal, notification } from "antd";
import { getText } from "~/src/libs/resources";
import { AppContext } from "@src/libs/context";
import axios from "axios";
import React, { useContext, useState } from "react";
import { VerifyEmailModalProps } from "./types";
import { useRouter } from "next/router";

const VerifyEmailModal = ({ checkoutModal = false }: VerifyEmailModalProps) => {
  const router = useRouter();
  const [verifyEmailLoader, setVerifyEmailLoader] = useState<boolean>(false);
  const { setShowVerifyModal, showVerifyModal, userEmail } =
    useContext<any>(AppContext);
  const sendVerificationEmail = async () => {
    setVerifyEmailLoader(true);
    try {
      await axios.post(`/api/ERP/request-link?type=EMAIL_VERIFICATION`, {
        email: userEmail
      });
      notification.success({
        message: getText()?.General?.Messages?.emailSentToValidateYourAddress,
        duration: 4
      });
      setVerifyEmailLoader(false);
      !checkoutModal && setShowVerifyModal(false);
    } catch (error: any) {
      setVerifyEmailLoader(false);
      !checkoutModal && setShowVerifyModal(false);
    }
  };
  const onCancel = () => {
    setShowVerifyModal(false);
    checkoutModal && router?.push("/");
  };
  return (
    <Modal
      title={getText()?.PDP?.verifyEmail}
      open={showVerifyModal}
      onOk={sendVerificationEmail}
      onCancel={onCancel}
      okButtonProps={{ loading: verifyEmailLoader }}
      centered={true}
    >
      <p>
        {checkoutModal
          ? getText()?.checkout?.verifyEmailModalText
          : getText()?.General?.Messages?.areYouSureToVerifyEmail}
      </p>
    </Modal>
  );
};

export default VerifyEmailModal;
