import styles from "./styles.module.scss";

const Overlay = ({ closeMenu, bgOverlay }: any) => {
  const handleClick = () => {
    if (bgOverlay !== "mobileLocator") {
      document.body.style.overflow = "auto";
    }
    closeMenu();
  };
  return (
    <button
      onClick={handleClick}
      className={`${styles.overlay} ${
        bgOverlay === "bgOverlay"
          ? styles.bgOverlay
          : bgOverlay === "mobileLocator"
          ? styles.mobileLocator
          : ""
      }`}
    />
  );
};

export default Overlay;
