import { makeUseApiCall } from "~/src/utilities/makeUseApiCall";
import {
  addItemToPallet,
  changePalletCustomerName,
  getMixedPalletById,
  getMixedPalletProducts,
  setPalletProductQuantity,
  getPalletById,
  setProductQuantity,
  getCart,
} from ".";

export const useAddItemToPallet = makeUseApiCall(addItemToPallet);
export const useBuildMixedPallet = makeUseApiCall(addItemToPallet);
export const useSetPalletProductQuantity = makeUseApiCall(
  setPalletProductQuantity
);
export const useChangePalletCustomerName = makeUseApiCall(
  changePalletCustomerName
);
export const useChangeName = makeUseApiCall(changePalletCustomerName);
export const useGetMixedPalletById = makeUseApiCall(getMixedPalletById);
export const useGetMixedPalletProducts = makeUseApiCall(getMixedPalletProducts);
export const useGetPalletById = makeUseApiCall(getPalletById);
export const usePalletById = makeUseApiCall(getPalletById);
export const useSetProductQuantity = makeUseApiCall(setProductQuantity);
export const useGetCart = makeUseApiCall(getCart);
