import styles from "../styles.module.scss";
import { Image, Row, Col, Skeleton } from "antd";
import Button from "@components/shared/Button";
import Icon from "@components/shared/Icon";
import { useEffect, useState, useContext } from "react";
import { ProductProps } from "./types";
import { AppContext } from "~/src/libs/context";
import { setCookie } from "src/server/utils/commonUtils";

const ProductItems = ({ product, viewType }: ProductProps) => {
  const [productName, setProductName] = useState("");
  const [productImage, setProductImage] = useState("");
  const [sku, setSku] = useState("");
  const {
    compareProductDetails,
    setCompareProductsSku,
    setShowProductCompare,
    compareProductsSku
  } = useContext<any>(AppContext);

  useEffect(() => {
    const attributes = product?.attributes;
    const sku = product?.sku;
    setSku(sku);
    attributes?.map((attr: any) => {
      if (attr.name == "Product Title") {
        setProductName(attr.value);
      }
      if (attr.name === "Plp image") {
        setProductImage(attr.value);
      }
    });
  }, []);

  const removeItemFromCompare = () => {


    const skuArray = compareProductsSku?.filter((compare_sku: any) => {
      if (compare_sku !== sku) {
        return sku;
      }
    });
    if (skuArray?.length == 0) {
      setCookie("isCompareActive", "false");
      setShowProductCompare(false);
    }
    setCompareProductsSku(skuArray);
    setCookie("compareSkus", JSON.stringify(skuArray));
  };
  return (
    <>
      {viewType !== "mobileView" ? (
        <div>
          <div className={`${styles.productItem} px-7`}>
            {productImage ? (
              <Image
                width={60}
                preview={false}
                height={60}
                src={productImage}
                alt={productName}
              />
            ) : (
              <Skeleton.Image className={styles.skeletonImage} active={true} />
            )}

            <span
              className={`${styles.productName} ps-3 pe-8 font-semibold-base lh-sm text-oxfordBlue`}
            >
              {productName}
            </span>
            <Button
              shape="circle"
              theme="secondary"
              hasIcon={true}
              icon={<Icon width={10} name="cross" height={10} color="#000" />}
              className={styles.removeProductBtn}
              onClick={() => {
                removeItemFromCompare();
              }}
            />
          </div>
          <div className={`${styles.mobileProductItem} `}>
            {productImage ? (
              <Image
                width={30}
                preview={false}
                height={30}
                src={productImage}
                alt={productName}
              />
            ) : (
              <Skeleton.Image className={styles.skeletonImage} active={true} />
            )}
          </div>
        </div>
      ) : (
        <Row className={`${styles.mobileViewItems} ps-6 pe-10`}>
          <Col span={3}>
            {productImage ? (
              <Image
                width={40}
                preview={false}
                height={46}
                src={productImage}
                alt={productName}
              />
            ) : (
              <Skeleton.Image className={styles.skeletonImage} active={true} />
            )}
          </Col>
          <Col span={17} className={`${styles.productNameWrapper}`}>
            <span
              className={`${styles.productName} font-semibold-base lh-sm text-oxfordBlue`}
            >
              {productName}
            </span>
          </Col>
          <Col span={2}>
            <Button
              shape="circle"
              theme="secondary"
              hasIcon={true}
              icon={<Icon width={10} name="cross" height={10} color="#000" />}
              className={styles.removeProductBtn}
              onClick={() => {
                removeItemFromCompare();
              }}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default ProductItems;
