import { Col, Form, Row, notification } from "antd";
import Button from "~/src/components/shared/Button";
import Field from "~/src/components/shared/Fields";
import styles from "./styles.module.scss";
import axios from "axios";
import { useState } from "react";
import { getText } from "~/src/libs/resources";
import { getCookie } from "cookies-next";
import { logoutHandler } from "~/src/actions/logoutHandler";
import { getPasswordRequirements } from "./passwordRequirements";

const UpdatePassword = () => {
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const onFinish = async (values: any) => {
    setButtonLoader(true);
    try {
      setErrorMessage("");
      await axios.post(
        "/api/ERP/password/changePassword",
        {
          data: values,
        },
        { headers: { sessionid: getCookie("sessionid") } }
      );
      notification.success({
        message: `Your password has been successfully changed, Please login again!`,
        duration: 4,
      });
      logoutHandler("/");
    } catch (error: any) {
      setHasError(true);
      setErrorMessage(error?.response?.data);
    }
    setButtonLoader(false);
    form.resetFields();
  };
  const onFinishFailed = async (errorInfo: any) => {};
  const cancelEdit = () => {
    form.resetFields();
    setErrorMessage("");
  };
  return (
    <div className={styles.updatePassword}>
      <h2 className="text-oxfordBlue font-bold-h5 mb-6">
        {getText()?.Account?.changePassword}
      </h2>
      <Form
        onFinish={onFinish}
        autoComplete="off"
        onFinishFailed={onFinishFailed}
        form={form}
        onChange={() => setErrorMessage("")}
      >
        <Row gutter={[16, 0]}>
          <Col
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
            xs={{ span: 24 }}
          >
            <Form.Item
              name="old_password"
              {...(errorMessage?.old_password
                ? {
                    validateStatus: "error",
                  }
                : {})}
              help={
                errorMessage?.old_password ? (
                  <ul className="mt-6 ps-0">
                    {errorMessage?.old_password?.map(
                      (item: string, index: number) => (
                        <li className="mb-6 font-medium-xs" key={index}>
                          {item}
                        </li>
                      )
                    )}
                  </ul>
                ) : null
              }
              rules={[
                { required: true, message: "This field should not be empty" },
              ]}
            >
              <Field
                placeholder="Old Password"
                label="Old Password"
                className="manageAccountField"
                type="Password"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 12]} className="my-6">
          <Col
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
            xs={{ span: 24 }}
          >
            <Form.Item
              name="new_password"
              {...(errorMessage?.new_password
                ? {
                    validateStatus: "error",
                  }
                : {})}
              help={
                errorMessage?.new_password ? (
                  <ul className="mt-6 ps-0">
                    {errorMessage?.new_password?.map(
                      (item: string, index: number) => (
                        <li className="mb-6 font-medium-xs" key={index}>
                          {item}
                        </li>
                      )
                    )}
                  </ul>
                ) : null
              }
              rules={[
                { required: true, message: "This field should not be empty" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("old_password") !== value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Please enter new password!")
                    );
                  },
                }),
              ]}
            >
              <Field
                placeholder="New Password"
                label="New Password"
                className="manageAccountField"
                type="Password"
              />
            </Form.Item>
          </Col>
          <Col
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
            xs={{ span: 24 }}
          >
            <Form.Item
              name="verifyPassword"
              rules={[
                { required: true, message: "This field should not be empty" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("new_password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Field
                placeholder="Verify Password"
                label="Verify Password"
                className="manageAccountField"
                type="Password"
              />
            </Form.Item>
          </Col>
          {!hasError && (
            <ul className="mt-3 mb-0 ps-12 text-darkGray">
            {getPasswordRequirements()?.map(
              (item: string, index: number) => (
                <li className="mb-6 font-medium-xs" key={index}>
                  {item}
                </li>
              )
            )}
          </ul>
          )}
        </Row>
        <Row className={styles.btnForm}>
          <Form.Item>
            <Button theme={"secondary"} onClick={cancelEdit}>
              {getText()?.Account?.cancelButton}
            </Button>
          </Form.Item>

          <Form.Item>
            <Button
              theme="primary"
              htmlType="submit"
              className="login-form-button"
              loading={buttonLoader}
            >
              {getText()?.Account?.saveButton}
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};
export default UpdatePassword;
