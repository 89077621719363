import axios from "axios";
import { useEffect, useReducer, useContext } from "react";
import { getCookie } from "src/server/utils/commonUtils";
import { AppContext } from "~/src/libs/context";
import { getText } from "~/src/libs/resources";
import { notification } from "antd";
import { isFunction } from "lodash";

const initialState = {
  data: undefined,
  loading: false,
  error: { hasError: false, message: "" },
};

const ACTIONS = {
  SET: "set",
  ADD: "add",
  DONE: "done",
  ERROR: "error",
  CLEAN: "clean",
  DELETE: "delete",
  PENDING: "pending",
  SET_AS_DEFAULT: "default",
};

const creditCardReducer = (state, actions) => {
  let updatedCards = state?.data ? [...state?.data] : [];
  switch (actions?.type) {
    case ACTIONS.PENDING:
      return { ...state, loading: true };
    case ACTIONS.SET:
      return {
        ...state,
        data: actions?.payload,
        loading: false,
      };
    case ACTIONS.ADD:
      updatedCards = state?.data?.map((item) => ({
        ...item,
        is_default: false,
      }));
      updatedCards?.unshift(actions?.payload);
      return {
        ...state,
        data: updatedCards,
        loading: false,
      };
    case ACTIONS.SET_AS_DEFAULT:
      updatedCards = state?.data?.map((item) => ({
        ...item,
        is_default: item?.id === actions?.cardId,
      }));

      return {
        ...state,
        data: updatedCards,
        loading: false,
      };
    case ACTIONS.DELETE:
      let deletedCard = state?.data?.find(
        (item) => item?.id === actions?.cardId
      );
      updatedCards = state?.data?.filter(
        (item) => item?.id !== actions?.cardId
      );
      if (updatedCards?.length > 0 && deletedCard?.is_default) {
        updatedCards[0].is_default = true;
      }
      return {
        ...state,
        data: updatedCards,
        loading: false,
      };
    case ACTIONS.DONE:
      return {
        ...state,
        loading: false,
      };
    case ACTIONS.ERROR:
      return {
        ...state,
        loading: false,
        error: { hasError: true, message: actions.message },
      };
    case ACTIONS.CLEAN:
      return {
        ...state,
        loading: false,
        error: { hasError: false, message: "" },
      };
    default:
      return state;
  }
};

const CREDIT_LINES_TYPES = {
  card: "CARD",
  ACH: "BANK_ACCOUNT",
  ePalletLine: "PURCHASE_ORDER",
  ACHDirectDebit: "us_bank_account",
};

const useCreditLines = () => {
  const [creditState, dispatcher] = useReducer(creditCardReducer, initialState);
  const { creditCardList, setCreditCardList, customerStatus, setLoadingState } =
    useContext(AppContext);
  const { data, loading, error } = creditState;

  // useEffect(() => {
  //   if (customerStatus?.email_verified) {
  //     if (typeof creditCardList === "undefined") {
  //       getCreditCards();
  //     } else {
  //       dispatcher({ type: ACTIONS.SET, payload: creditCardList });
  //     }
  //   }
  // }, [customerStatus?.email_verified]);

  useEffect(() => {
    if (data?.length > 0) setCreditCardList(data);
    if (data?.length === 0) setCreditCardList([]);
  }, [data]);

  const getCreditCards = async () => {
    if (!customerStatus?.email_verified) return;
    dispatcher({ type: ACTIONS.PENDING });
    try {
      let response = await axios.get("/api/ERP/customer/creditCard", {
        headers: { sessionId: getCookie("sessionid") },
      });
      dispatcher({ type: ACTIONS.SET, payload: response?.data });
    } catch (e) {
      dispatcher({
        type: ACTIONS.ERROR,
        message:
          e?.response?.data?.detail ||
          getText()?.General?.Messages?.SomethingWrongTryAgain,
      });
    }
  };

  const applyForCredit = async () => {
    let data;
    try {
      data = await axios.get("/api/ERP/customer/profile", {
        headers: { sessionid: getCookie("sessionid") },
      });
      await axios.get(
        `/api/ERP/customer/applyForCredit?customer_email=${data?.data?.email}`,
        {
          headers: { sessionId: getCookie("sessionid") },
        }
      );
    } catch (error) {}
  };

  const addNewCreditCardHandler = async (tokenId, publicToken, onClose) => {
    dispatcher({ type: ACTIONS.PENDING });
    const payload = tokenId?.setup_intent_id
      ? tokenId
      : { account_id: tokenId };
    if (publicToken) payload.public_token = publicToken;
    try {
      let response = await axios.post("/api/ERP/customer/creditCard", payload, {
        headers: { sessionId: getCookie("sessionid") },
      });
      getCreditCards();
      dispatcher({ type: ACTIONS.ADD, payload: response?.data });
      notification.success({
        message: `Your bank account has been successfully saved!`,
        duration: 2,
        onClose: () => {
          isFunction(onClose) && onClose();
        },
      });
    } catch (e) {
      dispatcher({
        type: ACTIONS.ERROR,
        message:
          e?.response?.data?.detail ||
          getText()?.General?.Messages?.SomethingWrongTryAgain,
      });
      notification.error({
        message:
          e?.response?.data?.detail ||
          getText()?.General?.Messages?.SomethingWrongTryAgain,
        duration: 4,
        onClose: () => {
          isFunction(onClose) && onClose();
        },
      });
    }
  };

  const setCardAsDefaultHandler = async (cardId) => {
    dispatcher({ type: ACTIONS.PENDING });
    setLoadingState(true)
    try {
      await axios.patch(
        "/api/ERP/customer/creditCard/" + cardId,
        { id: cardId, is_default: true },
        {
          headers: { sessionId: getCookie("sessionid") },
        }
      );
      getCreditCards();
    } catch (e) {
      dispatcher({
        type: ACTIONS.ERROR,
        message:
          e?.response?.data?.detail ||
          getText()?.General?.Messages?.SomethingWrongTryAgain,
      });
    }
    setLoadingState(false)
  };

  const deleteCardHandler = async (cardId) => {
    dispatcher({ type: ACTIONS.PENDING });
    try {
      await axios.delete("/api/ERP/customer/creditCard/" + cardId, {
        headers: { sessionId: getCookie("sessionid") },
      });
      getCreditCards();
    } catch (e) {
      dispatcher({
        type: ACTIONS.ERROR,
        message:
          e?.response?.data?.detail ||
          getText()?.General?.Messages?.SomethingWrongTryAgain,
      });
    }
  };

  const getCustomerProfileCreditLine = async () => {
    dispatcher({ type: ACTIONS.PENDING });
    let data;
    try {
      data = await axios.get("/api/ERP/customer/profile", {
        headers: { sessionid: getCookie("sessionid") },
      });
      await axios.get(
        `/api/ERP/customer/applyForCredit?customer_email=${data?.data?.email}`,
        {
          headers: { sessionId: getCookie("sessionid") },
        }
      );
      dispatcher({ type: ACTIONS.CLEAN });
    } catch (error) {
      dispatcher({
        type: ACTIONS.ERROR,
        message:
          e?.response?.data?.detail ||
          getText()?.General?.Messages?.SomethingWrongTryAgain,
      });
    }
  };

  const cleanRequest = () => dispatcher({ type: ACTIONS.CLEAN });

  const getDefaultCreditCard = () =>
    creditCardList?.find((item) => item?.is_default);

  const getCreditLineByType = (type) =>
    creditCardList?.filter((item) => item?.payment_method === type);

  return {
    creditCardList,
    CREDIT_LINES_TYPES,
    defaultCard: getDefaultCreditCard(),
    loading: loading,
    error: error,
    getCreditCards,
    getCreditLineByType,
    getDefaultCreditCard,
    applyForCredit,
    getCustomerProfileCreditLine,
    clean: cleanRequest,
    setCardAsDefault: setCardAsDefaultHandler,
    deleteCard: deleteCardHandler,
    addNewCreditCard: addNewCreditCardHandler,
  };
};

export default useCreditLines;
