import {
  Checkbox,
  Col,
  Form,
  Radio,
  Row,
  RadioChangeEvent,
  notification,
} from "antd";
import Field from "@components/shared/Fields";
import styles from "./styles.module.scss";
import Button from "~/src/components/shared/Button";
import { useState } from "react";
import { getCookie } from "src/server/utils/commonUtils";
import { getText } from "~/src/libs/resources";
import useClient from "~/src/server/utils/client";
import Link from "next/link";

const AddUser = () => {
  const [radioGroupDisabled, setRadioGroupDisabled] = useState(false);
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [form] = Form.useForm();
  const initialValues = {
    permissions: "Manager",

    checkboxGroup: ["MANAGE_SHIPPING_ADDRESSES", "VIEW_SHIPPING_ADDRESSES"],
  };
  const client = useClient({});
  const onFinish = async (values: any) => {
    setButtonLoader(true);
    try {
      setErrorMessage({});
      const bodyData = {
        contact_name: values?.contact_name,
        email: values?.email,
        is_customer_admin: radioGroupDisabled,
        permissions: values?.checkboxGroup,
      };
      await client.basic.post(
        "/api/ERP/customer/users",
        {
          data: bodyData,
        },
        { headers: { sessionid: getCookie("sessionid") } }
      );
      notification.success({
        message: `A new user has been successfully created!`,
        duration: 4,
      });
      form.resetFields();
    } catch (error: any) {
      if (error?.response?.status === 403) console.log(error);
      else {
        error?.response?.data
          ? setErrorMessage(error?.response?.data)
          : notification.error({
              message: getText().General.Messages.SomethingWrongTryAgain,
              duration: 4,
            });
      }
    }
    setButtonLoader(false);
  };
  const onFinishFailed = (errorInfo: any) => {};
  const onRadioGroupChange = (e: RadioChangeEvent) => {
    if (e.target.value === "Admin") {
      form.setFieldValue("checkboxGroup", [
        "MANAGE_USERS",
        "MANAGE_ORDERS",
        "MANAGE_SHIPPING_ADDRESSES",
        "VIEW_ORDERS",
        "VIEW_USERS",
        "VIEW_SHIPPING_ADDRESSES",
      ]);
      setRadioGroupDisabled(true);
    } else {
      setRadioGroupDisabled(false);
      form.setFieldValue("checkboxGroup", [
        "MANAGE_SHIPPING_ADDRESSES",
        "VIEW_SHIPPING_ADDRESSES",
      ]);
    }
  };
  const cancelEdit = () => {};
  return (
    <div className={styles.addUser}>
      <Form
        onFinish={onFinish}
        autoComplete="off"
        onFinishFailed={onFinishFailed}
        form={form}
        initialValues={initialValues}
        onValuesChange={() => setErrorMessage({})}
      >
        <Row gutter={[16, 12]} className="mb-6">
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 8 }}
          >
            <Form.Item
              name="contact_name"
              rules={[
                { required: true, message: "This field should not be empty" },
              ]}
            >
              <Field
                placeholder="Contact Name"
                label="Contact Name"
                className="manageAccountField"
              />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 8 }}
          >
            <Form.Item
              name="email"
              {...(errorMessage?.email
                ? {
                    validateStatus: "error",
                  }
                : {})}
              help={errorMessage?.email ? errorMessage?.email[0] : null}
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please input a valid email.",
                },
              ]}
            >
              <Field
                placeholder="Email"
                label="Email"
                className="manageAccountField"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[0, 16]}>
          <Col span={24} className="font-bold-base text-oxfordBlue">
            {getText()?.Account?.permissions}
          </Col>
          <Col span={24}>
            <Form.Item name="permissions">
              <Radio.Group onChange={onRadioGroupChange}>
                <Radio value="Manager"> {getText()?.Account?.manager} </Radio>
                <Radio value="Admin">{getText()?.Account?.admin}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row className={styles.checkboxGroup}>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 8 }}
          >
            <Form.Item
              name="checkboxGroup"
              className="pt-12 pb-20"
              rules={[
                () => ({
                  validator(_, value) {
                    if (
                      value?.includes("MANAGE_ORDERS") &&
                      !value?.includes("VIEW_ORDERS")
                    )
                      return Promise.reject(
                        new Error("MANAGE ORDERS must include VIEW ORDERS")
                      );
                    else if (
                      value?.includes("MANAGE_SHIPPING_ADDRESSES") &&
                      !value?.includes("VIEW_SHIPPING_ADDRESSES")
                    )
                      return Promise.reject(
                        new Error(
                          "MANAGE SHIPPING ADDRESSES must include VIEW SHIPPING ADDRESSES"
                        )
                      );
                    else if (
                      value?.includes("MANAGE_USERS") &&
                      !value?.includes("VIEW_USERS")
                    )
                      return Promise.reject(
                        new Error("MANAGE USERS must include VIEW USERS")
                      );
                    else if (value?.length === 0) {
                      return Promise.reject(
                        new Error("Please select at least one permission")
                      );
                    } else return Promise.resolve();
                  },
                }),
              ]}
            >
              <Checkbox.Group
                style={{ width: "100%" }}
                disabled={radioGroupDisabled}
              >
                <Checkbox value="MANAGE_ORDERS">
                  {getText()?.Account?.manageOrders}
                </Checkbox>
                <Checkbox value="VIEW_ORDERS">
                  {getText()?.Account?.viewOrders}
                </Checkbox>
                <Checkbox value="MANAGE_SHIPPING_ADDRESSES">
                  {getText()?.Account?.manageShippingAddress}
                </Checkbox>
                <Checkbox value="VIEW_SHIPPING_ADDRESSES">
                  {getText()?.Account?.viewShippingAddress}
                </Checkbox>
                <Checkbox value="MANAGE_USERS">
                  {getText()?.Account?.manageUsers}
                </Checkbox>
                <Checkbox value="VIEW_USERS">
                  {getText()?.Account?.viewUsers}
                </Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row className={styles.btnForm}>
          <Form.Item>
            <Link href="/account/dashboard">
              <a>
                <Button theme={"secondary"} onClick={cancelEdit}>
                  {getText()?.Account?.cancelButton}
                </Button>
              </a>
            </Link>
          </Form.Item>

          <Form.Item>
            <Button
              theme="primary"
              htmlType="submit"
              className="login-form-button"
              loading={buttonLoader}
            >
              {getText()?.Account?.saveButton}
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};
export default AddUser;
